import { BreadCrumb } from '@/components/shared/BreadCrumb';
import { ButtonsFooter } from '@/components/shared/ButtonsFooter';
import {
  DatePicker,
  NumberInput,
  RadioBoxes,
  SingleSelect,
  TextArea,
} from '@/components/shared/Form/Inputs';
import { TChoice, TValidator } from '@/components/shared/Form/types';
import React, { FC, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { FormField, TCreateResponse, TPhotographer } from './types';
import { usePreventDuplicateCall } from '@/ts/usePreventDuplicateCall';
import { postJson } from '@/ts/fetch';
import { errorToast, successToast } from '@/ts/toast';
import {
  isValidationError,
  renderError,
  toMessages,
  useJsonApi,
} from '@/ts/useApi';
import { alertApiError } from '@/ts/formValidation';
import Loading from '@/components/shared/Loading/App';
import { BackButton } from '@/components/pages/PhotographerOrganizations/Form/types';
import dayjs from 'dayjs';
import SvgLoading from '@/components/shared/Loading/SvgLoading';
import { WithholdingTaxChoices } from '../Form/WithholdingTaxChoices';

const Contents: FC<{
  response: TCreateResponse;
}> = ({ response }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const photographerOrganizationNames = useMemo(() => {
    const organizationNames: { [key: number]: string } = {};
    response.formItems.photographers.forEach((photographer: TPhotographer) => {
      organizationNames[photographer.photographerId] =
        photographer.organizationName;
    });
    return organizationNames;
  }, [response.formItems]);

  const photographerSelectChoices = useMemo(() => {
    const photographerChoices: TChoice[] = [];
    response.formItems.photographers.forEach((photographer: TPhotographer) => {
      photographerChoices.push({
        key: photographer.photographerId,
        value: `${photographer.photographerId}/${photographer.photographerSei} ${photographer.photographerMei}(${photographer.photographerSeiKana} ${photographer.photographerMeiKana})`,
      });
    });
    return photographerChoices;
  }, [response.formItems]);

  const methods = useForm<FormField>({
    defaultValues: {
      photographerId: undefined,
      price: 0,
      consumptionTaxPercent: 10,
      type: undefined,
      hasWithholdingTax: 1,
      tradingDate: dayjs(new Date()).format('YYYY-MM-DD'),
    },
  });

  const [validator, setValidator] = useState<TValidator>(response.validator);
  const history = useHistory();

  const onSubmit: SubmitHandler<FormField> = usePreventDuplicateCall(
    async (formData) => {
      try {
        setIsLoading(true);
        await postJson(
          '/api/photographer_payments/non_event_related_expenses',
          formData
        );
        successToast('イベント外経費を登録しました');
        history.push(`/photographer_payments/non_event_related_expenses`);
      } catch (e) {
        if (isValidationError(e)) {
          setValidator(e.jsonMessage.validator);
          alertApiError();
        } else {
          setValidator(validator);
          errorToast(`エラーが発生しました: ${toMessages(e)}`);
        }
      } finally {
        setIsLoading(false);
      }
    }
  );

  const watchedPhotographerId = methods.watch('photographerId');
  const photographerOrganizationName =
    photographerOrganizationNames[Number(watchedPhotographerId)];

  const backButton: BackButton = {
    name: '戻る',
    url: '/photographer_payments/non_event_related_expenses',
  };

  if (isLoading) {
    return <SvgLoading />;
  }

  return (
    <div>
      <BreadCrumb
        pagesInfo={[
          { id: 1, title: 'はいチーズ!フォト管理画面', path: '/' },
          {
            id: 2,
            title: '報酬管理',
            path: '/photographer_payments',
          },
          {
            id: 3,
            title: 'イベント外経費一覧',
            path: '/photographer_payments/non_event_related_expenses',
          },
          { id: 4, title: 'イベント外経費登録', path: undefined },
        ]}
      />
      <div className="nowrap u-mgb_m">
        <div className="l-flex_start">
          <h1 className="l-flex_center c-page_title">イベント外経費登録</h1>
        </div>
      </div>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormProvider {...methods}>
          <>
            <div className="l-center_wrap">
              <div className="c-frame">
                <ul className="l-flex between c-label_line">
                  <li className="c-dataLabel">
                    カメラマン
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <SingleSelect
                      name="photographerId"
                      choices={photographerSelectChoices}
                      validator={validator}
                    ></SingleSelect>
                  </li>
                </ul>
                <ul className="l-flex between c-label_line">
                  <li className="c-dataLabel">カメラマン組織</li>
                  <li className="c-dataValue">
                    {photographerOrganizationName}
                  </li>
                </ul>
                <ul className="l-flex between c-label_line">
                  <li className="c-dataLabel">
                    取引日
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <DatePicker name={'tradingDate'} validator={validator} />
                  </li>
                </ul>
                <ul className="l-flex between c-label_line">
                  <li className="c-dataLabel">
                    詳細区分
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <RadioBoxes
                      name="type"
                      choices={response.formItems.types}
                      validator={validator}
                    />
                  </li>
                </ul>
                <ul className="l-flex between c-label_line">
                  <li className="c-dataLabel">
                    金額(税込)
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <NumberInput
                      name="price"
                      validator={validator}
                    ></NumberInput>
                  </li>
                </ul>
                <ul className="l-flex between c-label_line">
                  <li className="c-dataLabel">
                    税率
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <RadioBoxes
                      name="consumptionTaxPercent"
                      choices={response.formItems.consumptionTaxPercent}
                      validator={validator}
                    />
                  </li>
                </ul>
                <ul className={'l-flex between c-label_line'}>
                  <li className="c-dataLabel">
                    源泉徴収
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <WithholdingTaxChoices
                      validator={validator}
                      setValue={(value: number) =>
                        methods.setValue('hasWithholdingTax', value)
                      }
                      watchType={methods.watch('type')}
                    />
                  </li>
                </ul>
                <ul className="l-flex between c-label_line">
                  <li className="c-dataLabel">備考</li>
                  <li className="c-dataValue">
                    <TextArea name="externalMemo" validator={validator} />
                    <span className="t-textColor_sub">
                      <p>※明細に表示されます</p>
                      <p>
                        ※詳細区分で「その他」を選択した場合は内容を記載お願いします。
                      </p>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="u-mgb_l" />
            <ButtonsFooter>
              <Link
                className="c-btn_large c-btn_cancel u-pdt_s u-pdb_s c-input_submit"
                to={backButton.url}
              >
                {backButton.name}
              </Link>
              <button className="c-btn_large c-btn_primary c-btn_rectangle c-input_submit">
                登録
              </button>
            </ButtonsFooter>
          </>
        </FormProvider>
      </form>
    </div>
  );
};

export const Create: FC = () => {
  const { data: response, error } = useJsonApi<TCreateResponse>(
    '/api/photographer_payments/non_event_related_expenses/create'
  );
  if (error) {
    return renderError(error);
  }
  if (!response) {
    return <Loading />;
  }
  return <Contents response={response} />;
};
