import React from 'react';
import { useParams } from 'react-router-dom';
import { kanriUrl } from '../../../../ts/url';
import { useJsonApi, renderError } from '../../../../ts/useApi';
import Loading from '../../../shared/Loading/App';
import PriceContainer from '../PriceContainer/App';
import { IndexResponse } from './types';

const App: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const {
    data: result,
    error,
    mutate,
  } = useJsonApi<IndexResponse>(`/api/groups/${id}/prices`);

  if (error) {
    return renderError(error);
  }
  if (!result) {
    return <Loading />;
  }
  const { data, validator } = result;

  return (
    <div className="c-summary">
      <a className="c-page_back" href={kanriUrl({ g: id })}>
        団体サマリに戻る
      </a>
      <h3 className="l-flex_center u-mgb_m">
        <span className="c-page_title">価格一覧</span>
      </h3>
      <div className="l-center_wrap">
        <div className="c-frame l-flex">
          <div className="l-flex_center u-mgr_m">
            <span className="c-label c-label__monotone u-fz_xs">
              団体ID: {id}
            </span>
            <span className="p-groupsName u-fz_l">{data.groupName}</span>
          </div>
        </div>
      </div>
      <PriceContainer
        {...data}
        validator={validator}
        savePath={`/api/groups/${id}/prices`}
        updatePathPrefix={`/api/groups/${id}`}
        isPhoto={data.isPhoto}
        onUpdate={mutate}
        enableInputTaxIncludedPrice={true}
        teacherFlag={false}
      />
    </div>
  );
};

export default App;
