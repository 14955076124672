import { useJsonApi } from '@/ts/useJsonApi';
import React from 'react';
import { kanriUrl, ownerUrl } from '../../../ts/url';
import { renderError } from '../../../ts/useApi';
import Loading from '../../shared/Loading/App';
import { TResponse } from './types';
import SalesOwners from './SalesOwners';
import Photographers from './Photographers';
import './Top.scss';
import { Link } from 'react-router-dom';

const App: React.FC = () => {
  const { data: response, error } = useJsonApi<TResponse>('/api/top');
  if (error) {
    return renderError(error);
  }
  if (!response) {
    return <Loading />;
  }
  return (
    <>
      <h3 className="c-page_title u-mgb_m">管理画面トップページ</h3>
      <div className="l-content">
        <div className="l-center_wrap">
          <div className="l-flex_between p-index_wrapInSp">
            <div className="c-frame p-index_innerHalf">
              <h3>オペレーション</h3>
              <hr className="u-line_plane" />
              <div className="l-flex c-label_line is-sp_input p-index_List">
                <ul className="p-index_innerHalf">
                  <li>
                    <a
                      className="c-textlink u-mgr_xs"
                      href={kanriUrl({ action_owner_FAXlist: 'true' })}
                    >
                      FAX
                    </a>
                    <i className="c-icon_arrowRight u-fz_s t-textColor_blue"></i>
                  </li>
                  <li>
                    <a
                      className="c-textlink u-mgr_xs"
                      href={ownerUrl('orders/manual')}
                    >
                      手動注文(addsen)
                    </a>
                    <i className="c-icon_arrowRight u-fz_s t-textColor_blue"></i>
                  </li>
                  <li>
                    <a
                      className="c-textlink u-mgr_xs"
                      href={kanriUrl({
                        action_owner_NEWSlist: 'true',
                        newsflag: '1',
                      })}
                    >
                      TOPお知らせ文
                    </a>
                    <i className="c-icon_arrowRight u-fz_s t-textColor_blue"></i>
                  </li>
                  <li>
                    <Link
                      className="c-textlink u-mgr_xs"
                      to="/send_event_schedule_pdf"
                    >
                      撮影日程確認書FAX送信
                    </Link>
                    <i className="c-icon_arrowRight u-fz_s t-textColor_blue"></i>
                  </li>
                  <li>
                    <a
                      className="c-textlink u-mgr_xs"
                      href={kanriUrl({ action_owner_EVENTchart: 'true' })}
                    >
                      イベント情報グラフ化
                    </a>
                    <i className="c-icon_arrowRight u-fz_s t-textColor_blue"></i>
                  </li>
                </ul>
                <ul className="p-index_innerHalf">
                  <li>
                    <Link
                      className="c-textlink u-mgr_xs"
                      to="/certificationkeys"
                    >
                      写真閲覧キー一覧
                    </Link>
                    <i className="c-icon_arrowRight u-fz_s t-textColor_blue"></i>
                  </li>
                  <li>
                    <Link
                      className="c-textlink u-mgr_xs"
                      to="/groups_societies"
                    >
                      団体一覧（CS向け）
                    </Link>
                    <i className="c-icon_arrowRight u-fz_s t-textColor_blue"></i>
                  </li>
                  <li>
                    <a
                      className="c-textlink u-mgr_xs"
                      href={ownerUrl('aspectRatioCalculator')}
                    >
                      縦横比率計算くん
                    </a>
                    <i className="c-icon_arrowRight u-fz_s t-textColor_blue"></i>
                  </li>
                  <li>
                    <Link
                      className="c-textlink u-mgr_xs"
                      to="/leader_mailadress_confirm"
                    >
                      責任者メールアドレスチェック
                    </Link>
                    <i className="c-icon_arrowRight u-fz_s t-textColor_blue"></i>
                  </li>
                </ul>
              </div>
            </div>
            <div className="c-frame p-index_innerHalf p-index_List">
              <h3>手配</h3>
              <hr className="u-line_plane" />
              <div className="l-flex c-label_line is-sp_input">
                <ul className="p-index_innerHalf u-mgr_xs">
                  <li>
                    <a
                      className="c-textlink u-mgr_xs"
                      href={kanriUrl({
                        action_owner_PHOTOGRAPHERassignablelist: 'true',
                      })}
                    >
                      撮影可能カメラマン一覧
                    </a>
                    <i className="c-icon_arrowRight u-fz_s t-textColor_blue"></i>
                  </li>
                  <li>
                    <a
                      className="c-textlink u-mgr_xs"
                      href={kanriUrl({
                        action_owner_PHOTOGRAPHERTROUBLElist: 'true',
                      })}
                    >
                      カメラマントラブル一覧
                    </a>
                    <i className="c-icon_arrowRight u-fz_s t-textColor_blue"></i>
                  </li>
                  <li>
                    <a
                      className="c-textlink u-mgr_xs"
                      href={kanriUrl({
                        action_owner_PHOTOGRAPHERREPORTEDDATElist: 'true',
                      })}
                    >
                      カメラマン報告日時一覧
                    </a>
                    <i className="c-icon_arrowRight u-fz_s t-textColor_blue"></i>
                  </li>
                  <li>
                    <a
                      className="c-textlink u-mgr_xs"
                      href={kanriUrl({
                        action_owner_PHOTOGRAPHERFEEDBACKtargetlist: 'true',
                      })}
                    >
                      カメラマンフィード
                      <br />
                      バック対象一覧
                    </a>
                    <i className="c-icon_arrowRight u-fz_s t-textColor_blue"></i>
                  </li>
                  <li>
                    <Link
                      className="c-textlink u-mgr_xs"
                      to="/photographer_schedule"
                    >
                      カメラマンアサイン状況一覧
                    </Link>
                    <i className="c-icon_arrowRight u-fz_s t-textColor_blue"></i>
                  </li>
                </ul>
                <ul className="p-index_innerHalf">
                  <li>
                    <a
                      className="c-textlink u-mgr_xs"
                      href={kanriUrl({
                        action_owner_SHINSEItehaichart: 'true',
                      })}
                    >
                      手配状況
                    </a>
                    <i className="c-icon_arrowRight u-fz_s t-textColor_blue"></i>
                  </li>
                  <li>
                    <a
                      className="c-textlink u-mgr_xs"
                      href={kanriUrl({
                        action_owner_PHOTOGRAPHDOCUMENTlist: 'true',
                      })}
                    >
                      撮影のポイント一覧
                    </a>
                    <i className="c-icon_arrowRight u-fz_s t-textColor_blue"></i>
                  </li>
                  <li>
                    <a
                      className="c-textlink u-mgr_xs"
                      href={kanriUrl({
                        action_owner_PHOTOGRAPHERngpairlist: 'true',
                      })}
                    >
                      カメラマンNG組み合わせ
                    </a>
                    <i className="c-icon_arrowRight u-fz_s t-textColor_blue"></i>
                  </li>
                  <li>
                    <a
                      className="c-textlink u-mgr_xs"
                      href={kanriUrl({ action_owner_RANKINGlist: 'true' })}
                    >
                      ランキング一覧
                    </a>
                    <i className="c-icon_arrowRight u-fz_s t-textColor_blue"></i>
                  </li>
                  <li>
                    <Link
                      className="c-textlink u-mgr_xs"
                      to="/arrange_photographer"
                    >
                      カメラマン手配
                    </Link>
                    <i className="c-icon_arrowRight u-fz_s t-textColor_blue"></i>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="l-flex_between">
            <div className="c-frame p-index_innerHalf p-index_List">
              <h3>セールスサポート</h3>
              <hr className="u-line_plane" />
              <div className="l-flex c-label_line is-sp_input">
                <ul className="p-index_innerHalf">
                  <li>
                    <a
                      className="c-textlink u-mgr_xs"
                      href={kanriUrl({
                        action_owner_SHOUSAIbouncedList: 'true',
                      })}
                    >
                      送信失敗詳細メール一覧
                    </a>
                    <i className="c-icon_arrowRight u-fz_s t-textColor_blue"></i>
                  </li>
                  <li>
                    <a
                      className="c-textlink u-mgr_xs"
                      href={kanriUrl({ action_owner_SYSTEMmaillist: 'true' })}
                    >
                      メールテンプレート一覧
                    </a>
                    <i className="c-icon_arrowRight u-fz_s t-textColor_blue"></i>
                  </li>
                </ul>
                <ul className="p-index_innerHalf">
                  <li>
                    <a
                      className="c-textlink u-mgr_xs"
                      href={kanriUrl({
                        action_owner_PHOTOGRAPHERNOUHINlist: 'true',
                      })}
                    >
                      納品報告一覧
                    </a>
                    <i className="c-icon_arrowRight u-fz_s t-textColor_blue"></i>
                  </li>
                </ul>
              </div>
            </div>
            <div className="c-frame p-index_innerHalf p-index_List">
              <h3>開発</h3>
              <hr className="u-line_plane" />
              <div className="l-flex c-label_line is-sp_input">
                <ul className="p-index_innerHalf">
                  <li>
                    <a
                      className="c-textlink u-mgr_xs"
                      href={kanriUrl({ action_owner_PGMAINTlist: 'true' })}
                    >
                      決済メンテナンス予約設定
                    </a>
                    <i className="c-icon_arrowRight u-fz_s t-textColor_blue"></i>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="c-frame">
            <h3>管理</h3>
            <hr className="u-line_plane" />
            <div className="c-label_line is-sp_input">
              <ul className="l-flex u-pdb_s">
                <li className="u-mgr_m">
                  <Link className="c-textlink u-mgr_xs" to="/administrator">
                    千社員管理
                  </Link>
                  <i className="c-icon_arrowRight u-fz_s t-textColor_blue"></i>
                </li>
              </ul>
            </div>
          </div>
          <div className="c-frame">
            <h3>パートナー</h3>
            <hr className="u-line_plane" />
            <div className="c-label_line is-sp_input">
              <ul className="l-flex u-pdb_s">
                <li className="u-mgr_m">
                  <a
                    className="c-textlink u-mgr_xs"
                    href={
                      kanriUrl({ action_owner_InfoForPartner: 't' }) + '#tabs-1'
                    }
                  >
                    ご利用マニュアル
                  </a>
                  <i className="c-icon_arrowRight u-fz_s t-textColor_blue"></i>
                </li>
                <li className="u-mgr_m">
                  <a
                    className="c-textlink u-mgr_xs"
                    href={
                      kanriUrl({ action_owner_InfoForPartner: 't' }) + '#tabs-2'
                    }
                  >
                    お知らせ
                  </a>
                  <i className="c-icon_arrowRight u-fz_s t-textColor_blue"></i>
                </li>
                <li className="u-mgr_m">
                  <a
                    className="c-textlink u-mgr_xs"
                    href={
                      kanriUrl({ action_owner_InfoForPartner: 't' }) + '#tabs-3'
                    }
                  >
                    ツールダウンロード
                  </a>
                  <i className="c-icon_arrowRight u-fz_s t-textColor_blue"></i>
                </li>
                <li>
                  <a
                    className="c-textlink u-mgr_xs"
                    href={
                      kanriUrl({ action_owner_InfoForPartner: 't' }) + '#tabs-4'
                    }
                  >
                    申請書類ダウンロード
                  </a>
                  <i className="c-icon_arrowRight u-fz_s t-textColor_blue"></i>
                </li>
              </ul>
            </div>
          </div>
          <div className="c-frame">
            <h3>営業</h3>
            <hr className="u-line_plane" />
            <SalesOwners salesOwners={response.data.salesOwners} />
          </div>
          <div className="c-frame">
            <h3>カメラマン</h3>
            <hr className="u-line_plane" />
            <div className="c-label_line is-sp_input">
              <ul className="l-flex u-pdb_s">
                <li className="u-mgr_m">
                  <a
                    className="c-textlink u-mgr_xs"
                    href={kanriUrl({ action_owner_MODELlist: 'true' })}
                  >
                    ロマホTOP応募画像一覧
                  </a>
                  <i className="c-icon_arrowRight u-fz_s t-textColor_blue"></i>
                </li>
              </ul>
            </div>
            <Photographers photographers={response.data.photographers} />
          </div>
        </div>
      </div>
    </>
  );
};

export default App;
