import React, {
  ChangeEventHandler,
  MouseEventHandler,
  useCallback,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SubmitHandler } from 'react-hook-form';
import dayjs from 'dayjs';
import QueryString from 'query-string';

import {
  makePathForSearchForm,
  convertQueryToFormData,
} from '@/ts/makePathForSearchForm';
import Paginator from '@/components/shared/Paginator/App';
import { TPagination } from '@/components/shared/Paginator/types';
import { TValidator } from '@/components/shared/Form/types';

import { Not200Error, postJson, renderError } from '../../../../ts/useApi';
import { useJsonApiForSearchForm } from '../../../../ts/useJsonApiForSearchForm';
import {
  isCheckedAllChecks,
  isDisplayAllChecks,
  toggleCheck,
  toggleCheckAll,
} from '../../../../ts/toggleAllSelectableCheckboxes';
import {
  toggleCheck as legacyToggleCheck,
  toggleCheckAll as legacyToggleCheckAll,
} from '../../../../ts/toggleCheckboxes';
import Loading from '../../../shared/Loading/App';
import { dateFormatHyphen } from '../../../../ts/formatTools';
import { TCheck, TAllSelectableChecks } from '../../../../types';
import { isUndecided } from '../requestPhotographer';
import SearchResultItem from './SearchResultItem';
import {
  TFormInputs,
  TIndexResponse,
  TEvent,
  TAssignPhotographersAutoResponse,
} from './types';
import SearchForm from './SearchForm';
import './style.scss';
import { errorToast, successToast } from '../../../../ts/toast';

const HasNoResult: React.FC = () => (
  <div className="u-mgb_m">
    <hr className="u-line_plane" />
    <div className="c-emptyState_box">ご指定の条件では見つかりませんでした</div>
  </div>
);

const HasValidationError: React.FC = () => (
  <div className="u-mgb_m">
    <hr className="u-line_plane" />
    <div className="c-emptyState_box">検索条件が不正です。</div>
  </div>
);

const Events: React.FC<{
  events: TEvent[];
  checks: TAllSelectableChecks;
  setChecks: React.Dispatch<React.SetStateAction<TAllSelectableChecks>>;
}> = ({ events, checks, setChecks }) => {
  const [checkAllForSendOffer, setCheckAllForSendOffer] = useState(false);
  const [checksForSendOffer, setChecksForSendOffer] = useState<TCheck[]>(
    events.flatMap((e) =>
      e.requestPhotographers
        .filter(
          (p) =>
            // NOTE: 依頼未送信&&顔文字くん以外
            isUndecided(p.requestStatus) &&
            !p.isTemporary &&
            !p.requestSentDate &&
            !!p.id
        )
        .map((p) => ({
          id: p.eventRequestPhotographerId,
          checked: false,
          // NOTE: 撮影中止だったら送れない
          disabled: !!e.isCanceled,
        }))
    )
  );

  const onChangeCheckAll: ChangeEventHandler = useCallback(() => {
    toggleCheckAll(setChecks);
  }, [setChecks]);
  const onChangeCheck: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      toggleCheck(e.target, setChecks);
    },
    [setChecks]
  );

  // FIXME: 最新実装（toggleAllSelectableCheckboxes）にリプレイス
  const onChangeCheckAllForSendOffer: ChangeEventHandler = useCallback(() => {
    legacyToggleCheckAll(
      checkAllForSendOffer,
      checksForSendOffer,
      setCheckAllForSendOffer,
      setChecksForSendOffer
    );
  }, [
    checkAllForSendOffer,
    checksForSendOffer,
    setCheckAllForSendOffer,
    setChecksForSendOffer,
  ]);
  const onChangeCheckForSendOffer: ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (e) => {
        legacyToggleCheck(
          e.target,
          checksForSendOffer,
          setCheckAllForSendOffer,
          setChecksForSendOffer
        );
      },
      [checksForSendOffer, setCheckAllForSendOffer, setChecksForSendOffer]
    );

  const onClickSendOffer: MouseEventHandler<HTMLButtonElement> =
    useCallback(() => {
      const submitPhotographerChecks = checksForSendOffer.filter(
        (c) => c.checked
      );
      if (submitPhotographerChecks.length <= 0) {
        window.alert('対象のカメラマンを選択してください');
        return;
      }
      const win = window.open(
        '/arrange_photographer/send_offer?' +
          QueryString.stringify(
            {
              ids: submitPhotographerChecks.map((c) => c.id),
            },
            {
              arrayFormat: 'bracket',
            }
          ),
        '_blank'
      );
      win!.focus();
    }, [checksForSendOffer]);

  return (
    <table className="c-indexList">
      <thead>
        <tr>
          <th className="u-align_center">
            {isDisplayAllChecks(checks.checks) ? (
              <label className="c-checkbox_listbox">
                <input
                  type="checkbox"
                  className="c-checkbox_list"
                  name="check-all"
                  value="all"
                  checked={checks.checkedAll}
                  onChange={onChangeCheckAll}
                />
                {/* NOTE: レイアウトの都合で必要な空のelement */}
                <span className="c-label_checkbox_list c-label_checkbox_list__empty"></span>
              </label>
            ) : (
              '×'
            )}
          </th>
          <th>イベント名</th>
          <th>撮影日時</th>
          <th>団体情報</th>
          <th className="u-align_right">
            予想売上
            <br />
            (粗利)
          </th>
          <th className="u-align_right">参加人数</th>
          <th className="u-align_right">アサイン状況</th>
          <th>仮</th>
          <th>
            {checksForSendOffer.length > 0 ? (
              <>
                <label className="c-checkbox_listbox u-mgr_xs">
                  <input
                    type="checkbox"
                    className="c-checkbox_list"
                    name="check-all-send-mail"
                    value="all"
                    checked={checkAllForSendOffer}
                    onChange={onChangeCheckAllForSendOffer}
                  />
                  {/* NOTE: レイアウトの都合で必要な空のelement */}
                  <span className="c-label_checkbox_list c-label_checkbox_list__empty"></span>
                </label>
                未定
                <br />
                <button
                  className="c-btn_edit c-btn_small u-fz_s u-mgt_xs"
                  onClick={onClickSendOffer}
                >
                  依頼送信
                </button>
              </>
            ) : (
              '未定'
            )}
          </th>
          <th>依頼済</th>
          <th>確定</th>
          <th>辞退</th>
        </tr>
      </thead>
      <tbody>
        {events.map((e) => (
          <SearchResultItem
            key={e.id}
            event={e}
            onChangeCheck={onChangeCheck}
            checksForSendOffer={checksForSendOffer}
            onChangeCheckForSendOffer={onChangeCheckForSendOffer}
            check={checks.checks.find((c) => c.id === e.id)!}
          />
        ))}
      </tbody>
    </table>
  );
};

const getAndValidTargetEventIds = (
  checks: TAllSelectableChecks,
  events: TEvent[]
): number[] | void => {
  const submitEventChecks = checks.checks.filter((c) => c.checked);
  if (submitEventChecks.length <= 0) {
    window.alert('対象のイベントを選択してください');
    return;
  }
  // NOTE: Setで日付の重複を除いている
  const containsMultipleDays = Array.from(
    new Set(
      submitEventChecks.map(
        (c) => events.find((e) => e.id === c.id)!.photographingDay
      )
    )
  );
  if (containsMultipleDays.length > 1) {
    window.alert('同じ撮影日のイベントを選択してください');
    return;
  }

  return submitEventChecks.map((c) => c.id);
};

const EventList: React.FC<{
  events: TEvent[];
  queryString: string;
  pagination: TPagination | null;
  validator: TValidator;
}> = React.memo(({ events, queryString, pagination, validator }) => {
  const history = useHistory();
  const [checks, setChecks] = useState<TAllSelectableChecks>(() => {
    const checks = events.map((e) => ({
      id: e.id,
      checked: false,
      disabled: !!e.isCanceled,
    }));
    return {
      checkedAll: isCheckedAllChecks(checks),
      checks,
    };
  });
  const [assignAutoButtonDisabled, setAssignAutoButtonDisabled] =
    useState(false);

  const onClickAssignAutoButton: MouseEventHandler<HTMLButtonElement> =
    useCallback(async () => {
      const eventIds = getAndValidTargetEventIds(checks, events);
      if (!eventIds) {
        return;
      }
      if (
        !window.confirm(
          eventIds.length +
            '件のイベントの自動アサインを行います。よろしいですか？'
        )
      ) {
        return;
      }
      try {
        setAssignAutoButtonDisabled(!assignAutoButtonDisabled);
        await postJson<TAssignPhotographersAutoResponse>(
          '/api/arrange_photographer/assign_photographers_auto',
          { eventIds }
        );
        successToast('自動アサインが完了しました');
        history.go(0);
      } catch (error) {
        setAssignAutoButtonDisabled(!assignAutoButtonDisabled);
        errorToast(
          (error as Not200Error).jsonMessage?.message ?? 'エラーが発生しました'
        );
      }
    }, [checks, events, history, assignAutoButtonDisabled]);

  const onClickAssignButton: MouseEventHandler<HTMLButtonElement> =
    useCallback(() => {
      const eventIds = getAndValidTargetEventIds(checks, events);
      if (!eventIds) {
        return;
      }
      const win = window.open(
        '/arrange_photographer/recommended_photographers?' +
          QueryString.stringify(
            { eventIds },
            {
              arrayFormat: 'bracket',
            }
          ),
        '_blank'
      );
      win!.focus();
    }, [checks, events]);

  return (
    <div className="c-frame">
      <div className="l-flex_between_center u-mgb_s">
        <p className="c-text_resultNumber">
          <span>{pagination?.total}</span>
          <small>件の結果</small>
        </p>
        <div className="l-flex_end">
          <div className="u-mgr_m">
            <button
              type="button"
              className={`c-btn_rectangle c-btn_edit u-mgr_s ${
                assignAutoButtonDisabled ? 'is-disabled' : ''
              }`}
              onClick={onClickAssignAutoButton}
            >
              自動アサイン
            </button>
            <button
              type="button"
              className="c-btn_rectangle c-btn_create"
              onClick={onClickAssignButton}
            >
              アサイン画面を表示
            </button>
          </div>
          <div className="c-pagination_upper">
            <Paginator
              pagination={pagination}
              currentPath="/arrange_photographer"
              queryString={queryString}
            />
          </div>
        </div>
      </div>
      {validator.hasError ? (
        <HasValidationError />
      ) : !events.length ? (
        <HasNoResult />
      ) : (
        <Events events={events} checks={checks} setChecks={setChecks} />
      )}
      <Paginator
        pagination={pagination}
        currentPath="/arrange_photographer"
        queryString={queryString}
      />
    </div>
  );
});

const App: React.FC = () => {
  const history = useHistory();
  const orgQueryString = useLocation().search;

  const queryParams = orgQueryString
    ? convertQueryToFormData(orgQueryString, {
        messages: {},
        hasError: false,
        rules: {
          notCanceled: { type: 'integer' },
          arrangeStatus: { type: 'integer' },
        },
      })
    : {
        photographingDayFrom: dateFormatHyphen(dayjs().startOf('month')),
        photographingDayTo: dateFormatHyphen(dayjs().endOf('month')),
        notCanceled: 1,
      };
  const queryString =
    '?' +
    QueryString.stringify(queryParams, {
      arrayFormat: 'bracket',
    });
  const { data, error } = useJsonApiForSearchForm<TIndexResponse>(
    '/api/arrange_photographer',
    queryString
  );

  const onSubmit: SubmitHandler<TFormInputs> = useCallback(
    async (formData) => {
      const newPath = makePathForSearchForm(
        '/arrange_photographer',
        queryString,
        formData
      );

      history.push(newPath);
    },
    [history, queryString]
  );

  if (error) {
    return renderError(error);
  }
  if (!data) {
    return <Loading />;
  }

  const { data: events, validator, formItems, pagination } = data;

  return (
    <div className="c-index p-arrangePhotographerIndex">
      <h1 className="l-flex_center u-mgb_m c-page_title">カメラマン手配</h1>
      <div className="l-center_wrap">
        <SearchForm
          validator={validator}
          formItems={formItems}
          queryParams={queryParams}
          onSubmit={onSubmit}
        />
        <EventList
          events={events}
          queryString={queryString}
          validator={validator}
          pagination={pagination}
        />
      </div>
    </div>
  );
};

export default App;
