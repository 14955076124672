import { renderError } from '../../../../ts/useApi';
import Loading from '../../../shared/Loading/App';
import Paginator from '@/components/shared/Paginator/App';
import SearchForm from './SearchForm';
import SearchResultSocietiesList from './SearchResultSocietiesList';
import SearchResultGroupList from './SearchResultGroupsList';
import {
  TGroupsResponse,
  TSocietiesResponse,
  TFormInputs,
  TSociety,
  TGroups,
} from './types';
import React, { useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import './app.scss';
import {
  makePathForSearchForm,
  convertQueryToFormData,
} from '@/ts/makePathForSearchForm';
import { useJsonApiForSearchForm } from '../../../../ts/useJsonApiForSearchForm';
import { SubmitHandler } from 'react-hook-form';
import { fetcher } from '../../../../ts/useApi';
import {
  errorToast,
  errorToastWithValidatorMessages,
} from '../../../../ts/toast';
import { TPagination } from '@/components/shared/Paginator/types';
import OverlaySpinner from '../../../shared/OverlaySpinner/App';

const QUERYSTRING_PER_PAGE = '&perPage=8';

const App: React.FC = () => {
  const [dataSocieties, setSocieties] = useState<TSociety[] | []>([]);
  const [pageForSocieties, setPageForSocieties] = useState<TPagination | null>(
    null
  );
  const [dataGroups, setGroups] = useState<TGroups[] | []>([]);
  const [pageForGroups, setPageForGroups] = useState<TPagination | null>(null);
  const [searchflag, setSearchflag] = useState<boolean>(true);
  const [searchflagForGroups, setSearchflagForGroups] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const queryString = useLocation().search;

  const { data: societiesData, error: societiesError } =
    useJsonApiForSearchForm<TSocietiesResponse>(
      '/api/societies',
      queryString + QUERYSTRING_PER_PAGE
    );
  const { data: groupsData, error: groupsError } =
    useJsonApiForSearchForm<TGroupsResponse>(
      '/api/groups',
      queryString + QUERYSTRING_PER_PAGE
    );

  const queryParams = convertQueryToFormData(queryString);
  const onSubmit: SubmitHandler<TFormInputs> = useCallback(
    (formData) => {
      const newPath = makePathForSearchForm(
        '/groups_societies',
        queryString,
        formData
      );

      setSearchflag(true);
      setSearchflagForGroups(true);
      history.push(newPath);
    },
    [history, queryString, setSearchflag, setSearchflagForGroups]
  );

  if (societiesError) {
    return renderError(societiesError);
  }
  if (!societiesData) {
    return <Loading />;
  }

  const {
    data: societies,
    validator: societiesValidator,
    formItems,
    pagination: societiesPagination,
  } = societiesData;

  if (groupsError) {
    return renderError(groupsError);
  }
  if (!groupsData) {
    return <Loading />;
  }

  const {
    data: groups,
    validator: groupsValidator,
    pagination: groupsPagination,
  } = groupsData;

  const paginatorOnChangeForSociety = async (path: string) => {
    try {
      setLoading(true);
      const response = await fetcher<TSocietiesResponse>(
        path + QUERYSTRING_PER_PAGE
      );
      if (response.validator.hasError) {
        errorToastWithValidatorMessages(
          'データの取得に失敗しました',
          response.validator.messages
        );
        setLoading(false);
      } else {
        setSearchflag(false);
        setSocieties(response.data);
        setPageForSocieties(response.pagination);
        setLoading(false);
      }
    } catch (error) {
      errorToast('エラーが発生しました');
      setLoading(false);
    }
  };

  const paginatorOnChangeForGroups = async (path: string) => {
    try {
      setLoading(true);
      const response = await fetcher<TGroupsResponse>(
        path + QUERYSTRING_PER_PAGE
      );
      if (response.validator.hasError) {
        errorToastWithValidatorMessages(
          'データの取得に失敗しました',
          response.validator.messages
        );
        setLoading(false);
      } else {
        setSearchflagForGroups(false);
        setGroups(response.data);
        setPageForGroups(response.pagination);
        setLoading(false);
      }
    } catch (error) {
      errorToast('エラーが発生しました');
      setLoading(false);
    }
  };

  return (
    <div className="App">
      {loading && <OverlaySpinner />}
      <h3 className="l-flex_center u-mgb_m">
        <span className="c-page_title">団体一覧</span>
      </h3>
      <div className="c-index l-center_wrap">
        <SearchForm
          validator={societiesValidator}
          formItems={formItems}
          queryParams={queryParams}
          onSubmit={onSubmit}
        />

        <div className="c-frame">
          <div className="l-flex_between_center u-mgb_s">
            <p className="c-text_resultNumber">
              <span className="u-mgr_m">直販・先生</span>
              <span>
                {searchflag
                  ? societiesPagination?.total || 0
                  : pageForSocieties?.total || 0}
              </span>
              <small>件の結果</small>
            </p>
          </div>
          {societiesValidator.hasError ? (
            <div className="u-mgb_m">
              <hr className="u-line_plane" />
              <div className="c-emptyState_box">検索条件が不正です。</div>
            </div>
          ) : societies.length === 0 ? (
            <div className="u-mgb_m">
              <hr className="u-line_plane" />
              <div className="c-emptyState_box">
                ご指定の条件では見つかりませんでした
              </div>
            </div>
          ) : (
            <SearchResultSocietiesList
              societies={searchflag ? societies : dataSocieties}
            />
          )}
          <Paginator
            pagination={searchflag ? societiesPagination : pageForSocieties}
            currentPath="/api/societies"
            queryString={queryString}
            onChangePath={paginatorOnChangeForSociety}
          />
        </div>

        <div className="c-frame">
          <div className="l-flex_between_center u-mgb_s">
            <p className="c-text_resultNumber">
              <span className="u-mgr_m">パートナー</span>
              <span>
                {searchflagForGroups
                  ? groupsPagination?.total || 0
                  : pageForGroups?.total || 0}
              </span>
              <small>件の結果</small>
            </p>
          </div>
          {groupsValidator.hasError ? (
            <div className="u-mgb_m">
              <hr className="u-line_plane" />
              <div className="c-emptyState_box">検索条件が不正です。</div>
            </div>
          ) : groups.length === 0 ? (
            <div className="u-mgb_m">
              <hr className="u-line_plane" />
              <div className="c-emptyState_box">
                ご指定の条件では見つかりませんでした
              </div>
            </div>
          ) : (
            <SearchResultGroupList
              groups={searchflagForGroups ? groups : dataGroups}
            />
          )}
          <Paginator
            pagination={searchflagForGroups ? groupsPagination : pageForGroups}
            currentPath="/api/groups"
            queryString={queryString}
            onChangePath={paginatorOnChangeForGroups}
          />
        </div>
      </div>
    </div>
  );
};

export default App;
