import React, { useEffect, useState } from 'react';
import {
  renderError,
  useJsonApi,
  toMessages,
  fetcher,
} from '../../../../ts/useApi';
import Loading from '../../../shared/Loading/App';
import { TValidatorResponse } from '@/components/shared/Form/types';
import { removeEmpty } from '@/ts/objectTools';
import { Form } from '../Form';
import {
  TCreateResponse,
  TSaveResponse,
  TFormInputs,
  TFormItems,
  BackButton,
} from '../Form/types';
import { usePreventDuplicateCall } from '@/ts/usePreventDuplicateCall';
import { alertApiError } from '../../../../ts/formValidation';
import { errorToast, successToast } from '../../../../ts/toast';
import { useHistory } from 'react-router';

const emptyValidator = {
  messages: {},
  hasError: false,
  rules: {},
};

const App: React.FC = () => {
  const { data, error } = useJsonApi<TCreateResponse>(
    '/api/photographer_organizations/create'
  );
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [validator, setValidator] =
    useState<TValidatorResponse>(emptyValidator);
  const history = useHistory();
  const onSubmit = usePreventDuplicateCall(async (formData) => {
    try {
      const saveResponse = (await fetcher('/api/photographer_organizations', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(removeEmpty(formData)),
      })) as TSaveResponse;
      setErrorMessages([]);
      if (saveResponse.validator.hasError) {
        setValidator(saveResponse.validator);
        alertApiError();
        return;
      }
      successToast('登録しました');
      history.push(
        `/photographer_organizations/${saveResponse.photographerOrganizationId}`
      );
    } catch (e) {
      setErrorMessages(toMessages(e));
      errorToast('エラーが発生しました');
    }
  });

  useEffect(() => {
    if (data) {
      setValidator(data.validator);
    }
  }, [data]);

  if (error) {
    return renderError(error);
  }
  if (!validator || !data) {
    return <Loading />;
  }

  const formItems: TFormItems = data.formItems;
  const defaultValue: Partial<TFormInputs> = {
    type: formItems.typesDefaultValue,
    status: formItems.statusesDefaultValue,
    business_registration_number: '',
  };
  const backButton: BackButton = {
    name: '戻る',
    url: '/photographer_organizations',
  };

  return (
    <div className="App">
      <h3 className="l-flex_center u-mgb_m">
        <span className="c-page_title">カメラマン組織 - 新規登録</span>
      </h3>
      <div className="l-center_wrap">
        <Form
          formItems={formItems}
          onSubmit={onSubmit}
          validator={validator}
          defaultValue={defaultValue}
          errorMessages={errorMessages}
          backButton={backButton}
        />
      </div>
    </div>
  );
};

export default App;
