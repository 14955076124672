import React from 'react';
import { Link, useParams } from 'react-router-dom';

import { useJsonApi } from '@/ts/useJsonApi';

import Loading from '@/components/shared/Loading/App';
import { renderError } from '@/ts/useApi';

import SendForm from './Create/SendForm';
import { TResponse } from './types';

const App: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data, error } = useJsonApi<TResponse>(
    `/api/events/${id}/samplebook/mail/create`
  );
  if (error) {
    return renderError(error);
  }
  if (!data) {
    return <Loading />;
  }

  return (
    <>
      <Link className="c-page_back" to={`/events/${id}`}>
        戻る
      </Link>
      <h3 className="l-flex_center u-mgb_m">
        <span className="c-page_title">サンプルブックメール</span>
      </h3>
      <div className="c-frame">
        <SendForm data={data.data} eventno={id} validator={data.validator} />
      </div>
    </>
  );
};
export default App;
