import React from 'react';
import { TValidatorResponse } from '@/components/shared/Form/types';

import GeneralPdfDetail from '../../../shared/IncentivePayments/Show/PdfDetails';
import { TShowPdfData, TSubmit } from './types';
import Header from './Header';

const PdfDetails = React.memo<{
  validator: TValidatorResponse;
  data: TShowPdfData;
  submit: TSubmit;
}>((props) => {
  const {
    partner,
    incentivePayment,
    conditions: { canEdit },
  } = props.data;
  const PartnerHeader = (
    <Header
      type="PDF"
      canEdit={canEdit}
      incentivePaymentId={incentivePayment.id}
      partnerName={partner.name}
      status={incentivePayment.status}
      statusName={incentivePayment.statusName}
      issuedOn={incentivePayment.issuedOn}
      isShowCsvDownloadLink={false}
      businessRegistrationNumber={partner.businessRegistrationNumber}
      businessRegistrationTradeName={partner.businessRegistrationTradeName}
    />
  );

  return (
    <GeneralPdfDetail target="PARTNER" Header={PartnerHeader} {...props} />
  );
});

export default PdfDetails;
