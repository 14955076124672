import React from 'react';

import { TValidatorResponse } from '@/components/shared/Form/types';
import GeneralGroupedInventivePaymentEvent from '../../../shared/IncentivePayments/Show/GroupedIncentivePaymentEvent';
import { TGroupedIncentivePaymentEvent } from './types';
import GroupHeader from './GroupHeader';

const GroupedIncentivePaymentEvent: React.FC<{
  canEdit: boolean;
  validator: TValidatorResponse;
  groupedIncentivePaymentEvent: TGroupedIncentivePaymentEvent;
}> = React.memo((props) => {
  const { isBulkDelivery, groupName, planName } =
    props.groupedIncentivePaymentEvent;

  return (
    <>
      <GroupHeader
        isBulkDelivery={isBulkDelivery}
        groupName={groupName}
        planName={planName}
      />
      <GeneralGroupedInventivePaymentEvent {...props} />
    </>
  );
});

export default GroupedIncentivePaymentEvent;
