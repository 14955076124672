import React, { useCallback } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Paginator from '@/components/shared/Paginator/App';
import {
  makePathForSearchForm,
  convertQueryToFormData,
} from '@/ts/makePathForSearchForm';

import { renderError } from '../../../../ts/useApi';
import { useJsonApiForSearchForm } from '../../../../ts/useJsonApiForSearchForm';
import Loading from '../../../shared/Loading/App';
import SocietiesList from './SocietiesList';
import SearchForm from './SearchForm';
import { ApiResult, TFormInputs } from './types';
import DownloadCsv from './DownloadCsv';
import './app.scss';

const App: React.FC = () => {
  const history = useHistory();
  const queryString = useLocation().search;
  const { data: result, error } = useJsonApiForSearchForm<ApiResult>(
    '/api/societies',
    queryString
  );

  const queryParams = convertQueryToFormData(queryString);
  const onSubmit: SubmitHandler<TFormInputs> = useCallback(
    (formData) => {
      delete formData.globalSearch;
      const newPath = makePathForSearchForm(
        '/societies/',
        queryString,
        formData
      );

      history.push(newPath);
    },
    [history, queryString]
  );

  if (error) {
    return renderError(error);
  }
  if (!result) {
    return <Loading />;
  }

  return (
    <div className="App">
      <h3 className="l-flex_center u-mgb_m">
        <span className="c-page_title">団体一覧</span>
      </h3>
      <div className="l-center_wrap">
        <SearchForm
          validator={result.validator}
          formItems={result.formItems}
          queryParams={queryParams}
          onSubmit={onSubmit}
        />
        <div className="c-frame">
          <div className="l-flex_between_center u-mgb_s">
            <p className="c-text_resultNumber">
              <span>{result.pagination?.total || 0}</span>
              <small>件の結果</small>
            </p>
            <div className="l-flex_end">
              <DownloadCsv
                queryParams={queryParams}
                paginatorTotal={result.pagination?.total}
              />
              <div className="c-pagination_upper u-mgr_s">
                <Paginator
                  pagination={result.pagination}
                  currentPath="/societies"
                  queryString={queryString}
                />
              </div>
              <Link
                className="c-btn_rectangle c-btn_create"
                to="/societies/create"
              >
                <i className="c-icon c-icon__small c-icon_create" />
              </Link>
            </div>
          </div>
          {result.validator.hasError ? (
            <div className="u-mgb_m">
              <hr className="u-line_plane" />
              <div className="c-emptyState_box">検索条件が不正です。</div>
            </div>
          ) : result.data.length === 0 ? (
            <div className="u-mgb_m">
              <hr className="u-line_plane" />
              <div className="c-emptyState_box">
                ご指定の条件では見つかりませんでした
              </div>
            </div>
          ) : (
            <SocietiesList societies={result.data} />
          )}
          <Paginator
            pagination={result.pagination}
            currentPath="/societies"
            queryString={queryString}
          />
        </div>
      </div>
    </div>
  );
};

export default App;
