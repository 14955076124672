import { PageContainer } from '@/components/shared/PageContainer';
import { SpitContentPanel } from '@/components/shared/SpitContentPanel';
import React from 'react';
import { PhotoIdSearchPanel } from './components/PhotoIdSearchPanel';
import { PhotoNameSearchPanel } from './components/PhotoNameSearchPanel';
import { usePhotoSearch } from './hooks';
import Loading from '@/components/shared/Loading/App';
import { ResultPanel } from './components/ResultPanel';
import { Notice } from '@/components/shared/Notice';
import styles from './index.module.scss';
import { ErrorPanel } from '@/components/shared/Form/Errors';

export const PhotoSearch = React.memo(function PhotoSearch() {
  const {
    loading,
    searchParam,
    photographs,
    errorMessages,
    handleIdSearchSubmit,
    handleNameSearchSubmit,
  } = usePhotoSearch();

  if (loading) {
    return <Loading />;
  }

  return (
    <PageContainer title="写真検索">
      {photographs?.length === 0 && (
        <div className={styles.noticeBlock}>
          <Notice
            type="warning"
            message="お探しの写真は見つかりませんでした。"
          />
        </div>
      )}
      {<ErrorPanel messages={errorMessages} />}
      <SpitContentPanel
        variant="no-frame"
        leftContent={
          <PhotoIdSearchPanel
            ids={searchParam?.idSearchFormParams?.ids}
            doSubmit={handleIdSearchSubmit}
          />
        }
        rightContent={
          <PhotoNameSearchPanel
            {...searchParam?.nameSearchFormParams}
            doSubmit={handleNameSearchSubmit}
          />
        }
      />
      <ResultPanel photographs={photographs} />
    </PageContainer>
  );
});
