import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { renderError, useJsonApi } from '../../../../ts/useApi';
import Loading from '../../../shared/Loading/App';
import { THiddenPhotographsResponse } from './types';
import EventHeader from '../EventHeader';
import HiddenPhotographs from './HiddenPhotographs';

const App: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: response, error } = useJsonApi<THiddenPhotographsResponse>(
    `/api/events/${id}/hidden_photographs`
  );

  if (error) return renderError(error);
  if (!response) return <Loading />;

  const {
    data: { photographs, ...shortResponseData },
  } = response;

  return (
    <div className="p-partnerSummary c-summary">
      <Link className="c-page_back" to={`/events/${id}`}>
        イベントサマリに戻る
      </Link>
      <h1 className="u-mgb_m c-page_title">非表示写真一覧</h1>
      <EventHeader {...shortResponseData} />
      <HiddenPhotographs eventId={Number(id)} photographs={photographs} />
    </div>
  );
};

export default App;
