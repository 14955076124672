import React, { useEffect, useState } from 'react';
import {
  renderError,
  useJsonApi,
  toMessages,
  fetcher,
} from '../../../../ts/useApi';
import { TCreateResponse, TFormInputs, TSaveResponse } from './types';
import SaveForm from './SaveForm';
import Loading from '../../../shared/Loading/App';
import { SubmitHandler } from 'react-hook-form';
import {
  TOnlyValidationRuleResponse,
  TValidatorResponse,
} from '@/components/shared/Form/types';
import { removeEmpty } from '@/ts/objectTools';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { alertApiError } from '../../../../ts/formValidation';
import { errorToast } from '../../../../ts/toast';

const emptyValidator = {
  messages: {},
  hasError: false,
  rules: {},
};

const App: React.FC = () => {
  const location =
    useLocation<{ subject: string; body: string; society_id: string }>();
  const { ownerno } = useParams<{ ownerno: string }>();
  const { data, error } = useJsonApi<TCreateResponse>(
    '/api/owneraccounts/' + ownerno + '/invitation_mail/input'
  );

  const [validator, setValidator] = useState<
    TValidatorResponse | TOnlyValidationRuleResponse
  >(emptyValidator);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const history = useHistory();

  useEffect(() => {
    if (data) {
      setValidator(data.validator);
    }
  }, [data]);

  if (error) {
    return renderError(error);
  }
  if (!data) {
    return <Loading />;
  }

  const formItems = data.formItems;
  const society_id =
    location.state === undefined
      ? formItems.invitation_mail.society_id
      : location.state.society_id;

  const onSubmit: SubmitHandler<TFormInputs> = async (formData) => {
    try {
      const saveResponse = (await fetcher(
        '/api/owneraccounts/' + ownerno + '/invitation_mail/confirm',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(removeEmpty(formData)),
        }
      )) as TSaveResponse;
      setErrorMessages([]);
      if (saveResponse.validator.hasError) {
        setValidator(saveResponse.validator);
        alertApiError('メール送信の確認に失敗しました。');
        return;
      }

      history.push({
        pathname: `/owneraccounts/${ownerno}/invitation_mail/confirm`,
        state: {
          subject: formData.subject,
          body: formData.body,
          society_id: society_id,
        },
      });
    } catch (e) {
      setErrorMessages(toMessages(e));
      errorToast('エラーが発生しました');
    }
  };

  const defaultValues = {
    subject:
      location.state === undefined
        ? formItems.invitation_mail.subject
        : location.state.subject,
    body:
      location.state === undefined
        ? formItems.invitation_mail.body
        : location.state.body,
  };

  return (
    <div className="App">
      <h3 className="l-flex_center u-mgb_m">
        <span className="c-page_title">管理者 - 招待メール作成</span>
      </h3>
      <div className="l-center_wrap">
        <SaveForm
          ownerno={ownerno}
          confirmflag={false}
          validator={validator}
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          errorMessages={errorMessages}
          submitButton="確認する"
          cancelLink={`/societies/${society_id}`}
        />
      </div>
    </div>
  );
};

export default App;
