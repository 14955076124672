import React, { useCallback } from 'react';
import { renderError } from '@/ts/useApi';
import { transformBoolToNumber } from '@/ts/transformBoolToNumber';
import { SubmitHandler } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';

import Loading from '@/components/shared/Loading/App';
import SearchForm from './SearchForm';
import SearchResultItem from './SearchResultItem';
import Paginator from '@/components/shared/Paginator/App';
import {
  convertQueryToFormData,
  makePathForSearchForm,
} from '@/ts/makePathForSearchForm';

import { useJsonApiForSearchForm } from '@/ts/useJsonApiForSearchForm';
import {
  TFormInputs,
  TIndexResponse,
  TEvents,
  TFormName,
  TConditions,
  TFormItems,
} from './types';
import './style.scss';
import { removeEmpty } from '@/ts/objectTools';
import DownloadCsv from './DownloadCsv';

// 入力値はstring型で渡されるので、formの値に合わせて型を変換
const formatInputValuesToForm = (value: TFormName): TFormName => {
  // 初期値で値が不要な要素群は、初期値から除外する
  return Object.assign(
    {},
    value,
    removeEmpty({
      removeCancelled: value.removeCancelled === undefined ? false : 1,
      unDecidedValueStartDay:
        value.unDecidedValueStartDay === undefined ? false : 1,
      unDecidedValueEndDay:
        value.unDecidedValueEndDay === undefined ? false : 1,
      unDecidedPublishEndDay:
        value.unDecidedPublishEndDay === undefined ? false : 1,
      unDecidedFlyerSendDay:
        value.unDecidedFlyerSendDay === undefined ? false : 1,
      unDecidedSbSendDay: value.unDecidedSbSendDay === undefined ? false : 1,
      unDecidedFaxSendDay: value.unDecidedFaxSendDay === undefined ? false : 1,
      unDecidedDvdSendDay: value.unDecidedDvdSendDay === undefined ? false : 1,
      unDecidedAdvanceSbSendDay:
        value.unDecidedAdvanceSbSendDay === undefined ? false : 1,
    }),
    {
      // ラジオボタンなどはデフォルト値で空文字を指定する必要があるので、filterInputValuesToFormを噛ませない
      eventIds: value.eventIds ? value.eventIds : '',
      partnerIds: value.partnerIds ? value.partnerIds : '',
      societyIds: value.societyIds ? value.societyIds : '',
      eventName: value.eventName ? value.eventName : '',
      partnerName: value.partnerName ? value.partnerName : '',
      societyName: value.societyName ? value.societyName : '',
      eventOptions: value.eventOptions ? value.eventOptions : [],
      planIds: value.planIds ? value.planIds : [],
      openFlags: value.openFlags ? value.openFlags : [],
      eventTypeIds: value.eventTypeIds ? value.eventTypeIds : [],
      groupTypeIds: value.groupTypeIds ? value.groupTypeIds : [],
      eigyoOwnerNos: value.eigyoOwnerNos ? value.eigyoOwnerNos : [],
      photographerIds: value.photographerIds ? value.photographerIds : [],
      isFlyerSend: value.isFlyerSend ? Number(value.isFlyerSend) : '',
      flyerPreDeliveryType: value.flyerPreDeliveryType
        ? Number(value.flyerPreDeliveryType)
        : '',
      isSbSend: value.isSbSend ? Number(value.isSbSend) : '',
      isFaxSend: value.isFaxSend ? Number(value.isFaxSend) : '',
      isDvdSend: value.isDvdSend ? Number(value.isDvdSend) : '',
      isAdvanceSbSend: value.isAdvanceSbSend
        ? Number(value.isAdvanceSbSend)
        : '',
      hasConv: value.hasConv ? Number(value.hasConv) : '',
      hasPriorConfirmation: value.hasPriorConfirmation
        ? Number(value.hasPriorConfirmation)
        : '',
      hasPrice: value.hasPrice ? Number(value.hasPrice) : '',
      hasCertificationKey: value.hasCertificationKey
        ? Number(value.hasCertificationKey)
        : '',
      certificaitonKey: value.certificaitonKey ? value.certificaitonKey : '',
    }
  );
};

const App: React.FC = () => {
  const history = useHistory();
  const queryString = useLocation().search;
  const { data: response, error } = useJsonApiForSearchForm<TIndexResponse>(
    '/api/events/',
    queryString
  );

  const onSubmit: SubmitHandler<TFormInputs> = useCallback(
    async (formData) => {
      delete formData.globalSearch;
      const newPath = makePathForSearchForm(
        '/events',
        queryString,
        removeEmpty(transformBoolToNumber(formData))
      );

      history.push(newPath);
    },
    [history, queryString]
  );

  if (error) {
    return renderError(error);
  }
  if (!response) {
    return <Loading />;
  }

  const { validator, formItems, inputs } = response;
  return (
    <div className="l-content_wrap">
      {/* ページタイトル */}
      <h1 className="l-flex_center u-mgb_m">
        <span className="c-page_title">イベント一覧</span>
      </h1>
      <div className="App">
        <div className="l-content">
          <div className="l-center_wrap">
            {/* 検索フォーム */}
            <div className="c-frame">
              <SearchForm
                validator={validator}
                formItems={formItems}
                queryParams={formatInputValuesToForm(inputs)}
                onSubmit={onSubmit}
              />
            </div>
            {/* 検索結果表示 */}
            <div className="c-frame">
              <Results response={response} queryString={queryString} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Results: React.FC<{ response: TIndexResponse; queryString: string }> = ({
  response,
  queryString,
}) => {
  const {
    data: events,
    validator,
    pagination,
    conditions,
    formItems,
  } = response;
  const queryParams = convertQueryToFormData(queryString);

  return (
    <>
      {/* paginator */}
      <div className="l-flex_between_center u-mgb_s">
        <p className="c-text_resultNumber">
          <span>{pagination?.total}</span>
          <small>件の結果</small>
        </p>
        <div className="l-flex">
          {/* 期間設定 */}
          {queryParams.removeCancelled === '1' &&
            JSON.stringify(queryParams.planIds) === JSON.stringify(['1']) &&
            Array.isArray(queryParams.societyIds) &&
            queryParams.societyIds.length === 1 && (
              <button
                type="button"
                className="c-btn_large c-btn_manage u-mgr_m"
                onClick={() => {
                  window.open('/events/period' + queryString, '_blank');
                }}
              >
                <i className="c-icon_event t-textColor_white u-mgr_xs" />
                <span>期間設定</span>
              </button>
            )}
          <DownloadCsv
            queryParams={queryParams}
            paginatorTotal={pagination?.total}
          />
          <div className="c-pagination_upper u-mgr_s">
            <Paginator
              pagination={pagination}
              currentPath="/events"
              queryString={queryString}
            />
          </div>
        </div>
      </div>
      <>
        {validator.hasError ? (
          <FailedGetEvents reason={'検索条件が不正です。'} />
        ) : !events.length ? (
          <FailedGetEvents reason={'条件を満たす結果が存在しません'} />
        ) : (
          <ResultList
            events={events}
            conditions={conditions}
            formItems={formItems}
          />
        )}
      </>
      <Paginator
        pagination={pagination}
        currentPath="/events"
        queryString={queryString}
      />
    </>
  );
};

const ResultList: React.FC<{
  events: TEvents[];
  conditions: TConditions;
  formItems: TFormItems;
}> = ({ events, conditions, formItems }) => (
  <>
    <div className="p-eventsIndex_scrollbar">
      {/* グリッドヘッダ */}
      <div className="grid_header p-eventsIndex_grid_other_department_container_wrap">
        <div className="p-eventsIndex_grid_other_department_container">
          <div className="p-eventsIndex_grid_row">
            <div
              className="
              p-eventsIndex_grid_caption
              p-eventsIndex_grid_caption_1st
            "
            >
              パートナー
            </div>
            <div
              className="
              p-eventsIndex_grid_caption
              p-eventsIndex_grid_caption_2nd
            "
            >
              団体
            </div>
            <div
              className="
              p-eventsIndex_grid_caption
              p-eventsIndex_grid_caption_3rd
            "
            >
              撮影日
            </div>
            <div
              className="
              p-eventsIndex_grid_caption
              p-eventsIndex_grid_caption_4th
            "
            >
              イベント
            </div>
            <div className="p-eventsIndex_grid_caption">種類</div>
            <div className="p-eventsIndex_grid_caption">
              申請
              <br />
              オプション
            </div>
            <div className="p-eventsIndex_grid_caption">公開設定</div>
            <div className="p-eventsIndex_grid_caption">
              <i className="c-icon_mail u-fz_s" />
            </div>{' '}
            {/* 事前確認<br/>依頼メール */}
            <div className="p-eventsIndex_grid_caption">
              <i className="c-icon_photo u-fz_s" />
            </div>{' '}
            {/* フォルダ */}
            <div className="p-eventsIndex_grid_caption">写真閲覧キー</div>
            <div className="p-eventsIndex_grid_caption">価格No.</div>
            <div className="p-eventsIndex_grid_caption">
              掲載
              <br />
              開始日
            </div>
            <div className="p-eventsIndex_grid_caption">
              バリュー
              <br />
              終了日
            </div>
            <div className="p-eventsIndex_grid_caption">
              掲載
              <br />
              終了日
            </div>
            <div className="p-eventsIndex_grid_caption">
              責任者
              <br />
              終了日
            </div>
            <div className="p-eventsIndex_grid_caption">チラシ</div>
            <div className="p-eventsIndex_grid_caption">SB</div>
            <div className="p-eventsIndex_grid_caption">
              FAX
              <br />
              用紙
            </div>
            <div className="p-eventsIndex_grid_caption">DVD</div>
            <div className="p-eventsIndex_grid_caption">
              確認用
              <br />
              SB
            </div>
            <div className="p-eventsIndex_grid_caption">一括配送入金締め日</div>
            <div className="p-eventsIndex_grid_caption">プラン</div>
            <div className="p-eventsIndex_grid_caption">担当営業</div>
            <div className="p-eventsIndex_grid_caption">詳細対応者</div>
          </div>
        </div>
      </div>
      {/* 詳細情報 */}
      {events.map((e, key) => (
        <div
          className="p-eventsIndex_grid_other_department_container"
          key={key}
        >
          <SearchResultItem
            events={e}
            conditions={conditions}
            formItems={formItems}
          />
        </div>
      ))}
    </div>
  </>
);

const FailedGetEvents: React.FC<{ reason: string }> = (prop) => (
  <div className="u-mgb_m">
    <hr className="u-line_plane" />
    <div className="c-emptyState_box">{prop.reason}</div>
  </div>
);

export default App;
