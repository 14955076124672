import React, { FC } from 'react';
import styles from './IndexPageLayout.module.scss';
import { RiLink } from 'react-icons/ri';
import { kanriUrl, ownerUrl } from '@/ts/url';
import { TMenuTitlesResponse } from '@/components/pages/Events/Show/types';
import { dateFormatFromISO8601, dateFormatHyphen } from '@/ts/formatTools';
import { LinkText } from '@/components/shared/LinkText';
import { EventSubMenu } from '@/components/pages/Events/Show/ShowPageLayout/EventSubMenu';
import { useLocation } from 'react-router-dom';

const SideMenu: FC<{
  response: TMenuTitlesResponse;
}> = ({ response }) => {
  const isEventSummaryTab = useLocation().pathname.endsWith(
    `/events/${response.eventId}`
  );
  return (
    <div className={styles.sideMenu}>
      <div className={styles.sideMenuEvent}>
        <p className={styles.sideMenuTitle}>イベント関連</p>
        <dl>
          <dt>団体詳細登録者</dt>
          <dd>
            {response.requesterName &&
              response.requestedAt &&
              `${response.requesterName}(${dateFormatFromISO8601(
                response.requestedAt
              )})`}
          </dd>
        </dl>
      </div>
      {!isEventSummaryTab && (
        <div className={styles.sideMenuLinkGroup}>
          <p className={styles.sideMenuTitle}>
            <RiLink />
            その他関連
          </p>
          <div>
            <a
              className="c-textlink"
              href={kanriUrl({
                action_owner_SHINSEItehailist2: 'true',
                society_id: response.societyId.toString(),
                photographingday_from:
                  dateFormatHyphen(response.photographingDay) ?? '',
              })}
            >
              手配リスト
            </a>
            <br />
            {response.hasEventRequest && (
              <>
                <a
                  className="c-textlink"
                  href={ownerUrl('events/requests', {
                    society_id: response.societyId.toString(),
                    photographing_day_from: '',
                  })}
                >
                  申請リスト
                </a>
                <br />
                <a
                  className="c-textlink"
                  href={ownerUrl('events/change_requests', {
                    photographing_day_from: '',
                    society_id: response.societyId.toString(),
                    relation: 'changes',
                  })}
                >
                  イベント変更申請リスト
                </a>
                <br />
              </>
            )}
            <LinkText to={`/events?societyIds[]=${response.societyId}`}>
              イベント一覧
            </LinkText>
          </div>
        </div>
      )}
      {isEventSummaryTab && <EventSubMenu response={response} />}
    </div>
  );
};

export const ShowPageLayout: FC<{ response: TMenuTitlesResponse }> = ({
  children,
  response,
}) => {
  return (
    <div className="l-flex l-col_wrap">
      <div className="l-col_18">{children}</div>
      <div className="l-col_6">
        <SideMenu response={response} />
      </div>
    </div>
  );
};
