import { Link, useParams } from 'react-router-dom';

import Loading from '../../../shared/Loading/App';
import { renderError, useJsonApi } from '../../../../ts/useApi';
import { TSocietyResponse } from './types';
import SocietyContractContainer from './SocietyContractContainer';
import CreateYearCertificationkey from '../../../shared/CreateYearCertificationkey/App';
import './style.scss';
import SocietyContainer from './SocietyContainer';
import SocietyHeader from './SocietyHeader';
import SubMenu from './SubMenu';
import { useState } from 'react';

const App: React.FC = () => {
  const [onDisplay, setOnDisplay] = useState(0);
  const { id } = useParams<{ id: string }>();
  const { data, error, mutate } = useJsonApi<TSocietyResponse>(
    '/api/societies/' + id
  );
  if (error) {
    return renderError(error);
  }
  if (!data) {
    return <Loading />;
  }
  const {
    data: {
      society,
      organization,
      societyStudents,
      requestPhotographers,
      societyDirect,
      societyContracts,
    },
    conditions: {
      currentFiscalYear,
      canCreateYearCertificationkey,
      canCreateSocietyContractIds,
    },
  } = data;

  return (
    <div className="p-groupsSummary c-summary">
      <Link className="c-page_back" to="/societies">
        一覧に戻る
      </Link>
      <h1 className="l-flex_center u-mgb_m c-page_title">団体サマリ</h1>
      <CreateYearCertificationkey
        target="societies"
        id={society.id}
        currentFiscalYear={currentFiscalYear}
        canCreate={canCreateYearCertificationkey}
        canCreateSocietyContractIds={canCreateSocietyContractIds}
      />
      <div className="l-content">
        <div className="l-center_wrap">
          <div className="c-frame">
            <SocietyHeader
              society={society}
              organization={organization}
              showEditButton
              showLinks
            />
            <div className="u-mgt_s u-mgb_m l-flex nowrap">
              <SocietyContainer
                society={society}
                organization={organization}
                societyDirect={societyDirect}
                societyStudents={societyStudents}
                requestPhotographers={requestPhotographers}
              />
              <SubMenu
                isPopup={false}
                society={society}
                organization={organization}
              />
            </div>
          </div>
          <div className="c-frame">
            <div className="l-flex_between_center u-mgb_s">
              <h4 className="c-section_title">契約情報</h4>
              <div className="l-flex_end">
                <Link
                  className="c-btn_rectangle c-btn_create u-mgr_s"
                  to={`/societies/${society.id}/society_contracts/create/direct`}
                >
                  + 直販契約を追加
                </Link>
                <Link
                  className="c-btn_rectangle c-btn_create"
                  to={`/societies/${society.id}/society_contracts/create/teacher`}
                >
                  + 先生契約を追加
                </Link>
              </div>
            </div>
            <ul role="tablist" className="c-tabList p-societies_contractTab">
              {societyContracts.map((societyContractContainer, index) => (
                <li
                  className={
                    'p-contractTabListItem ' +
                    (onDisplay === index && 'is-active')
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOnDisplay(index);
                  }}
                  key={index}
                >
                  <b>
                    NO.{index + 1}_
                    {
                      societyContractContainer.societyContract
                        .societyContractTypeName
                    }
                  </b>
                </li>
              ))}
            </ul>
            <div className="tab-content">
              {societyContracts.map(
                (societyContractContainer, index) =>
                  onDisplay === index && (
                    <div key={index}>
                      <SocietyContractContainer
                        key={societyContractContainer.societyContract.id}
                        societyContractContainer={societyContractContainer}
                        organizationName={organization.name}
                        index={index}
                        mutate={() => mutate()}
                      />
                    </div>
                  )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
