import { ApiErrors } from '@/components/shared/Form/Errors';
import { MultiSelect, TextArea } from '@/components/shared/Form/Inputs';
import {
  TOnlyValidationRuleResponse,
  TValidatorResponse,
} from '@/components/shared/Form/types';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { alertApiError } from '../../../../ts/formValidation';
import { errorToast, successToast } from '../../../../ts/toast';
import { useJsonApi, fetcher, renderError } from '../../../../ts/useApi';
import { usePreventDuplicateCall } from '../../../../ts/usePreventDuplicateCall';
import Loading from '../../../shared/Loading/App';
import { TResponse, TFormInputs, TSendResponse } from './types';

const emptyValidator = {
  messages: {},
  hasError: false,
  rules: {},
};

const App: React.FC = () => {
  const { data, error } = useJsonApi<TResponse>(`/api/event_schedule_pdf`);
  const [validator, setValidator] = useState<
    TValidatorResponse | TOnlyValidationRuleResponse
  >(emptyValidator);

  const methods = useForm<TFormInputs>();
  const onSubmit = usePreventDuplicateCall(async (formData) => {
    setValidator(emptyValidator);
    try {
      const sendResponse = (await fetcher('/api/event_schedule_pdf/send', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      })) as TSendResponse;
      if (sendResponse.validator.hasError) {
        setValidator(sendResponse.validator);
        alertApiError('送信に失敗しました');
        return;
      }
      successToast('送信しました');
    } catch (e) {
      errorToast('エラーが発生しました');
    }
  });

  const setSocietyContractIds = () => {
    const inputText = methods.getValues('societyContractIds');
    const inputs = inputText
      .split(/[\s,]/)
      .filter((id) => id.match(/^\d+$/))
      .map((id) => Number(id));
    methods.setValue('societyContracts', inputs);
  };

  useEffect(() => {
    if (data) {
      setValidator(data.validator);
    }
  }, [data]);

  if (error) {
    return renderError(error);
  }
  if (!data) {
    return <Loading />;
  }

  return (
    <div className="App">
      <h3 className="l-flex_center u-mgb_m">
        <span className="c-page_title">撮影日程確認書FAX送信</span>
      </h3>
      <FormProvider {...methods}>
        <ApiErrors {...validator} />
        <form
          method="POST"
          onSubmit={methods.handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <MultiSelect
            name="societyContracts"
            choices={data.formItems.societyContracts}
            placeholder="選択してください(複数選択可)"
            validator={data.validator}
            windowed={true}
          />
          <input
            className="c-btn_large c-btn_primary is-arrow c-input_submit"
            type="submit"
            value="送信"
          />
          <br />
          <br />
          <span>
            Excel等から団体契約IDをコピーして入力すると、入力した団体契約のみ選択されます。
            <br />
            スペース、タブ、改行、カンマ(",")を区切りとして認識します。それ以外の文字で区切るとエラーになります。
            <br />
          </span>
          <TextArea
            name="societyContractIds"
            placeholder="一括選択用"
            validator={data.validator}
          />
          <input
            className="c-btn_large c-btn_Search"
            value="一括選択"
            type="button"
            onClick={setSocietyContractIds}
          />
        </form>
      </FormProvider>
    </div>
  );
};

export default App;
