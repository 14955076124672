import React from 'react';
import { useLocation } from 'react-router-dom';

import { renderError } from '../../../../ts/useApi';
import { useJsonApiForSearchForm } from '../../../../ts/useJsonApiForSearchForm';
import Loading from '../../../shared/Loading/App';
import { TSendOfferFormResponse, TTargetPhotographer } from './types';
import Accordion from '../../../shared/Accordion/App';
import { dateFormatWithDayOfWeek } from '../../../../ts/formatTools';
import { convertQueryToFormData } from '@/ts/makePathForSearchForm';
import Form from './Form';

const REQUEST_OPTION_ALBUM = 14;

const Targets: React.FC<{ targetPhotographers: TTargetPhotographer[] }> =
  React.memo(({ targetPhotographers }) => (
    <table className="c-indexList u-fz_s">
      <thead>
        <tr>
          <th>カメラマン</th>
          <th>イベント</th>
          <th>団体</th>
        </tr>
      </thead>
      <tbody>
        {targetPhotographers.map((t) =>
          t.eventRequests.map((e, index) => (
            <tr key={e.eventRequestPhotographerId}>
              {index === 0 && (
                <td
                  className="c-indexList_column_m"
                  rowSpan={t.eventRequests.length}
                >
                  <span className="c-label c-label__monotone u-fz_xs">
                    ID: {t.photographerId}
                  </span>
                  {t.shortName}
                </td>
              )}
              <td className="c-indexList_column_l">
                <span className="c-label c-label__monotone u-fz_xs">
                  ID: {e.eventId}
                </span>
                {dateFormatWithDayOfWeek(e.photographingDay)}{' '}
                {e.photographingTimeFrom}-{e.photographingTimeTo} {e.eventName}
                <br />
                車：{e.isRequiredCarName} / 宿泊：{e.isRequiredStayNightName} /
                集合場所：{e.meetingPlaceName ?? 'なし'} / アルバム撮影：{' '}
                {e.options.some((o) => o.id === REQUEST_OPTION_ALBUM)
                  ? '有'
                  : '無'}
                <br />
                モノブロ：{e.monoblockStrobeName ?? 'なし'} / バック紙：
                {e.backgroundPaperName
                  ? `${e.backgroundPaperName}${
                      e.backgroundPaperWhoseName
                        ? `(${e.backgroundPaperWhoseName})`
                        : ''
                    }`
                  : 'なし'}{' '}
                / スタンド：{e.backgroundPaperStandName ?? 'なし'}
                <br />
                ひな壇：
                {e.standName
                  ? `${e.standName} - ${e.standTypeName}${
                      e.standTypeMemo ? `(${e.standTypeMemo})` : ''
                    } - ${e.standCountName}${
                      e.standCountMemo ? `(${e.standCountMemo})` : ''
                    }`
                  : 'なし'}
              </td>
              <td className="c-indexList_column_l">
                <span className="c-label c-label__monotone u-fz_xs">
                  ID: {e.societyId}
                </span>
                {e.societyName}
                <br />
                {e.societyAddress}
              </td>
            </tr>
          ))
        )}
      </tbody>
    </table>
  ));

const App: React.FC = () => {
  const queryString = useLocation().search;
  const queryParams = convertQueryToFormData(queryString);

  const { data, error } = useJsonApiForSearchForm<TSendOfferFormResponse>(
    '/api/arrange_photographer/send_offer',
    queryString
  );

  if (error) {
    return renderError(error);
  }
  if (!data) {
    return <Loading />;
  }

  const {
    data: { targetPhotographers, mailFormats },
    formItems,
    rules,
  } = data;

  return (
    <div className="c-summary p-sendOfferIndex">
      <h1 className="l-flex_center u-mgb_m c-page_title">依頼一括送信</h1>
      <div className="l-center_wrap">
        <div className="c-frame">
          {targetPhotographers.length}人のカメラマンに依頼を送信します。
          <Accordion labelOpen="詳細を表示" labelClose="詳細を非表示">
            <Targets targetPhotographers={targetPhotographers} />
          </Accordion>
        </div>
        <div className="c-frame">
          <Form
            rules={rules}
            mailFormats={mailFormats}
            formItems={formItems}
            queryParams={queryParams}
            targetPhotographers={targetPhotographers}
          />
        </div>
      </div>
    </div>
  );
};

export default App;
