import React, { useEffect, useState } from 'react';
import {
  renderError,
  useJsonApi,
  toMessages,
  fetcher,
} from '../../../../ts/useApi';
import { TCreateResponse, TFormInputs, TSaveResponse } from './types';
import SaveForm from './SaveForm';
import Loading from '../../../shared/Loading/App';
import { SubmitHandler } from 'react-hook-form';
import {
  TOnlyValidationRuleResponse,
  TValidatorResponse,
} from '@/components/shared/Form/types';
import { removeEmpty } from '@/ts/objectTools';
import { useHistory } from 'react-router-dom';
import { alertApiError } from '../../../../ts/formValidation';

const emptyValidator = {
  messages: {},
  hasError: false,
  rules: {},
};

const App: React.FC = () => {
  const { data, error } = useJsonApi<TCreateResponse>(
    '/api/administrator/create'
  );

  const [validator, setValidator] = useState<
    TValidatorResponse | TOnlyValidationRuleResponse
  >(emptyValidator);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const history = useHistory();

  useEffect(() => {
    if (data) {
      setValidator(data.validator);
    }
  }, [data]);

  if (error) {
    return renderError(error);
  }
  if (!data) {
    return <Loading />;
  }

  const formItems = data.formItems;

  const onSubmit: SubmitHandler<TFormInputs> = async (formData) => {
    try {
      const saveResponse = (await fetcher('/api/administrator', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(removeEmpty(formData)),
      })) as TSaveResponse;
      setErrorMessages([]);
      if (saveResponse.validator.hasError) {
        setValidator(saveResponse.validator);
        alertApiError();
        return;
      }
      history.push(`/administrator`);
    } catch (e) {
      setErrorMessages(toMessages(e));
    }
  };

  const defaultValues = {
    isLegalEntity: 1,
    isSalableTsuide: 1,
    isEnabledAutoEventPeriodSetting: 0,
    assigningExternalPhotographerTraineeType: 0,
    assigningInternalPhotographerTraineeType: 0,
    photographerId: 0,
    isRequiredAcceptanceByLeader: 0,
  };

  return (
    <div className="App">
      <h3 className="l-flex_center u-mgb_m">
        <span className="c-page_title">千社員管理 - 新規登録</span>
      </h3>
      <div className="l-center_wrap">
        <SaveForm
          validator={validator}
          formItems={formItems}
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          errorMessages={errorMessages}
          submitButton="登録する"
          cancelLink="/administrator"
        />
      </div>
    </div>
  );
};

export default App;
