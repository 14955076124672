import React, { useRef, useState, ChangeEventHandler } from 'react';
import dayjs from 'dayjs';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';

import { ApiErrors as ApiValidationErrors } from '@/components/shared/Form/Errors';
import { TextInput, TextArea } from '@/components/shared/Form/Inputs';
import {
  TOnlyValidationRuleResponse,
  TValidatorResponse,
} from '@/components/shared/Form/types';

import { errorToast, successToast } from '@/ts/toast';
import { fetcher, toMessages } from '@/ts/useApi';
import Download from '../Download';
import { TContainer, TFormInputs, TMail } from '../types';
import '../flyer.scss';

const SendApiError: React.FC<{ messages: string[] }> = React.memo(
  ({ messages }) => {
    return (
      <>
        {messages.length > 0 && (
          <div className="c-error_block">
            {messages.map((message, index) => (
              <ul className="c-error_list" key={index}>
                <li className="c-error_listItem">{message}</li>
              </ul>
            ))}
          </div>
        )}
      </>
    );
  }
);

type TSendFormProps = {
  eventno: string;
  validator: TValidatorResponse | TOnlyValidationRuleResponse;
  data: TContainer;
};

const SendForm: React.FC<TSendFormProps> = React.memo(
  ({ eventno, validator, data }) => {
    const history = useHistory();
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const [isSalesMail, setIsSalesMail] = useState(!!data.salesMail);
    const [photographerMails, setPhotographerMails] = useState(
      data.photographerMails
    );
    const onChangeCheck: ChangeEventHandler<HTMLInputElement> = (ev) => {
      const newMails: TMail = {};
      for (const mail of Object.keys(photographerMails)) {
        newMails[mail] = photographerMails[mail];
        if (ev.target.dataset.mail === mail) {
          newMails[mail] = ev.target.checked;
        }
      }
      setPhotographerMails(newMails);
    };
    const onSubmit: SubmitHandler<TFormInputs> = async (formData) => {
      if (!isSalesMail) {
        formData.salesMail = null;
      }
      formData.photographerMails = photographerMails;
      if (window.confirm('チラシを送付します。よろしいですか？')) {
        try {
          await fetcher(`/api/events/${eventno}/flyer/mail/send`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          });
          successToast('送信しました');
          history.push(`/events/${eventno}/eventflyer`);
        } catch (e) {
          setErrorMessages(toMessages(e));
          errorToast('エラーが発生しました');
        }
      }
    };
    const dateFormat = (date: string, format: string) =>
      dayjs(date).format(format);
    const PeriodSales = data.isEnabledOutOfPeriodSales
      ? `◆バリュー期間：${dateFormat(
          data.valuestartday,
          'M月D日（ddd）'
        )}〜${dateFormat(data.valueendday, 'M月D日（ddd）')}`
      : `◆販売期間：${dateFormat(
          data.valuestartday,
          'M月D日（ddd）'
        )}〜${dateFormat(data.publishendday, 'M月D日（ddd）')}`;

    const PeriodSalesMessage = data.isEnabledOutOfPeriodSales
      ? [
          `◆バリュー期間：${dateFormat(
            data.valuestartday,
            'M月D日（ddd）'
          )}〜${dateFormat(data.valueendday, 'M月D日（ddd）')}`,
          `※バリュー期間終了後は価格が25％アップします。ぜひお早めにご注文ください。`,
          `※バリュー期間終了後も、${dateFormat(
            data.publishendday,
            'YYYY年M月D日'
          )}まで、閲覧・注文が可能です。`,
        ].join('\n')
      : `◆販売期間：${dateFormat(
          data.valuestartday,
          'M月D日（ddd）'
        )}〜${dateFormat(data.publishendday, 'M月D日（ddd）')}`;

    const mailContent = [
      data.societyName,
      `ご担当者様`,
      ``,
      `いつもお世話になっております。`,
      `サポートグループでございます。`,
      ``,
      `この度、${dateFormat(
        data.valuestartday,
        'M月D日'
      )}から公開予定のイベントの件でご連絡いたしました。`,
      `以下該当のイベントでございますので、ご確認よろしくお願いいたします。`,
      ``,
      `■イベント名`,
      data.events.map((ev) => `「${ev.eventname}」`).join('\n'),
      ``,
      PeriodSales,
      ``,
      `合わせてチラシデータをお送りします。`,
      `写真公開の重要なお知らせとなりますので、写真公開日までにチラシを保護者様に必ずお渡しください。`,
      ``,
      `なお下記の時期に関しては、長期休暇に入る前や、園児様・生徒様が卒園・卒業を迎える前の在籍中に`,
      `先生方がチラシを配布できるよう通常よりも早くお届けしております。`,
      ``,
      `　　■夏期休暇前・・・6月下旬から7月上旬までにお届け`,
      `　　■冬期休暇前・・・11月下旬～12月中旬までにお届け`,
      `　　■卒園・卒業シーズン前・・・2月中旬～3月中旬までにお届け`,
      `　 ※「夏期・冬期休暇」がない団体様は通常通りのお届けとなります。`,
      ``,
      `また、必要であれば保護者様へ案内時に下記ご案内文ご活用ください。`,
      `＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝`,
      `${data.societyName}からのお知らせ`,
      ``,
      `《${dateFormat(
        data.valuestartday,
        'M/D（ddd）'
      )}から販売写真の公開が始まります。》`,
      data.events
        .map(
          (ev) =>
            `イベント：${ev.eventname}（${dateFormat(
              ev.photographingday,
              'M/D'
            )}撮影）`
        )
        .join('\n'),
      ``,
      `◆閲覧はコチラ：https://8122.jp/`,
      PeriodSalesMessage,
      `◆写真閲覧キー(旧 認証キー)：${data.certificationkey}`,
      ``,
      `====================================`,
      `写真の閲覧には、会員登録と写真閲覧キー(旧 認証キー)登録が必要です。`,
      `《会員登録、写真の閲覧は無料です。》`,
      ``,
      `▼会員登録がまだの方へ▼`,
      `https://8122.jp/ にアクセスし、「会員登録」よりご登録ください。`,
      ``,
      `▼写真閲覧キー(旧 認証キー)登録がまだの方へ▼`,
      `「はいチーズ！フォト」にログイン後、「写真閲覧キー(旧 認証キー)を登録」から写真閲覧キー(旧 認証キー)をご登録ください。`,
      `（写真閲覧キー(旧 認証キー)登録はコチラから：https://8122.jp/?certificationkey=${data.certificationkey}）`,
      `※チラシデータに記載の「写真閲覧キー(旧 認証キー)を登録」のリンクから簡単に写真閲覧キー(旧 認証キー)登録ができます。`,
      `※登録済みの写真閲覧キー(旧 認証キー)を再度入力すると、「入力した写真閲覧キー(旧 認証キー)は既に登録済みです。」と表示されます。`,
      ``,
      `写真閲覧キー(旧 認証キー)を登録されますと、今後は「はいチーズ！フォト」から写真公開に関するメールをお届けいたします。`,
      ``,
      `□■------------------------------------------------------------`,
      `　操作にお困りの際は「よくある質問」をご覧ください。`,
      `https://8122.jp/?action_static_FAQ=true`,
      `-------------------------------------------------------------■□`,
      ``,
      `====================================`,
      ``,
      `写真の公開まで楽しみにお待ちください★`,
      ``,
      `＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝`,
      ``,
      `以上です。`,
      `ご確認の程よろしくお願いいたします。`,
    ].join('\n');

    const eventFlyerName = `${data.flyerFileName}.pdf`;
    const downloadLinkRef = useRef<HTMLAnchorElement>(null);
    const defaultValues = {
      fileName: eventFlyerName,
      subject: `【はいチーズ!フォト】★保護者様配布資料★　写真公開のご案内（${dayjs(
        data.valuestartday
      ).format('M/D')}公開）`,
      leaderMail: data.leaderMail,
      salesMail: data.salesMail,
      photographerMails: data.photographerMails,
      mailContent: mailContent,
    };
    const methods = useForm<TFormInputs>({ defaultValues });

    return (
      <FormProvider {...methods}>
        <SendApiError messages={errorMessages} />
        <ApiValidationErrors {...validator} />
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <ul className="l-flex_between c-label_line is-sp_input">
            <li className="c-dataLabel">
              <label>ファイル</label>
            </li>
            <li className="c-dataValue">
              <Download
                eventno={eventno}
                eventFlyerName={eventFlyerName}
                downloadLinkRef={downloadLinkRef}
              />
              <span>({eventFlyerName})</span>
              <input type="hidden" name="fileName" value={eventFlyerName} />
              <a href="/" className="is-hidden" ref={downloadLinkRef}>
                dummy
              </a>
            </li>
          </ul>
          <ul className="l-flex_between c-label_line is-sp_input">
            <li className="c-dataLabel">
              <label>宛先</label>
            </li>
            <li className="c-dataValue">
              <div className="l-flex nowrap u-mgb_xs">
                {data.leaderMail ? (
                  <span
                    className="t-bgBox_org u-mgr_s"
                    style={{ padding: '8px 10px' }}
                  >{`責任者 <${data.leaderMail}>`}</span>
                ) : (
                  <span
                    className="t-bgBox_gray u-mgr_s"
                    style={{ padding: '8px 10px' }}
                  >{`責任者 <none>`}</span>
                )}
              </div>
            </li>
          </ul>
          <ul className="l-flex_between c-label_line is-sp_input">
            <li className="c-dataLabel">
              <label>CC</label>
            </li>
            <li className="c-dataValue">
              <div className="nowrap u-mgb_xs">
                <label className="c-checkboxLabel">
                  <input
                    name={`salesMail`}
                    className={`c-checkbox`}
                    type="checkbox"
                    defaultChecked={!!data.salesMail}
                    disabled={!data.salesMail}
                    onChange={() => {
                      setIsSalesMail((c) => !c);
                    }}
                  />
                  <span className="c-label_checkbox small">
                    {`担当営業 <${data.salesMail ?? '設定なし'}>`}
                  </span>
                </label>
                {Object.keys(photographerMails).length <= 0 ? (
                  <div className="l-flex nowrap u-mgb_xs">
                    <label className="c-checkboxLabel">
                      <span className="c-label_checkbox small">
                        担当カメラマン &lt;設定なし&gt;
                      </span>
                    </label>
                  </div>
                ) : (
                  Object.keys(photographerMails).map((mail, idx) => (
                    <div key={idx} className="nowrap u-mgb_xs">
                      <label className="c-checkboxLabel">
                        <input
                          type="checkbox"
                          name={`photographerMails[]`}
                          className={`c-checkbox`}
                          defaultChecked={true}
                          onChange={onChangeCheck}
                          data-mail={mail}
                        />
                        <span className="c-label_checkbox small">
                          {`担当カメラマン <${mail}>`}
                        </span>
                      </label>
                    </div>
                  ))
                )}
              </div>
            </li>
          </ul>
          <ul className="l-flex_between c-label_line is-sp_input">
            <li className="c-dataLabel">
              <label>イベント</label>
            </li>
            <li className="c-dataValue">
              {data.events.map((ev, idx) => (
                <div key={idx} className="l-flex nowrap u-mgb_xs">
                  <Link to={`/events/${ev.id}`} target="_blank">
                    {ev.id}
                  </Link>
                  <i className="c-icon c-icon__xxsmall c-icon_blank u-mgl_xs u-mgr_xs" />
                  <span>{ev.eventname}</span>
                </div>
              ))}
            </li>
          </ul>
          <ul className="l-flex_between c-label_line is-sp_input">
            <li className="c-dataLabel">
              <label>件名</label>
            </li>
            <li className="c-dataValue">
              <TextInput
                name="subject"
                validator={validator}
                additionalClassName="p-flyer_titleInput"
              />
            </li>
          </ul>

          <ul className="l-flex_between c-label_line is-sp_input">
            <li className="c-dataLabel">本文</li>
            <li className="c-dataValue">
              <TextArea
                name="mailContent"
                validator={validator}
                rows={20}
                cols={10}
              />
            </li>
          </ul>
          <p className="u-align_center l-flex_center_line">
            <Link
              className="c-btn_large c-btn_cancel u-mgr_m c-input_submit e-events-linkLabel"
              to={`/events/${eventno}/eventflyer`}
            >
              キャンセル
            </Link>
            {data.leaderMail ? (
              <input
                className="c-btn_large c-btn_edit is-arrow c-input_submit"
                type="submit"
                value={'送信する'}
              />
            ) : (
              <span className="u-fz_xs u-mgl_m c-alert_text">
                責任者が設定されていません
              </span>
            )}
            {data.dateSentMail && (
              <span className="u-fz_xs u-mgl_m">
                送信日時：
                {dayjs(data.dateSentMail).format('YYYY-MM-DD HH:mm')}
              </span>
            )}
          </p>
          <a href="/" className="is-hidden" ref={downloadLinkRef}>
            dummy
          </a>
        </form>
      </FormProvider>
    );
  }
);

export default SendForm;
