import React, { useEffect, useState } from 'react';
import {
  renderError,
  useJsonApi,
  toMessages,
  fetcher,
} from '../../../../ts/useApi';
import { TCreateResponse, TFormInputs, TSaveResponse } from './types';
import SaveForm from './SaveForm';
import Loading from '../../../shared/Loading/App';
import { SubmitHandler } from 'react-hook-form';
import {
  TOnlyValidationRuleResponse,
  TValidatorResponse,
} from '@/components/shared/Form/types';
import { removeEmpty } from '@/ts/objectTools';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { alertApiError } from '../../../../ts/formValidation';
import { errorToast, successToast } from '../../../../ts/toast';

const emptyValidator = {
  messages: {},
  hasError: false,
  rules: {},
};

const App: React.FC = () => {
  const { society_contract_id } = useParams<{ society_contract_id: string }>();
  const { data, error } = useJsonApi<TCreateResponse>(
    '/api/society_contracts/' + society_contract_id + '/owneraccounts/create'
  );

  const [validator, setValidator] = useState<
    TValidatorResponse | TOnlyValidationRuleResponse
  >(emptyValidator);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const history = useHistory();

  useEffect(() => {
    if (data) {
      setValidator(data.validator);
    }
  }, [data]);

  if (error) {
    return renderError(error);
  }
  if (!data) {
    return <Loading />;
  }

  const formItems = data.formItems;
  const society_id = formItems.society.id;

  const onSubmit: SubmitHandler<TFormInputs> = async (formData) => {
    try {
      const saveResponse = (await fetcher(
        '/api/society_contracts/' + society_contract_id + '/owneraccounts',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(removeEmpty(formData)),
        }
      )) as TSaveResponse;
      setErrorMessages([]);
      if (saveResponse.validator.hasError) {
        setValidator(saveResponse.validator);
        alertApiError();
        return;
      }
      successToast('作成しました');
      history.push(`/societies/${society_id}`);
    } catch (e) {
      setErrorMessages(toMessages(e));
      errorToast('エラーが発生しました');
    }
  };

  const defaultValues = {
    ownername: '管理者',
    mailaddress: data.data.societyContract.mailaddress,
  };

  return (
    <div className="App">
      <h3 className="l-flex_center u-mgb_m">
        <span className="c-page_title">管理者 - 新規登録</span>
      </h3>
      <div className="l-center_wrap">
        <SaveForm
          societyId={society_id}
          validator={validator}
          formItems={formItems}
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          errorMessages={errorMessages}
          submitButton="登録する"
          cancelLink={`/societies/${society_id}`}
        />
      </div>
    </div>
  );
};

export default App;
