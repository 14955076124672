import React from 'react';
import { useParams } from 'react-router-dom';
import { useJsonApi } from '@/ts/useJsonApi';
import { TOverviewResponse } from '@/components/pages/Events/Show/Overview/types';
import { renderError } from '@/ts/useApi';
import { Arrangement } from '@/components/pages/Events/Show/Overview/Arrangement';
import { AssignedPhotographers } from '@/components/pages/Events/Show/Overview/AssignedPhotographers';
import SvgLoading from '@/components/shared/Loading/SvgLoading';

export const Overview: React.FC = () => {
  const { eventId } = useParams<{ eventId: string }>();
  const { data: response, error } = useJsonApi<TOverviewResponse>(
    '/api/events/' + eventId + '/overview'
  );
  if (error) return renderError(error);
  if (!response) return <SvgLoading />;
  return (
    <>
      <div className="c-frame">
        <AssignedPhotographers response={response} />
      </div>
      <div className="c-frame">
        <Arrangement response={response} />
      </div>
    </>
  );
};
