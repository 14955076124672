import React, { useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SubmitHandler } from 'react-hook-form';
import QueryString from 'query-string';

import { renderError } from '../../../../ts/useApi';
import { useJsonApiForSearchForm } from '../../../../ts/useJsonApiForSearchForm';
import {
  makePathForSearchForm,
  convertQueryToFormData,
} from '@/ts/makePathForSearchForm';
import Loading from '../../../shared/Loading/App';
import Paginator from '@/components/shared/Paginator/App';

import SearchForm from '../../../shared/Certificationkeys/SearchForm';
import SearchResultItem from '../../../shared/Certificationkeys/SearchResultItem';
import CreateYearCertificationkeys from './CreateYearCertificationkeys';
import {
  TIndexResponse,
  TFormInputs,
  TCertificationkey,
} from '../../../shared/Certificationkeys/types';
import { TAB_DOUBLE } from '../../../shared/Certificationkeys/constants';
import './style.scss';

const HasNoResult: React.FC = () => (
  <div className="u-mgb_m">
    <hr className="u-line_plane" />
    <div className="c-emptyState_box">ご指定の条件では見つかりませんでした</div>
  </div>
);

const HasValidationError: React.FC = () => (
  <div className="u-mgb_m">
    <hr className="u-line_plane" />
    <div className="c-emptyState_box">検索条件が不正です。</div>
  </div>
);

const CertificationkeyList: React.FC<{
  certificationkeys: TCertificationkey[];
}> = (props) => (
  <table className="c-indexList">
    <thead>
      <tr>
        <th>キー種類</th>
        <th>写真閲覧キー</th>
        <th>パートナー名</th>
        <th>団体名</th>
        <th>契約</th>
        <th>キー束</th>
      </tr>
    </thead>
    <tbody>
      {props.certificationkeys.map((c) => (
        <tr
          key={c.id}
          className={c.isActive ? '' : 'c-certificationkey__notActive'}
        >
          <SearchResultItem key={c.id} certificationkey={c} />
        </tr>
      ))}
    </tbody>
  </table>
);

const App: React.FC = () => {
  const history = useHistory();
  const searchString = useLocation().search;
  const queryParams = convertQueryToFormData(searchString);
  if (!queryParams.type) {
    queryParams.type = TAB_DOUBLE;
  }
  const queryString =
    '?' + QueryString.stringify(queryParams, { arrayFormat: 'bracket' });

  const [selectedType, setSelectedType] = useState<number>(
    Number(queryParams.type)
  );

  const { data, error } = useJsonApiForSearchForm<TIndexResponse>(
    '/api/certificationkeys',
    queryString
  );

  const onSubmit: SubmitHandler<TFormInputs> = useCallback(
    async (formData) => {
      formData.type = selectedType;
      const newPath = makePathForSearchForm(
        '/certificationkeys',
        queryString,
        formData
      );

      history.push(newPath);
    },
    [history, queryString, selectedType]
  );

  if (error) {
    return renderError(error);
  }
  if (!data) {
    return <Loading />;
  }

  const {
    data: certificationkeys,
    validator,
    formItems,
    pagination,
    conditions: { isEnabledCreateYear },
  } = data;

  return (
    <div className="c-index p-certificationkeysIndex">
      {/* ページタイトル */}
      <h1 className="l-flex_center u-mgb_m c-page_title">写真閲覧キー一覧</h1>
      <div className="l-center_wrap">
        <SearchForm
          validator={validator}
          formItems={formItems}
          queryParams={queryParams}
          onSubmit={onSubmit}
          selectedType={selectedType}
          setSelectedType={setSelectedType}
        />

        <div className="c-frame">
          <div className="l-flex_between_center u-mgb_s">
            <p className="c-text_resultNumber">
              <span>{pagination?.total}</span>
              <small>件の結果</small>
            </p>
            <div className="l-flex_end">
              <CreateYearCertificationkeys canCreate={isEnabledCreateYear} />
              <div className="c-pagination_upper u-mgr_s">
                <Paginator
                  pagination={pagination}
                  currentPath="/certificationkeys"
                  queryString={queryString}
                />
              </div>
            </div>
          </div>
          {validator.hasError ? (
            <HasValidationError />
          ) : !certificationkeys.length ? (
            <HasNoResult />
          ) : (
            <CertificationkeyList certificationkeys={certificationkeys} />
          )}
          <Paginator
            pagination={pagination}
            currentPath="/certificationkeys/"
            queryString={queryString}
          />
        </div>
      </div>
    </div>
  );
};

export default App;
