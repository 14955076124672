import React, { useCallback, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SubmitHandler } from 'react-hook-form';
import QueryString from 'query-string';
import Paginator from '@/components/shared/Paginator/App';
import {
  convertQueryToFormData,
  makePathForSearchForm,
} from '@/ts/makePathForSearchForm';

import { renderError } from '../../../../ts/useApi';
import { transformBoolToNumber } from '../../../../ts/transformBoolToNumber';
import Loading from '../../../shared/Loading/App';
import { useJsonApiForSearchForm } from '../../../../ts/useJsonApiForSearchForm';
import { TFormInputs, TPhotographerSchedule, TIndexResponse } from './types';
import SearchForm from './SearchForm';
import { dateFormatHyphen } from '../../../../ts/formatTools';
import SearchResultItem, { ListHeader } from './SearchResultItem';

const HasNoResult: React.FC = () => (
  <div className="u-mgb_m">
    <hr className="u-line_plane" />
    <div className="c-emptyState_box">ご指定の条件では見つかりませんでした</div>
  </div>
);

const HasValidationError: React.FC = () => (
  <div className="u-mgb_m">
    <hr className="u-line_plane" />
    <div className="c-emptyState_box">検索条件が不正です。</div>
  </div>
);

const SearchResultList: React.FC<{
  photographers: TPhotographerSchedule[];
  displayAssignDetail: boolean;
  displayPhotographerDetail: boolean;
}> = React.memo(
  ({ photographers, displayAssignDetail, displayPhotographerDetail }) => (
    <table className="c-indexList">
      <thead>
        <ListHeader
          displayAssignDetail={displayAssignDetail}
          displayPhotographerDetail={displayPhotographerDetail}
        />
      </thead>
      <tbody>
        {photographers.map((photographer) => (
          <SearchResultItem
            photographer={photographer}
            key={photographer.photographerId}
            displayAssignDetail={displayAssignDetail}
            displayPhotographerDetail={displayPhotographerDetail}
          />
        ))}
      </tbody>
    </table>
  )
);

const addDefaultParam = (queryString: string): string => {
  const parsed = QueryString.parse(queryString);
  if (Object.keys(parsed).length === 0) {
    const today = dateFormatHyphen(Date.now());
    return QueryString.stringify({
      fromDate: today,
      toDate: today,
      // TODO 後で古い約款は削除する
      // NOTE: 新しい契約種類ができたら実装も増やす必要あり
      // NOTE: {key: 8, value: "交通費別(2404版)"}, {key: 9, value: "交通費込(2404版)"}
      'agreementtypes[]': [8, 9],
    });
  }
  return queryString;
};

const ToggleDetailButton: React.FC<{
  setShowDetail: React.Dispatch<React.SetStateAction<boolean>>;
  showDetail: boolean;
  infoName: string;
  queryString: string;
  keyShowDetail: string;
}> = React.memo(
  ({ setShowDetail, showDetail, infoName, queryString, keyShowDetail }) => {
    const { replace } = useHistory();
    const { pathname } = useLocation();
    const queryParams = QueryString.parse(queryString);
    const onClick = useCallback(
      () =>
        setShowDetail((current) => {
          const value = !current;
          queryParams[keyShowDetail] = Number(value).toString();
          replace(
            QueryString.stringifyUrl({ url: pathname, query: queryParams })
          );

          return value;
        }),
      [setShowDetail, queryParams, keyShowDetail, pathname, replace]
    );

    return (
      <button
        type="button"
        className="c-btn_small c-btn_rectangle c-btn_drawer u-mgl_s"
        onClick={onClick}
      >
        {infoName}を{showDetail ? '減らす' : '増やす'}
      </button>
    );
  }
);

const App: React.FC = () => {
  const history = useHistory();
  const queryString = addDefaultParam(useLocation().search);

  const [displayPhotographerDetail, setDisplayPhotographerDetail] =
    useState(true);
  const [displayAssignDetail, setDisplayAssignDetail] = useState(true);
  useEffect(() => {
    const queryParams = QueryString.parse(queryString);
    if (queryParams.displayPhotographerDetail !== undefined) {
      setDisplayPhotographerDetail(
        !!Number(queryParams.displayPhotographerDetail)
      );
    }
    if (queryParams.displayAssignDetail !== undefined) {
      setDisplayAssignDetail(!!Number(queryParams.displayAssignDetail));
    }
  }, [queryString]);

  const { data, error } = useJsonApiForSearchForm<TIndexResponse>(
    '/api/photographer_schedule/',
    queryString
  );

  const onSubmit: SubmitHandler<TFormInputs> = useCallback(
    async (formData) => {
      const newPath = makePathForSearchForm(
        '/photographer_schedule/',
        queryString,
        transformBoolToNumber(formData)
      );

      history.push(newPath);
    },
    [history, queryString]
  );
  if (error) {
    return renderError(error);
  }
  if (!data) {
    return <Loading />;
  }

  const { data: photographers, pagination, formItems, validator } = data;

  return (
    <div className="c-index">
      <h1 className="l-flex_center u-mgb_m c-page_title">
        カメラマンアサイン状況一覧
      </h1>
      <div className="l-center_wrap">
        <SearchForm
          validator={validator}
          formItems={formItems}
          queryParams={convertQueryToFormData(queryString, validator)}
          onSubmit={onSubmit}
        />
        <div className="c-frame">
          <div className="l-flex_between_center u-mgb_s">
            {/* NOTE: レイアウト用のdivです */}
            <div>
              <span className="c-text_resultNumber u-mgl_s">
                <span>{pagination?.total}</span>
                <small>件の結果</small>
              </span>
              <ToggleDetailButton
                showDetail={displayAssignDetail}
                setShowDetail={setDisplayAssignDetail}
                infoName="アサイン情報"
                queryString={queryString}
                keyShowDetail="displayAssignDetail"
              />
              <ToggleDetailButton
                showDetail={displayPhotographerDetail}
                setShowDetail={setDisplayPhotographerDetail}
                infoName="カメラマン情報"
                queryString={queryString}
                keyShowDetail="displayPhotographerDetail"
              />
            </div>
            <div className="l-flex_end">
              <div className="c-pagination_upper u-mgr_s">
                <Paginator
                  pagination={pagination}
                  currentPath="/photographer_schedule"
                  queryString={queryString}
                />
              </div>
            </div>
          </div>
          {validator.hasError ? (
            <HasValidationError />
          ) : !photographers.length ? (
            <HasNoResult />
          ) : (
            <SearchResultList
              photographers={photographers}
              displayAssignDetail={displayAssignDetail}
              displayPhotographerDetail={displayPhotographerDetail}
            />
          )}
        </div>

        <Paginator
          pagination={pagination}
          currentPath="/photographer_schedule"
          queryString={queryString}
        />
      </div>
    </div>
  );
};
export default App;
