import {
  TOnlyValidationRuleResponse,
  TValidatorResponse,
} from '@/components/shared/Form/types';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  fetcher,
  renderError,
  toMessages,
  useJsonApi,
} from '../../../../ts/useApi';
import { TFormInputs, TSaveResponse } from '../Create/types';
import Loading from '../../../shared/Loading/App';
import SaveForm from '../Create/SaveForm';
import { SubmitHandler } from 'react-hook-form';
import { TEditResponse } from './types';
import { useHistory } from 'react-router-dom';
import { errorToast, successToast } from '../../../../ts/toast';
import { alertApiError } from '../../../../ts/formValidation';

const emptyValidator = {
  messages: {},
  hasError: false,
  rules: {},
};

const App: React.FC = () => {
  const { ownerno } = useParams<{ ownerno: string }>();
  const { data, error } = useJsonApi<TEditResponse>(
    `/api/administrator/${ownerno}/edit`
  );
  const [validator, setValidator] = useState<
    TValidatorResponse | TOnlyValidationRuleResponse
  >(emptyValidator);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const history = useHistory();

  useEffect(() => {
    if (data) {
      setValidator(data.validator);
    }
  }, [data]);

  if (error) {
    return renderError(error);
  }
  if (!data) {
    return <Loading />;
  }

  const onSubmit: SubmitHandler<TFormInputs> = async (formData) => {
    try {
      const saveResponse = (await fetcher(
        `/api/administrator/${ownerno}/update`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        }
      )) as TSaveResponse;
      setErrorMessages([]);
      if (saveResponse.validator.hasError) {
        setValidator(saveResponse.validator);
        alertApiError();
        return;
      }
      successToast('更新しました');
      history.push(`/administrator`);
    } catch (e) {
      setErrorMessages(toMessages(e));
      errorToast('エラーが発生しました');
    }
  };

  const { owneraccounts } = data.data;

  const defaultValues = {
    ownername: owneraccounts.ownername,
    telephone: owneraccounts.telephone,
    mailaddress: owneraccounts.mailaddress,
    passwd: owneraccounts.passwd,
    authority: owneraccounts.authority,
    photographer_id: owneraccounts.photographer_id,
    employeetype: owneraccounts.employeetype,
  };

  const formItems = { ...data.formItems };

  return (
    <div className="App">
      <h3 className="l-flex_center u-mgb_m">
        <span className="c-page_title">管理者 - 編集</span>
      </h3>
      <div className="l-center_wrap">
        <SaveForm
          ownerno={ownerno}
          validator={validator}
          formItems={formItems}
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          errorMessages={errorMessages}
          submitButton="更新する"
          cancelLink="/administrator"
        />
      </div>
    </div>
  );
};

export default App;
