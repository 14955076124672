import { useParams, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import {
  TOnlyValidationRuleResponse,
  TValidatorResponse,
} from '@/components/shared/Form/types';
import { removeEmpty } from '@/ts/objectTools';

import Loading from '../../../shared/Loading/App';
import {
  fetcher,
  renderError,
  toMessages,
  useJsonApi,
} from '../../../../ts/useApi';
import Header from '../Header';
import { TFormInputs, TSaveResponse } from './types';
import './bankAccountCreate.scss';
import SaveForm from './SaveForm';
import { TEditResponse } from './types';
import { errorToast, successToast } from '../../../../ts/toast';
import { alertApiError } from '../../../../ts/formValidation';

const emptyValidator = {
  messages: {},
  hasError: false,
  rules: {},
};

const App: React.FC = () => {
  const { contractId } = useParams<{ contractId: string }>();
  const { data, error } = useJsonApi<TEditResponse>(
    '/api/society_contracts/' + contractId + '/bank_accounts/edit'
  );

  const [validator, setValidator] = useState<
    TValidatorResponse | TOnlyValidationRuleResponse
  >(emptyValidator);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const history = useHistory();

  useEffect(() => {
    if (data) {
      setValidator(data.validator);
    }
  }, [data]);

  if (error) {
    return renderError(error);
  }
  if (!data) {
    return <Loading />;
  }

  const {
    data: { bankAccount, society, societyContract },
    formItems,
  } = data;

  const onSubmit: SubmitHandler<TFormInputs> = async (formData) => {
    try {
      // FIXME: URLを修正 /bank_accounts/{id}
      const saveResponse = (await fetcher(
        `/api/society_contracts/${bankAccount.id}/bank_accounts`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(removeEmpty(formData)),
        }
      )) as TSaveResponse;
      setErrorMessages([]);
      if (saveResponse.validator.hasError) {
        setValidator(saveResponse.validator);
        alertApiError();
        return;
      }

      successToast('更新しました');
      history.push(`/society_contracts/${contractId}/bank_accounts`);
    } catch (e) {
      setErrorMessages(toMessages(e));
      errorToast('エラーが発生しました');
    }
  };
  return (
    <>
      <h3 className="l-flex_center u-mgb_m">
        <span className="c-page_title">口座情報 - 編集</span>
      </h3>
      <div className="l-center_wrap">
        <Header society={society} societyContract={societyContract} />
        <SaveForm
          validator={validator}
          bankAccount={bankAccount}
          types={formItems}
          onSubmit={onSubmit}
          errorMessages={errorMessages}
          submitButton="更新する"
          cancelLink={`/society_contracts/${contractId}/bank_accounts`}
        />
      </div>
    </>
  );
};

export default App;
