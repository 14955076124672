import React, { useEffect, useState } from 'react';
import { TValidatorResponse } from '@/components/shared/Form/types';
import { ProfileHeader } from '../ProfileHeader';
import { renderError, toMessages, useJsonApi } from '../../../../ts/useApi';
import { TCreateResponse, TSaveResponse } from './types';
import { alertApiError } from '../../../../ts/formValidation';
import { errorToast, successToast } from '../../../../ts/toast';
import { useHistory } from 'react-router-dom';
import { usePreventDuplicateCall } from '@/ts/usePreventDuplicateCall';
import Loading from '../../../shared/Loading/App';
import { Form } from '../Form';
import './style.scss';
import { postJson } from '@/ts/fetch';
import { BreadCrumb } from '../../../shared/BreadCrumb';

const emptyValidator = {
  messages: {},
  hasError: false,
  rules: {},
};

const App: React.FC = () => {
  const { data, error } = useJsonApi<TCreateResponse>(
    '/api/photographers/create'
  );
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [profileImage, setProfileImage] = React.useState<string | null>(null);
  const [validator, setValidator] =
    useState<TValidatorResponse>(emptyValidator);
  const history = useHistory();

  const onSubmit = usePreventDuplicateCall(async (formData) => {
    try {
      formData.profileImage = profileImage;
      Object.keys(formData).forEach((key) => {
        if (formData[key] === undefined) {
          formData[key] = null;
        }
      });

      const postCode = formData['postCode'];
      if (postCode.match(/\d{7}/)) {
        formData['postCode'] =
          postCode.slice(0, 3) + '-' + postCode.slice(3, 7);
      }

      const saveResponse = (await postJson(
        '/api/photographers/',
        formData
      )) as TSaveResponse;

      setErrorMessages([]);
      if (saveResponse.validator.hasError) {
        setValidator(saveResponse.validator);
        alertApiError();
        return;
      }
      successToast('登録しました');
      history.push(`/photographers/${saveResponse.data.photographer.id}`);
    } catch (e) {
      setErrorMessages(toMessages(e));
      errorToast('エラーが発生しました');
    }
  });

  useEffect(() => {
    if (data) {
      setValidator(data.validator);
    }
  }, [data]);

  if (error) {
    return renderError(error);
  }
  if (!data) {
    return <Loading />;
  }

  const defaultValues = {
    authority: '1',
    gender: undefined,
    birthdate: '1999-01-01',
    prefecturesMasterNo: '1',
    expenseCalculationType: '0',
    canWorkingDateType: '1',
    agreementType: null,
    newRankId: 0,
    oldRankId: 0,
  };

  return (
    <div className="App">
      <BreadCrumb
        pagesInfo={[
          { id: 1, title: 'はいチーズ!フォト管理画面', path: '/' },
          { id: 2, title: 'カメラマン一覧', path: '/photographers' },
          { id: 3, title: 'カメラマン - 新規登録', path: undefined },
        ]}
      />
      <ProfileHeader pageTitle="カメラマン - 新規登録" />
      <div className="l-center_wrap">
        <Form
          formItems={data.formItems}
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          validator={validator}
          errorMessages={errorMessages}
          setProfileImage={setProfileImage}
          submitButton="登録する"
          cancelLink="/photographers"
        />
      </div>
    </div>
  );
};

export default App;
