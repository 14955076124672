import dayjs from 'dayjs';
import React from 'react';
import { IndexResponse, TFormInputs } from './types';
import SearchForm from './SearchForm';
import WithholdingFrom from './WithholdingForm';
import { useJsonApi, renderError } from '@/ts/useApi';
import Loading from '@/components/shared/Loading/App';

export const IndexPage: React.FC = () => {
  const defaultValues: TFormInputs = {
    csvFormatType: 1,
    occurrenceMonth: dayjs().format('YYYY-MM'),
  };
  const { data: response, error } = useJsonApi<IndexResponse>(
    `/api/photographer_payments/zengin/index`
  );

  if (error) {
    return renderError(error);
  }

  if (!response) {
    return <Loading />;
  }

  return (
    <>
      <h3 className="l-flex_center u-mgb_m">
        <span className="c-page_title">全銀データ</span>
      </h3>
      <SearchForm defaultValues={defaultValues} response={response} />
      <WithholdingFrom defaultValues={defaultValues} />
    </>
  );
};
