import React, { MouseEventHandler, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

import { dateFormat } from '../../../../ts/formatTools';
import {
  fetcher,
  renderError,
  toMessages,
  useJsonApi,
} from '../../../../ts/useApi';
import Loading from '../../../shared/Loading/App';
import StatusLabel from '../../../shared/StatusLabel/App';
import { STATUS_ACTIVE } from '../constants';
import { TDeleteResponse, TShowResponse } from './types';
import SubMenu from './SubMenu';
import Partner from './Partner';
import Owners from './Owners';
import LatestEvents from './LatestEvents';
import LatestGroups from './LatestGroups';
import './style.scss';
import TextCopy from '../../../shared/TextCopy/App';
import { useAuthInfo } from '../../../AuthProvider';
import { ownerUrl } from '../../../../ts/url';
import {
  TOnlyValidationRuleResponse,
  TValidatorResponse,
} from '@/components/shared/Form/types';
import { errorToast, successToast } from '../../../../ts/toast';
import ErrorMessages from '@/components/shared/ErrorMessages';
import { ApiErrors } from '@/components/shared/Form/Errors';
import { alertApiError } from '../../../../ts/formValidation';

const App: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data, error } = useJsonApi<TShowResponse>('/api/partners/' + id);
  const authInfo = useAuthInfo();

  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const history = useHistory();

  const emptyValidator = {
    messages: {},
    hasError: false,
    rules: {},
  };
  const [validator, setValidator] = useState<
    TValidatorResponse | TOnlyValidationRuleResponse
  >(emptyValidator);

  const deletePartner = async (partnerId: number) => {
    if (window.confirm('パートナーを削除します。よろしいですか？')) {
      try {
        const response = (await fetcher('/api/partners/' + partnerId, {
          method: 'DELETE',
        })) as TDeleteResponse;
        if (response.validator.hasError) {
          setValidator(response.validator);
          alertApiError('エラーが発生しました');
          return;
        }
        setErrorMessages([]);
        successToast('削除しました');
        history.push(`/partners`);
      } catch (error) {
        errorToast('エラーが発生しました');
        setErrorMessages(toMessages(error));
      }
    }
  };

  const [isOpenSubMenu, setIsOpenSubMenu] = useState(false);
  if (error) {
    return renderError(error);
  }
  if (!data) {
    return <Loading />;
  }

  const { partner, owners, latestEvents, latestGroups } = data.data;
  const { areas } = data.displayItems;
  const subMenuProps = {
    isSen: partner.conditions.isSen,
    isAsp: !!partner.isAsp,
    isTaiwan: partner.conditions.isTaiwan,
    salesDepartmentOwnerno: partner.salesDepartmentOwnerno,
  };

  const toggleSubMenu: MouseEventHandler = (e) => {
    e.preventDefault();
    setIsOpenSubMenu(!isOpenSubMenu);
  };

  return (
    <div className="p-partnerSummary c-summary">
      <Link className="c-page_back" to="/partners">
        一覧に戻る
      </Link>
      <h1 className="l-flex_center u-mgb_m c-page_title">パートナーサマリ</h1>
      <div className="l-center_wrap">
        <div className="c-frame">
          <ErrorMessages messages={errorMessages} />
          <ApiErrors {...validator} />
          <div>
            <div className="l-flex_between_center">
              <div>
                <span className="c-label c-label__monotone u-fz_xs">
                  パートナーID：{partner.id}
                </span>
                <StatusLabel
                  isPositive={partner.status === STATUS_ACTIVE}
                  statusName={partner.statusName}
                  additionalClassName="u-fz_xs"
                />
              </div>
              <div>
                <span className="c-label c-label__monotone u-fz_xs u-mgr_xs">
                  登録日
                </span>
                <span className="u-fz_xs u-mgr_s">
                  {dateFormat(partner.createdday)}
                </span>
                <span className="c-label c-label__monotone u-fz_xs u-mgr_xs">
                  更新日
                </span>
                <span className="u-fz_xs">
                  {dateFormat(partner.updatedday)}（{partner.updatedUserName}）
                </span>
              </div>
            </div>
            <hr className="u-line_plane" />
            <div className="l-flex_between_center u-mgb_s">
              <div className="p-partnerSummary_name">
                <div className="l-flex_align_base">
                  <h4 className="c-section_title u-fz_l">{partner.name}</h4>
                  <TextCopy text={partner.name + ' ' + window.location.href} />
                </div>
                <span className="u-fz_xs">{partner.namekana}</span>
              </div>
              <div className="l-flex_end">
                {partner.conditions.isDeletable ? (
                  <button
                    className="c-btn_rectangle c-btn_delete u-mgr_s"
                    onClick={() => deletePartner(partner.id)}
                  >
                    <i className="c-icon c-icon__xsmall c-icon_trash"></i>
                  </button>
                ) : (
                  <a
                    className="c-btn_rectangle c-btn_delete u-mgr_s is-disabled"
                    href="/"
                  >
                    <i className="c-icon c-icon__xsmall c-icon_trash"></i>
                  </a>
                )}
                <a
                  className="c-btn_rectangle c-btn_edit"
                  href={ownerUrl(`partners/${partner.id}/edit`)}
                >
                  <i className="c-icon c-icon__xsmall c-icon_edit"></i>
                </a>
                <div className="l-relative u-pcOff p-partnersSummary_margin">
                  <a
                    className="c-submenu_rectangle c-btn_drawer"
                    onClick={toggleSubMenu}
                    href="/"
                  >
                    <i className="c-icon c-icon__xsmall c-icon_leader"></i>
                  </a>
                  {/* <transition name="slide"> */}
                  <SubMenu
                    partnerID={partner.id}
                    isPopup={true}
                    onClickClose={toggleSubMenu}
                    isOpen={isOpenSubMenu}
                    {...subMenuProps}
                  />
                  {/* </transition> */}
                </div>
              </div>
            </div>
          </div>
          <div className="l-flex nowrap">
            <Partner partner={partner} />
            <SubMenu partnerID={partner.id} isPopup={false} {...subMenuProps} />
          </div>
        </div>
        <Owners
          authInfo={authInfo}
          owners={owners}
          areas={areas}
          isSen={partner.conditions.isSen}
          partnerId={partner.id}
        />
        {!partner.conditions.isSen && <LatestEvents events={latestEvents} />}
        {!partner.conditions.isSen && <LatestGroups groups={latestGroups} />}
        {/* TODO: delete modal */}
        {/* <delete-modal v-if="isDeleteModalVisible" :csrf="csrfToken" :agencyname="partner.agencyname" :destroy-url="partner.destroyUrl" @close="closeDeleteModal"></delete-modal> */}
        {/* TODO: snack bar ? */}
        {/* <base-snackbar v-if="saveResult!==null" :message="saveResult" :className="{'is-up': isSnackbarVisible}" @close="closeSnackbar"></base-snackbar> */}
      </div>
    </div>
  );
};

export default App;
