import { useJsonApi } from '@/ts/useJsonApi';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { TOrder, TOrderDeliveryInfo, TOrderPhoto } from '../Show/types';
import SvgLoading from '@/components/shared/Loading/SvgLoading';
import { renderError } from '@/ts/useApi';
import { kanriUrl } from '@/ts/url';

const App: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data, error } = useJsonApi<TOrder>('/api/order_info/' + id);

  if (error) {
    return renderError(error);
  }
  if (!data) {
    return <SvgLoading />;
  }

  const { orderInfo, orderDeliveryInfo } = data.data;

  const ODeliveryInfo = orderDeliveryInfo.orderDeliveryInfo;

  return (
    <div className="App">
      <div className="c-page_back">
        <Link to="/order_infos">一覧に戻る</Link>
      </div>
      <h1>注文明細（注文ID：{orderInfo.orderinfono}）</h1>
      <div className="l-content">
        <div className="l-center_wrap">
          <div className="c-frame">
            <div className="u-mgt_s u-mgb_m">
              <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">会員ID</li>
                    <li className="c-dataValue">{orderInfo.useraccountno}</li>
                  </ul>
                </li>
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">名前</li>
                    <li className="c-dataValue">
                      {orderInfo.firstname} {orderInfo.secondname}様
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">注文日</li>
                    <li className="c-dataValue">
                      {orderInfo.orderInfoCreatedday}
                    </li>
                  </ul>
                </li>
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">入金日</li>
                    <li className="c-dataValue">{orderInfo.paymentday}</li>
                  </ul>
                </li>
              </ul>
              <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">決済方法</li>
                    <li className="c-dataValue">{orderInfo.paymentTypeName}</li>
                  </ul>
                </li>
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">ステータス</li>
                    <li className="c-dataValue">
                      {orderInfo.paymentStatusName}
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">写真</li>
                    <li className="c-dataValue">
                      合計　{orderInfo.orderPhotoSum}枚<br />
                      内訳
                      <br />
                      {Object.keys(orderInfo.orderPhotoSumSize).map(
                        (photoSum: string) => (
                          <span key={photoSum}>
                            {photoSum.toUpperCase()}{' '}
                            {orderInfo.orderPhotoSumSize[photoSum]}
                          </span>
                        )
                      )}
                    </li>
                  </ul>
                </li>
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">写真小計</li>
                    <li className="c-dataValue">{orderInfo.photosum}</li>
                  </ul>
                </li>
              </ul>
              <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">送料</li>
                    <li className="c-dataValue">{orderInfo.carriage}</li>
                  </ul>
                </li>
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">税金額</li>
                    <li className="c-dataValue">{orderInfo.taxamount}</li>
                  </ul>
                </li>
              </ul>
              <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">代引手数料</li>
                    <li className="c-dataValue">{orderInfo.collectcarriage}</li>
                  </ul>
                </li>
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">値引き</li>
                    <li className="c-dataValue">{orderInfo.discount}</li>
                  </ul>
                </li>
              </ul>
              <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">合計金額</li>
                    <li className="c-dataValue">{orderInfo.total}</li>
                  </ul>
                </li>
              </ul>
              <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">クーポン値引き額</li>
                    <li className="c-dataValue">{orderInfo.couponDiscount}</li>
                  </ul>
                </li>
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">フォトマネー使用</li>
                    <li className="c-dataValue">{orderInfo.usepoint}</li>
                  </ul>
                </li>
              </ul>
              <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">お支払金額</li>
                    <li className="c-dataValue">{orderInfo.totalpayment}</li>
                  </ul>
                </li>
                <li className="l-col_12">
                  <ul className="l-flex">
                    <li className="c-dataLabel">
                      還元フォトマネー(翌日に反映)
                    </li>
                    <li className="c-dataValue">{orderInfo.reductionpoint}</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {ODeliveryInfo.map((ODI: TOrderDeliveryInfo) => {
        return (
          ODI.printLogs.jvisprintlog?.jvisprintlogno && (
            <div className="l-content">
              <div className="l-center_wrap">
                <div className="c-frame">
                  <div className="u-mgt_s u-mgb_m">
                    <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                      <li className="l-col_12">
                        <ul className="l-flex">
                          <li className="c-dataLabel">ID (jvisprintlogno)</li>
                          <li className="c-dataValue">
                            {ODI.printLogs.jvisprintlog.jvisprintlogno}
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                      <li className="l-col_12">
                        <ul className="l-flex">
                          <li className="c-dataLabel">注文ID</li>
                          <li className="c-dataValue">
                            {orderInfo.orderinfono}
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                      <li className="l-col_12">
                        <ul className="l-flex">
                          <li className="c-dataLabel">注文配送先no</li>
                          <li className="c-dataValue">
                            {ODI.orderDeliveryInfo.orderdeliveryinfono}
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                      <li className="l-col_12">
                        <ul className="l-flex">
                          <li className="c-dataLabel">作成・送信日</li>
                          <li className="c-dataValue">
                            {ODI.printLogs.jvisprintlog.createdday}
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                      <li className="l-col_12">
                        <ul className="l-flex">
                          <li className="c-dataLabel">
                            配送伝票番号（日本郵便）
                            <br />
                            invoiceno
                          </li>
                          <li className="c-dataValue">
                            {ODI.jvisBulkDelivery?.invoiceno ??
                              ODI.printLogs.jvisprintlog.invoiceno}
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                      <li className="l-col_12">
                        <ul className="l-flex">
                          <li className="c-dataLabel">配送日</li>
                          <li className="c-dataValue">
                            {ODI.jvisBulkDelivery?.deliveredday ??
                              ODI.jvisBulkDelivery?.deliveredday}
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                      <li className="l-col_12">
                        <ul className="l-flex">
                          <li className="c-dataLabel">発送しましたメール</li>
                          <li className="c-dataValue">
                            {ODI.printLogs.jvisprintlog.mailsentflag === 0
                              ? '未送信'
                              : '送信済み'}
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                      <li className="l-col_12">
                        <ul className="l-flex">
                          <li className="c-dataLabel">一括配送ID</li>
                          <li className="c-dataValue">
                            {ODI.jvisBulkDelivery?.jvisbulkdeliveryno}
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                      <li className="l-col_12">
                        <ul className="l-flex">
                          <li className="c-dataLabel">一括配送締め日</li>
                          <li className="c-dataValue">
                            {ODI.jvisBulkDelivery?.bulkDeliveryCloseDay}
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                      <li className="l-col_12">
                        <ul className="l-flex">
                          <li className="c-dataLabel">
                            一括配送データ送信日時
                          </li>
                          <li className="c-dataValue">
                            {ODI.jvisBulkDelivery?.uploadday}
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )
        );
      })}
      {ODeliveryInfo.map((ODI: TOrderDeliveryInfo) => {
        return (
          ODI.printLogs.hokuryoprintlog?.id && (
            <div className="l-content">
              <div className="l-center_wrap">
                <div className="c-frame">
                  <div className="u-mgt_s u-mgb_m">
                    <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                      <li className="l-col_12">
                        <ul className="l-flex">
                          <li className="c-dataLabel">ID</li>
                          <li className="c-dataValue">
                            {ODI.printLogs.hokuryoprintlog.id}
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                      <li className="l-col_12">
                        <ul className="l-flex">
                          <li className="c-dataLabel">注文ID</li>
                          <li className="c-dataValue">
                            {orderInfo.orderinfono}
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                      <li className="l-col_12">
                        <ul className="l-flex">
                          <li className="c-dataLabel">注文タイプ</li>
                          <li className="c-dataValue">
                            {ODI.printLogs.hokuryoprintlog.ordertype}
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                      <li className="l-col_12">
                        <ul className="l-flex">
                          <li className="c-dataLabel">エラー内容</li>
                          <li className="c-dataValue">
                            {ODI.printLogs.hokuryoprintlog.error}
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                      <li className="l-col_12">
                        <ul className="l-flex">
                          <li className="c-dataLabel">
                            TSV作成日
                            <br />
                            createdday
                          </li>
                          <li className="c-dataValue">
                            {ODI.printLogs.hokuryoprintlog.createdday}
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                      <li className="l-col_12">
                        <ul className="l-flex">
                          <li className="c-dataLabel">
                            注文配送先no
                            <br />
                            orderdeliveryinfono
                          </li>
                          <li className="c-dataValue">
                            {ODI.orderDeliveryInfo.orderdeliveryinfono}
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                      <li className="l-col_12">
                        <ul className="l-flex">
                          <li className="c-dataLabel">
                            北菱フォトでの受取日
                            <br />
                            printedday
                          </li>
                          <li className="c-dataValue">
                            {ODI.printLogs.hokuryoprintlog.printedday}
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                      <li className="l-col_12">
                        <ul className="l-flex">
                          <li className="c-dataLabel">
                            配送伝票番号
                            <br />
                            invoiceno
                          </li>
                          <li className="c-dataValue">
                            {ODI.printLogs.hokuryoprintlog.invoiceno}
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                      <li className="l-col_12">
                        <ul className="l-flex">
                          <li className="c-dataLabel">配送番号発行日</li>
                          <li className="c-dataValue">
                            {ODI.printLogs.hokuryoprintlog.invoicedday}
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                      <li className="l-col_12">
                        <ul className="l-flex">
                          <li className="c-dataLabel">発送しましたメール</li>
                          <li className="c-dataValue">
                            {ODI.printLogs.hokuryoprintlog.mailflag === 0
                              ? '未送信'
                              : '送信済み'}
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )
        );
      })}
      {ODeliveryInfo.map((ODI: TOrderDeliveryInfo) => {
        return (
          ODI.printLogs.machinetechprintlog?.machinetechprintlogno && (
            <div className="l-content">
              <div className="l-center_wrap">
                <div className="c-frame">
                  <div className="u-mgt_s u-mgb_m">
                    <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                      <li className="l-col_12">
                        <ul className="l-flex">
                          <li className="c-dataLabel">ID</li>
                          <li className="c-dataValue">
                            {
                              ODI.printLogs.machinetechprintlog
                                .machinetechprintlogno
                            }
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                      <li className="l-col_12">
                        <ul className="l-flex">
                          <li className="c-dataLabel">注文ID</li>
                          <li className="c-dataValue">
                            {orderInfo.orderinfono}
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                      <li className="l-col_12">
                        <ul className="l-flex">
                          <li className="c-dataLabel">注文タイプ</li>
                          <li className="c-dataValue">
                            {ODI.printLogs.machinetechprintlog.ordertype}
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                      <li className="l-col_12">
                        <ul className="l-flex">
                          <li className="c-dataLabel">エラー内容</li>
                          <li className="c-dataValue">
                            {ODI.printLogs.machinetechprintlog.error}
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                      <li className="l-col_12">
                        <ul className="l-flex">
                          <li className="c-dataLabel">
                            TSV作成日
                            <br />
                            createdday
                          </li>
                          <li className="c-dataValue">
                            {ODI.printLogs.machinetechprintlog.createdday}
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                      <li className="l-col_12">
                        <ul className="l-flex">
                          <li className="c-dataLabel">
                            注文配送先no
                            <br />
                            orderdeliveryinfono
                          </li>
                          <li className="c-dataValue">
                            {ODI.orderDeliveryInfo.orderdeliveryinfono}
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                      <li className="l-col_12">
                        <ul className="l-flex">
                          <li className="c-dataLabel">
                            日本マシンテックでの受取日
                            <br />
                            printedday
                          </li>
                          <li className="c-dataValue">
                            {ODI.printLogs.machinetechprintlog.printedday}
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                      <li className="l-col_12">
                        <ul className="l-flex">
                          <li className="c-dataLabel">
                            配送伝票番号
                            <br />
                            invoiceno
                          </li>
                          <li className="c-dataValue">
                            {ODI.printLogs.machinetechprintlog.invoiceno}
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                      <li className="l-col_12">
                        <ul className="l-flex">
                          <li className="c-dataLabel">配送番号発行日</li>
                          <li className="c-dataValue">
                            {ODI.printLogs.machinetechprintlog.invoicedday}
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                      <li className="l-col_12">
                        <ul className="l-flex">
                          <li className="c-dataLabel">発送しましたメール</li>
                          <li className="c-dataValue">
                            {ODI.printLogs.machinetechprintlog.mailflag === 0
                              ? '未送信'
                              : '送信済み'}
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )
        );
      })}
      {ODeliveryInfo.map((ODI: TOrderDeliveryInfo) => (
        <>
          <div className="l-content">
            <div className="l-center_wrap">
              <div className="c-frame">
                <div className="u-mgt_s u-mgb_m">
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_12">
                      <ul className="l-flex">
                        <li className="c-dataLabel">
                          {ODI.orderDeliveryInfo.groupsname
                            ? '[一括配送]'
                            : '[個別配送]'}
                          <br />
                          {'odino: ' +
                            ODI.orderDeliveryInfo.orderdeliveryinfono}
                          <br />
                          {'印刷会社: ' +
                            ODI.orderDeliveryInfo.printcompanyName}
                        </li>
                        <li className="c-dataValue">
                          {ODI.orderDeliveryInfo.groupsname && (
                            <>
                              {ODI.orderDeliveryInfo.groupsname + '様'}
                              <br />
                              [年度:クラス名]{ODI.orderDeliveryInfo.nendo}:
                              {ODI.orderDeliveryInfo.classname}
                              <br />
                            </>
                          )}
                          <br />
                          {ODI.orderDeliveryInfo.firstname}{' '}
                          {ODI.orderDeliveryInfo.secondname}　様 <br />〒
                          {ODI.orderDeliveryInfo.postcode}{' '}
                          {ODI.orderDeliveryInfo.prefecture}
                          {ODI.orderDeliveryInfo.city}{' '}
                          {ODI.orderDeliveryInfo.address}
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="l-content">
            <div className="l-center_wrap">
              <div className="c-frame">
                <div className="u-mgt_s u-mgb_m">
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_12">
                      <ul className="l-flex">
                        <li className="c-dataLabel">写真枚数</li>
                        <li className="c-dataValue">
                          合計{ODI.photoSum.sum}枚<br />
                          内訳
                          <br />
                          {Object.keys(ODI.photoSum.orderPhotoSumSize).map(
                            (photoSum) => (
                              <span key={photoSum}>
                                {photoSum.toUpperCase()}{' '}
                                {ODI.photoSum.orderPhotoSumSize[photoSum]}
                              </span>
                            )
                          )}
                          <br />
                          {Object.keys(
                            ODI.photoSum.tsuideOrderPhotoSumSize
                          ).map((photoSum) => (
                            <span key={photoSum}>
                              {photoSum.toUpperCase()}{' '}
                              {ODI.photoSum.tsuideOrderPhotoSumSize[photoSum]}
                            </span>
                          ))}
                          <br />
                          写真小計（内手数料）　{ODI.photoSum.photoAmount}
                          <br />
                          送料　{ODI.orderDeliveryInfo.carriage}
                          {ODI.orderDeliveryInfo.collectCarriage
                            ? '代引き手数料' +
                              ODI.orderDeliveryInfo.collectCarriage
                            : ''}
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {ODI.orderPhotos?.map((orderPhoto: TOrderPhoto, index) => {
            return (
              <div className="l-content">
                <div className="l-center_wrap">
                  <div className="c-frame">
                    <div className="u-mgt_s u-mgb_m">
                      <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                        <li className="l-col_12">
                          <ul className="l-flex">
                            <li className="c-dataLabel">No.{index + 1}</li>
                            <li className="c-dataValue">
                              orderphotono: {orderPhoto.orderphotono}
                              <br />
                              <img src={orderPhoto.photoUrl} alt="orderphoto" />
                              <br />
                              <a href={orderPhoto.previewUrl}>オリジナル</a>
                              <br />
                              トリミング: {orderPhoto.isEdit ? '有' : '無'}
                              <br />
                              団体:{' '}
                              {orderPhoto.isPartner ? (
                                <a
                                  className="c-textOmit c-textlink"
                                  href={kanriUrl({
                                    action_owner_GROUPsummary: 'true',
                                    groupsno: String(orderPhoto.societyId),
                                  })}
                                >
                                  {orderPhoto.societyName}
                                </a>
                              ) : (
                                <Link to={'/societies/' + orderPhoto.societyId}>
                                  {orderPhoto.societyName}
                                </Link>
                              )}
                              <br />
                              イベント:{' '}
                              {orderPhoto.isPartner ? (
                                <a
                                  className="c-textOmit c-textlink"
                                  href={kanriUrl({
                                    action_owner_EVENTsummary: 'true',
                                    eventno: orderPhoto.eventno.toString(),
                                  })}
                                >
                                  {orderPhoto.eventName}
                                </a>
                              ) : (
                                <Link to={'/events/' + orderPhoto.eventno}>
                                  {orderPhoto.eventName}
                                </Link>
                              )}
                              <br />
                              カテゴリ:{' '}
                              <Link
                                to={
                                  'events/' +
                                  orderPhoto.eventno +
                                  '/photographs?categoryno=' +
                                  orderPhoto.categoryno
                                }
                              >
                                {orderPhoto.categoryname}
                              </Link>
                              <br />
                              写真番号[ID]: {orderPhoto.photoname} [
                              {orderPhoto.photographno}]<br />
                              カメラマン {orderPhoto.cameramanid}{' '}
                              {orderPhoto.cameramanName}
                              <br />
                              タイプ {orderPhoto.photoType}
                              <br />
                              サイズ {orderPhoto.photosize}
                              <br />
                              枚数 {orderPhoto.photosum}
                              <br />
                              単価 {orderPhoto.photoprice}
                              <br />
                              期間外手数料 {orderPhoto.photocommission}
                              <br />
                              ダウンロード期日{' '}
                              {orderPhoto.photosize === 1 ||
                              orderPhoto.photosize === 16 ||
                              orderPhoto.photosize === 9
                                ? orderInfo.dllimitday
                                : ''}
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      ))}
    </div>
  );
};

export default App;
