import React, {
  useCallback,
  useState,
  useEffect,
  ChangeEventHandler,
} from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';

import Paginator from '@/components/shared/Paginator/App';
import {
  convertQueryToFormData,
  makePathForSearchForm,
} from '@/ts/makePathForSearchForm';
import Loading from '../../../shared/Loading/App';
import { renderError } from '../../../../ts/useApi';
import { useJsonApiForSearchForm } from '../../../../ts/useJsonApiForSearchForm';
import * as incentivePaymentCheckboxes from '../../../shared/IncentivePayments/Index/incentivePaymentCheckboxes';
import * as canActions from '../../../shared/IncentivePayments/Index/canActions';
import Actions from '../../../shared/IncentivePayments/Index/Actions';
import DownloadCsv from '../../../shared/IncentivePayments/Index/DownloadCsv';

import SearchForm from './SearchForm';
import SearchResultItem from './SearchResultItem';
import {
  TCheck,
  TFormInputs,
  TIncentivePayment,
  TIndexResponse,
} from './types';

const HasNoResult: React.FC = () => (
  <div className="u-mgb_m">
    <hr className="u-line_plane" />
    <div className="c-emptyState_box">ご指定の条件では見つかりませんでした</div>
  </div>
);

const HasValidationError: React.FC = () => (
  <div className="u-mgb_m">
    <hr className="u-line_plane" />
    <div className="c-emptyState_box">検索条件が不正です。</div>
  </div>
);

const SearchResults: React.FC<{
  canEdit: boolean;
  displayCheckAll: boolean;
  incentivePayments: TIncentivePayment[];
  checkAll: boolean;
  toggleCheckAll: ChangeEventHandler;
  checks: TCheck[];
  toggleCheck: ChangeEventHandler;
}> = React.memo(
  ({
    canEdit,
    displayCheckAll,
    incentivePayments,
    checkAll,
    toggleCheckAll,
    checks,
    toggleCheck,
  }) => (
    <table className="c-indexList">
      <thead>
        <tr>
          <th className="u-align_center">
            {checks.some((c) => !c.disabled) && displayCheckAll ? (
              <label className="c-checkbox_listbox">
                <input
                  type="checkbox"
                  className="c-checkbox_list"
                  name="check-all"
                  value="all"
                  checked={checkAll}
                  onChange={toggleCheckAll}
                />
                {/* NOTE: レイアウトの都合で必要な空のelement */}
                <span className="c-label_checkbox_list c-label_checkbox_list__empty"></span>
              </label>
            ) : (
              <span className="c-help l-relative">
                {'×'}
                <span className="c-help_tooltip">
                  「一括承認依頼」「振込日一括登録」で使用します
                </span>
              </span>
            )}
          </th>
          <th>
            状態
            <br />
            発行月
          </th>
          <th>パートナー名</th>
          <th>プラン</th>
          <th>総支払額</th>
          <th>総売上</th>
          <th>承認日</th>
          <th>振込依頼日</th>
          <th>口座不備</th>
          <th>種類</th>
          <th>振込日</th>
        </tr>
      </thead>
      <tbody>
        {incentivePayments.map((p) => (
          <SearchResultItem
            key={p.id}
            canEdit={canEdit}
            incentivePayment={p}
            check={checks.find((c) => c.id === p.id)}
            toggleCheck={toggleCheck}
          />
        ))}
      </tbody>
    </table>
  )
);

const App: React.FC = () => {
  const history = useHistory();
  const searchString = useLocation().search;

  const [checkAll, setCheckAll] = useState(false);
  const [enabledMultipleRequestApprove, setEnabledMultipleRequestApprove] =
    useState(false);
  const [enabledMultipleSetPaidAt, setEnabledMultipleSetPaidAt] =
    useState(false);
  const [checks, setChecks] = useState<TCheck[]>([]);

  // query default値： 発行日＝当月
  const currentMonth = dayjs(new Date()).format('YYYY-MM');
  const queryString = searchString
    ? searchString
    : `?issuedOnFrom=${currentMonth}&issuedOnTo=${currentMonth}`;

  // search
  const { data, error } = useJsonApiForSearchForm<TIndexResponse>(
    '/api/partner_incentive_payments/',
    queryString
  );
  const queryParams = convertQueryToFormData(queryString, data?.validator);

  // 初期化処理系
  useEffect(() => {
    const queryParams = convertQueryToFormData(queryString);
    // reset checkAll
    setCheckAll(false);

    if (!data?.conditions.canEdit || !('statuses' in queryParams)) {
      setEnabledMultipleRequestApprove(false);
      setEnabledMultipleSetPaidAt(false);
      return;
    }

    // reset enabled Multiples
    // TODO: queryParams をnumberに変換可能になったらstring->numberに修正
    const statuses = queryParams['statuses'] as string[];
    setEnabledMultipleRequestApprove(
      canActions.isEnabledMultipleRequestApprove(statuses)
    );
    setEnabledMultipleSetPaidAt(
      canActions.isEnabledMultipleSetPaidAt(statuses, 'PARTNER')
    );
  }, [queryString, data?.conditions.canEdit]);

  useEffect(() => {
    // TODO: checkされてるとき検索しようとしたら、「チェック解除されます」を出すべきかも
    // checkの初期化
    if (!data) {
      setChecks([]);
      return;
    }
    const tmpChecks = incentivePaymentCheckboxes.initialize(
      data.data,
      enabledMultipleRequestApprove,
      enabledMultipleSetPaidAt,
      'PARTNER'
    );
    setChecks(tmpChecks);
  }, [data, enabledMultipleRequestApprove, enabledMultipleSetPaidAt]);

  // submit
  const onSubmit: SubmitHandler<TFormInputs> = useCallback(
    async (formData) => {
      const newPath = makePathForSearchForm(
        '/partner_incentive_payments/',
        queryString,
        formData
      );

      history.push(newPath);
    },
    [history, queryString]
  );

  // check 全選択toggle
  const toggleCheckAll: ChangeEventHandler = useCallback(() => {
    incentivePaymentCheckboxes.toggleCheckAll(
      checkAll,
      checks,
      setCheckAll,
      setChecks
    );
  }, [checkAll, checks]);
  // check
  const toggleCheck: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      incentivePaymentCheckboxes.toggleCheck(
        e.target,
        checks,
        setCheckAll,
        setChecks
      );
    },
    [checks]
  );

  if (error) {
    return renderError(error);
  }
  if (!data) {
    return <Loading />;
  }

  const {
    data: incentivePayments,
    validator,
    formItems,
    pagination,
    conditions: { canDownloadCsv, canEdit },
  } = data;

  return (
    <div className="c-index p-partnerIncentivePaymentsIndex c-incentivePayments">
      <div className="l-flex">
        <h1 className="u-mgb_m c-page_title">パートナー請求明細一覧</h1>
        <div className="l-left_auto">
          <DownloadCsv enable={canDownloadCsv} target="PARTNER" />
        </div>
      </div>
      <div className="l-center_wrap">
        <SearchForm
          validator={validator}
          formItems={formItems}
          queryParams={queryParams}
          onSubmit={onSubmit}
        />
        <Actions
          target="PARTNER"
          checks={checks}
          enabledMultipleSetPaidAt={enabledMultipleSetPaidAt}
          enabledMultipleRequestApprove={enabledMultipleRequestApprove}
        />
        <div className="c-frame">
          <div className="l-flex_between_center u-mgb_s">
            <p className="c-text_resultNumber">
              <span>{pagination?.total}</span>
              <small>件の結果</small>
            </p>
            <div className="l-flex_end">
              <div className="c-pagination_upper u-mgr_s">
                <Paginator
                  pagination={pagination}
                  currentPath="/partner_incentive_payments"
                  queryString={queryString}
                />
              </div>
              <div>
                <a
                  className="c-btn_rectangle c-btn_create is-disabled"
                  href="/"
                  target="_blank"
                >
                  <i className="c-icon c-icon__small c-icon_create"></i>
                </a>
              </div>
            </div>
          </div>
          {validator.hasError ? (
            <HasValidationError />
          ) : !incentivePayments.length ? (
            <HasNoResult />
          ) : (
            <SearchResults
              canEdit={canEdit}
              displayCheckAll={
                enabledMultipleRequestApprove || enabledMultipleSetPaidAt
              }
              incentivePayments={incentivePayments}
              checkAll={checkAll}
              toggleCheckAll={toggleCheckAll}
              checks={checks}
              toggleCheck={toggleCheck}
            />
          )}
          <Paginator
            pagination={pagination}
            currentPath="/partner_incentive_payments"
            queryString={queryString}
          />
        </div>
      </div>
    </div>
  );
};

export default App;
