import React, { useCallback, useState, MouseEventHandler } from 'react';
import { useLocation, Link, useHistory } from 'react-router-dom';

import { TValidationRule } from '@/components/shared/Form/types';
import { renderError, useJsonApi } from '../../../../ts/useApi';
import { postJson } from '../../../../ts/useApi';
import Loading from '../../../shared/Loading/App';
import { TAllSelectableCheck, TAllSelectableChecks } from '../../../../types';
import {
  TAssignPhotographersInputs,
  TAssignPhotographersResponse,
  TEvent,
  TEventPhotographersCheck,
  TFormItems,
  TRecommendedPhotographersResponse,
  TValidationRulesResponse,
} from './types';
import Events from './Events';
import PhotographerList from './PhotographerList';
import './style.scss';
import {
  errorToast,
  errorToastWithValidatorMessages,
} from '../../../../ts/toast';
import { isCheckedAllChecks } from '../../../../ts/toggleAllSelectableCheckboxes';

const makeFormData = (
  eventChecks: TAllSelectableCheck[],
  eventPhotographersChecks: TEventPhotographersCheck[]
): TAssignPhotographersInputs => ({
  assignPhotographers: eventPhotographersChecks
    .filter((epc) =>
      eventChecks.some((ec) => ec.checked && ec.id === epc.eventId)
    )
    .map((epc) => ({
      eventId: epc.eventId,
      photographerIds: epc
        ? epc.photographerChecks
            .filter((pc) => !pc.disabled && pc.checked)
            .map((pc) => pc.id)
        : [],
    })),
});

const isEventSelected = (eventChecks: TAllSelectableCheck[]) =>
  eventChecks.filter((ec) => !ec.disabled && ec.checked).length > 0;

const Main: React.FC<{
  events: TEvent[];
  formItems: TFormItems;
  rules: Record<string, TValidationRule | never>;
}> = React.memo(({ events, formItems, rules }) => {
  const history = useHistory();
  const [selectedEvents, setSelectedEvents] = useState<TEvent[]>([]);
  const [eventChecks, setEventChecks] = useState<TAllSelectableChecks>(() => {
    const checks = events.map((e) => ({
      id: e.id,
      checked: false,
      disabled: false,
    }));
    return {
      checkedAll: isCheckedAllChecks(checks),
      checks,
    };
  });
  const [eventPhotographersChecks, setEventPhotographersChecks] = useState<
    TEventPhotographersCheck[]
  >(
    events.map((e) => ({
      eventId: e.id,
      photographerChecks: e.recommendedPhotographers.map((p) => ({
        ...p,
        checked: true,
        disabled: false,
      })),
    }))
  );

  const onClickSubmit: MouseEventHandler = useCallback(
    async (e) => {
      e.preventDefault();
      if (
        !window.confirm(
          '選択したイベントのカメラマンを仮アサインします。よろしいですか？\n※登録完了後、画面を再読込します。カメラマンの選択状態はリセットされますのでご注意ください。'
        )
      ) {
        return;
      }
      const data = makeFormData(eventChecks.checks, eventPhotographersChecks);
      // カメラマン選ばれてないイベントが処理対象にあればエラー
      const photographerNoSelected = data.assignPhotographers.filter(
        (ap) => !ap.photographerIds.length
      );
      if (photographerNoSelected.length) {
        window.alert(
          `アサインするカメラマンを選択してください。イベントID: ${photographerNoSelected
            .map((ap) => ap.eventId)
            .join(', ')}`
        );
        return;
      }
      try {
        const { data: response, validator } =
          await postJson<TAssignPhotographersResponse>(
            '/api/arrange_photographer/assign_photographers',
            data
          );
        if (response === 'NG') {
          errorToastWithValidatorMessages(
            'アサインに失敗しました',
            validator?.messages ?? {}
          );
          return;
        }
        // reload
        history.go(0);
      } catch (error) {
        errorToast('エラーが発生しました');
      }
    },
    [eventChecks, eventPhotographersChecks, history]
  );

  return (
    <>
      <nav className="p-recommendedPhotographers_eventsContainer">
        <div className="l-flex_between_center">
          <Link className="c-page_back" to="/arrange_photographer">
            一覧に戻る
          </Link>
          <div className="l-flex_align_end">
            <button
              className={`c-btn_rectangle c-btn_create c-btn_small u-mgt_xs${
                isEventSelected(eventChecks.checks) ? '' : ' is-disabled'
              }`}
              onClick={onClickSubmit}
            >
              確定
            </button>
          </div>
        </div>
        <h1 className="l-flex_center u-mgb_m c-page_title u-mgt_s">
          優先カメラマン一覧
        </h1>
        <div className="c-frame">
          <Events
            events={events}
            selectedEvents={selectedEvents}
            setSelectedEvents={setSelectedEvents}
            eventChecks={eventChecks}
            setEventChecks={setEventChecks}
            eventPhotographersChecks={eventPhotographersChecks}
          />
        </div>
      </nav>
      {/* カメラマン一覧 */}
      <article className="p-recommendedPhotographers_photographersContainer">
        {selectedEvents.map((event) => (
          <article
            key={event.id}
            className={`p-recommendedPhotographers_photographers${
              selectedEvents.length === 1
                ? ' p-recommendedPhotographers_photographers__fullWidth'
                : ''
            }`}
          >
            <PhotographerList
              event={event}
              formItems={formItems}
              rules={rules}
              setEventPhotographersChecks={setEventPhotographersChecks}
            />
          </article>
        ))}
      </article>
    </>
  );
});

const App: React.FC = () => {
  const queryString = useLocation().search;

  const { data, error } = useJsonApi<TRecommendedPhotographersResponse>(
    `/api/arrange_photographer/recommended_photographers${queryString}`
  );
  const { data: formItemData, error: formItemError } =
    useJsonApi<TValidationRulesResponse>(
      '/api/arrange_photographer/photographers_validation_rules'
    );

  if (error) {
    return renderError(error);
  }
  if (formItemError) {
    return renderError(formItemError);
  }
  if (!data || !formItemData) {
    return <Loading />;
  }

  const { data: events } = data;
  const {
    data: { formItems, rules },
  } = formItemData;

  return (
    <div className="c-index p-recommendedPhotographers">
      <div className="l-center_wrap p-recommendedPhotographers_container">
        <Main events={events} formItems={formItems} rules={rules} />
      </div>
    </div>
  );
};

export default App;
