import {
  convertQueryToFormData,
  makePathForSearchForm,
} from '@/ts/makePathForSearchForm';
import { usePreventDuplicateCall } from '@/ts/usePreventDuplicateCall';
import dayjs from 'dayjs';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { renderError } from '../../../../ts/useApi';
import { useJsonApiForSearchForm } from '../../../../ts/useJsonApiForSearchForm';
import Loading from '../../../shared/Loading/App';
import './salesIndex.scss';
import { TFormInputs, TResponse } from './types';
import SearchForm from './SearchForm';
import Results from './Results';
import Description from './Description';
import QueryString from 'query-string';
const addDefaultParam = (queryString: string): string => {
  const parsed = QueryString.parse(queryString);
  if (Object.keys(parsed).length === 0) {
    return queryString;
  }
  parsed['timingType'] = parsed['timingType'] || '1';
  parsed['aggregateType'] = parsed['aggregateType'] || '1';
  parsed['amountConditionType'] = parsed['amountConditionType'] || '1';
  return QueryString.stringify(parsed);
};
const App: React.FC = () => {
  const history = useHistory();
  const queryString = addDefaultParam(useLocation().search);
  const { data: response, error } = useJsonApiForSearchForm<TResponse>(
    '/api/sales',
    queryString
  );

  const handleShow = usePreventDuplicateCall(async (data) => {
    const newPath = makePathForSearchForm('/sales', queryString, data);
    history.push(newPath);
  });

  if (error) {
    return renderError(error);
  }
  if (!response) {
    return <Loading />;
  }
  const queryParams = convertQueryToFormData(queryString, response.validator);
  const initialView = Object.keys(queryParams).length === 0;
  const defaultValues: TFormInputs = initialView
    ? {
        aggregateType: 1,
        timingType: 1,
        amountConditionType: 1,
        dateTo: dayjs().format('YYYY-MM-DD'),
        dateFrom: dayjs().format('YYYY-MM-01'),
      }
    : queryParams;
  const validator = initialView
    ? {
        ...response.validator,
        hasError: false,
        messages: {},
      }
    : response.validator;

  return (
    <>
      <h3 className="l-flex_center u-mgb_m">
        <span className="c-page_title">売れ行き集計</span>
      </h3>
      <SearchForm
        validator={validator}
        formItems={response.formItems}
        defaultValues={defaultValues}
        handleShow={handleShow}
      />
      {!!response.data.rows && <Results rows={response.data.rows} />}
      <Description />
    </>
  );
};

export default App;
