import { BreadCrumb } from '@/components/shared/BreadCrumb';
import { ButtonsFooter } from '@/components/shared/ButtonsFooter';
import {
  NumberInput,
  RadioBoxes,
  SingleSelect,
  TextArea,
} from '@/components/shared/Form/Inputs';
import { TChoice, TValidator } from '@/components/shared/Form/types';
import React, { FC, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FormField, TCreateResponse, TPhotographer } from './types';
import { usePreventDuplicateCall } from '@/ts/usePreventDuplicateCall';
import { postJson } from '@/ts/fetch';
import { errorToast, successToast } from '@/ts/toast';
import {
  isValidationError,
  renderError,
  toMessages,
  useJsonApi,
} from '@/ts/useApi';
import { alertApiError } from '@/ts/formValidation';
import Loading from '@/components/shared/Loading/App';
import { BackButton } from '@/components/pages/PhotographerOrganizations/Form/types';
import dayjs from 'dayjs';
import queryString from 'query-string';
import { convertQueryToFormData } from '@/ts/makePathForSearchForm';
import { removeEmpty } from '@/ts/objectTools';
import { dateFormatFromISO8601 } from '@/ts/formatTools';

const Contents: FC<{
  response: TCreateResponse;
}> = ({ response }) => {
  const photographerSelectChoices = useMemo(() => {
    const photographerChoices: TChoice[] = [];
    response.formItems.photographers.forEach((photographer: TPhotographer) => {
      photographerChoices.push({
        key: photographer.photographerId,
        value: `${photographer.photographerId}/${photographer.photographerSei} ${photographer.photographerMei}(${photographer.photographerSeiKana} ${photographer.photographerMeiKana})`,
      });
    });
    return photographerChoices;
  }, [response]);

  const methods = useForm<FormField>({
    defaultValues: {
      photographerId: '',
      price: 0,
      consumptionTaxPercent: 10,
      hasWithholdingTax: 1,
      tradingDate: dayjs(response.formItems.tradingDate).format('YYYY-MM-DD'),
      externalMemo: '',
      type: undefined,
    },
  });
  const history = useHistory();
  const [validator, setValidator] = useState<TValidator>(response.validator);

  const onSubmit: SubmitHandler<FormField> = usePreventDuplicateCall(
    async (formData) => {
      if (response.formItems.event) {
        formData['eventId'] = response.formItems.event.eventId;
      }
      try {
        await postJson('/api/photographer_payments/adjustment', formData);
        successToast('調整金を登録しました');
        history.push(`/photographer_payments`);
      } catch (e) {
        if (isValidationError(e)) {
          setValidator(e.jsonMessage.validator);
          alertApiError();
        } else {
          setValidator(response.validator);
          errorToast(`エラーが発生しました: ${toMessages(e)}`);
        }
      }
    }
  );

  const backButton: BackButton = {
    name: '戻る',
    url: '/photographer_payments',
  };

  return (
    <div>
      <BreadCrumb
        pagesInfo={[
          { id: 1, title: 'はいチーズ!フォト管理画面', path: '/' },
          {
            id: 2,
            title: '報酬管理',
            path: '/photographer_payments',
          },
          { id: 3, title: '調整金登録', path: undefined },
        ]}
      />
      <div className="nowrap u-mgb_m">
        <div className="l-flex_start">
          <h1 className="l-flex_center c-page_title">調整金登録</h1>
        </div>
      </div>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormProvider {...methods}>
          <>
            <div className="l-center_wrap">
              <div className="c-frame">
                <ul className="l-flex between c-label_line">
                  <li className="c-dataLabel">
                    カメラマン
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <SingleSelect
                      name="photographerId"
                      choices={photographerSelectChoices}
                      validator={validator}
                    ></SingleSelect>
                  </li>
                </ul>
                <ul className="l-flex between c-label_line">
                  <li className="c-dataLabel">カメラマン組織</li>
                  <li className="c-dataValue">
                    {response.formItems.organizationName}
                  </li>
                </ul>
                {response.formItems.event && (
                  <ul className="l-flex between c-label_line">
                    <li className="c-dataLabel">イベント</li>
                    <li className="c-dataValue">
                      {response.formItems.event.eventName}
                    </li>
                  </ul>
                )}
                <ul className="l-flex between c-label_line">
                  <li className="c-dataLabel">取引日</li>
                  <li className="c-dataValue">
                    {dateFormatFromISO8601(response.formItems.tradingDate)}
                  </li>
                </ul>
                <ul className="l-flex between c-label_line">
                  <li className="c-dataLabel">
                    詳細区分
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <RadioBoxes
                      name="type"
                      choices={response.formItems.types}
                      validator={validator}
                    />
                  </li>
                </ul>
                <ul className="l-flex between c-label_line">
                  <li className="c-dataLabel">
                    金額(税込)
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <NumberInput
                      name="price"
                      validator={validator}
                    ></NumberInput>
                    <p className="t-textColor_sub">
                      ※調整金は登録後、次の締め処理のタイミングで、そのときに登録されている報酬と合算されます
                    </p>
                  </li>
                </ul>
                <ul className="l-flex between c-label_line">
                  <li className="c-dataLabel">
                    税率
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <RadioBoxes
                      name="consumptionTaxPercent"
                      choices={response.formItems.consumptionTaxPercent}
                      validator={validator}
                    />
                  </li>
                </ul>
                <ul className="l-flex between c-label_line">
                  <li className="c-dataLabel">
                    源泉徴収
                    <small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <RadioBoxes
                      name="hasWithholdingTax"
                      choices={[
                        {
                          key: 1,
                          value: '必要',
                        },
                        {
                          key: 0,
                          value: '不要',
                        },
                      ]}
                      validator={validator}
                    />
                  </li>
                </ul>
                <ul className="l-flex between c-label_line">
                  <li className="c-dataLabel">
                    備考<small className="c-required">(必須)</small>
                  </li>
                  <li className="c-dataValue">
                    <TextArea name="externalMemo" validator={validator} />
                    <p className="t-textColor_sub">※明細に表示されます</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="u-mgb_l" />
            <ButtonsFooter>
              <Link
                className="c-btn_large c-btn_cancel u-pdt_s u-pdb_s c-input_submit"
                to={backButton.url}
              >
                {backButton.name}
              </Link>
              <button className="c-btn_large c-btn_primary c-btn_rectanble c-input_submit">
                登録
              </button>
            </ButtonsFooter>
          </>
        </FormProvider>
      </form>
    </div>
  );
};

const toQueryParam = (queryString: string): Record<string, unknown> => {
  return removeEmpty(convertQueryToFormData(queryString));
};

const toApiPath = (queryParam: Record<string, unknown>): string => {
  queryParam = { ...queryParam };
  const query = queryString.stringify(queryParam, { arrayFormat: 'bracket' });
  return `/api/photographer_payments/adjustment/create?${query}`;
};

export const Create: FC = () => {
  const queryString = useLocation().search;
  const queryParams = toQueryParam(queryString);
  const { data: response, error } = useJsonApi<TCreateResponse>(
    toApiPath(queryParams)
  );

  if (error) {
    return renderError(error);
  }
  if (!response) {
    return <Loading />;
  }
  return <Contents response={response} />;
};
