import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { renderError, useJsonApi } from '../../../../ts/useApi';
import Loading from '../../../shared/Loading/App';
import PriceContainer from '../PriceContainer/App';
import { isProductTypePhoto } from '../../../../ts/societyContractConstants';
import { IndexResponse } from './types';

const App: React.FC = () => {
  const { societyContractId, planId } =
    useParams<{ societyContractId: string; planId: string }>();
  const {
    data: result,
    error,
    mutate,
  } = useJsonApi<IndexResponse>(
    '/api/society_contracts/' +
      societyContractId +
      '/plans/' +
      planId +
      '/prices'
  );

  if (error) {
    return renderError(error);
  }
  if (!result) {
    return <Loading />;
  }

  const {
    data: { society, societyContract, societyContractPlan, ...priceProps },
    validator,
  } = result;

  const societyContractTypeTeacher = 2;

  return (
    <div className="c-summary">
      <Link className="c-page_back" to={`/societies/${society.id}`}>
        団体サマリに戻る
      </Link>
      <h3 className="l-flex_center u-mgb_m">
        <span className="c-page_title">価格一覧</span>
      </h3>
      <div className="l-center_wrap">
        <div className="c-frame l-flex">
          <div className="l-flex_center u-mgr_m">
            <span className="c-label c-label__monotone u-fz_xs">
              団体ID: {society.id}
            </span>
            <span className="p-groupsName u-fz_l">
              <Link className="c-textlink" to={`/societies/${society.id}`}>
                {society.name}
              </Link>
            </span>
          </div>
          <div className="l-flex_center u-mgr_m">
            <span className="c-label c-label__monotone u-fz_xs">
              契約ID: {societyContract.id}
            </span>
            <span className="u-fz_l">
              {societyContract.societyContractTypeName}
            </span>
          </div>
          <div className="l-flex_center u-mgr_m">
            <span className="c-label c-label__monotone u-fz_xs">プラン</span>
            <span className="u-fz_l">
              {societyContractPlan.planName}（
              {societyContractPlan.productTypeName}）
            </span>
          </div>
        </div>
        <PriceContainer
          {...priceProps}
          validator={validator}
          savePath={`/api/society_contracts/${societyContractId}/plans/${planId}/prices`}
          updatePathPrefix={`/api/society_contracts/${societyContractId}/plans/${planId}`}
          isPhoto={isProductTypePhoto(societyContractPlan.productType)}
          onUpdate={mutate}
          enableInputTaxIncludedPrice={false}
          teacherFlag={
            societyContract.societyContractTypeId === societyContractTypeTeacher
          }
        />
      </div>
    </div>
  );
};

export default App;
