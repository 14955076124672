import React from 'react';
import { renderError, useJsonApi } from '../../../../ts/useApi';
import { TResponse } from './types';
import { useHistory, useParams } from 'react-router-dom';
import { Detail } from './Detail/Detail';
import Loading from '../../../shared/Loading/App';
import { BreadCrumb } from '../../../shared/BreadCrumb';
import './style.scss';

const App: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data, error } = useJsonApi<TResponse>(
    '/api/photographer_organizations/' + id
  );
  const history = useHistory();
  const handleGoBack = () => {
    history.push(`/photographer_organizations/`);
  };
  const handleGoEdit = () => {
    history.push(`/photographer_organizations/${id}/edit`);
  };

  if (error) {
    return renderError(error);
  }
  if (!data) {
    return <Loading />;
  }

  return (
    <div>
      <div className="p-partnerSummary l-flex l-col_wrap">
        <div className="l-col_20">
          <BreadCrumb
            pagesInfo={[
              { id: 1, title: 'はいチーズ!フォト管理画面', path: '/' },
              {
                id: 2,
                title: 'カメラマン組織一覧に戻る',
                path: '/photographer_organizations',
              },
              { id: 3, title: 'カメラマン組織サマリ', path: undefined },
            ]}
          />
          <div className="nowrap u-mgb_m">
            <div className="l-flex_start">
              <h1 className="l-flex_center c-page_title">
                カメラマン組織サマリ
              </h1>
            </div>
          </div>
          <div className="l-center_wrap">
            <div className="l-flex nowrap">
              <div className="c-submenu_main c-summary l-col_wrap">
                <div className="l-flex">
                  <div className="l-col_12">
                    <Detail
                      photographerOrganization={data.photographerOrganization}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="u-mgb_l" />
      <div className="p-photographerOrganizationShow_link">
        <button className="back" onClick={handleGoBack}>
          　戻る　
        </button>
        <button className="edit" onClick={handleGoEdit}>
          編集する
        </button>
      </div>
    </div>
  );
};

export default App;
