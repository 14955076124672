import React from 'react';
import { Link, useParams } from 'react-router-dom';

import Loading from '@/components/shared/Loading/App';
import { renderError } from '@/ts/useApi';
import { useJsonApi } from '@/ts/useJsonApi';

import SendForm from './Create/SendForm';
import { TCreateResponse } from './types';
import './app.scss';

const App: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data, error } = useJsonApi<TCreateResponse>(
    `/api/events/${id}/flyer/cover_letter`
  );
  if (error) {
    return renderError(error);
  }
  if (!data) {
    return <Loading />;
  }

  return (
    <>
      <Link className="c-page_back" to={`/events/${id}/eventflyer`}>
        PDFチラシ作成に戻る
      </Link>
      <h3 className="l-flex_center u-mgb_m">
        <span className="c-page_title">送付状作成</span>
      </h3>
      <div className="c-frame">
        <SendForm data={data.data} eventno={id} />
      </div>
    </>
  );
};

export default App;
