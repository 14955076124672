import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import { TValidatorResponse } from '@/components/shared/Form/types';

import { renderError, useJsonApi } from '../../../../ts/useApi';
import Loading from '../../../shared/Loading/App';
import { submitForm } from '../../../shared/IncentivePayments/Show/update';

import {
  TShowResponse,
  TShowHtmlData,
  TShowPdfData,
  TPdfFormInputs,
  THtmlFormInputs,
} from './types';
import PdfDetails from './PdfDetails';
import HtmlDetails from './HtmlDetails';

const App: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: response, error } = useJsonApi<TShowResponse>(
    '/api/teacher_incentive_payments/' + id
  );

  const submit = useCallback(
    async (
      formData: THtmlFormInputs | TPdfFormInputs,
      incentivePaymentId: number,
      setValidator: Dispatch<SetStateAction<TValidatorResponse>>,
      type: 'HTML' | 'PDF'
    ) => {
      await submitForm(
        'TEACHER',
        formData,
        incentivePaymentId,
        setValidator,
        type
      );
    },
    []
  );

  if (error) {
    return renderError(error);
  }
  if (!response) {
    return <Loading />;
  }

  const {
    rules,
    data: { type },
  } = response;
  const isHtml = type === 'HTML';

  const validator: TValidatorResponse = {
    messages: {},
    hasError: false,
    rules,
  };

  return (
    <div className="p-teacherIncentivePaymentSummary c-summary">
      <Link className="c-page_back" to="/teacher_incentive_payments">
        請求明細一覧に戻る
      </Link>
      <h1 className="l-flex_center u-mgb_m c-page_title">先生請求明細サマリ</h1>
      <div className="l-center_wrap">
        {isHtml ? (
          <HtmlDetails
            submit={submit}
            validator={validator}
            data={response.data as TShowHtmlData}
          />
        ) : (
          <PdfDetails
            submit={submit}
            validator={validator}
            data={response.data as TShowPdfData}
          />
        )}
      </div>
    </div>
  );
};

export default App;
