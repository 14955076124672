import React from 'react';
import Loading from '../../../shared/Loading/App';
import { Link, useParams } from 'react-router-dom';
import { renderError, useJsonApi } from '../../../../ts/useApi';
import { TGroupsShowResponse } from './types';
import TextCopy from '../../../shared/TextCopy/App';
import { dateFormat } from '../../../../ts/formatTools';
import { kanriUrl, ownerUrl } from '../../../../ts/url';
import CreateYearCertificationkey from '../../../shared/CreateYearCertificationkey/App';
import QueryString from 'query-string';
import { TAB_PARTNER } from '../../../shared/Certificationkeys/constants';

const App: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data, error } = useJsonApi<TGroupsShowResponse>('/api/groups/' + id);
  const BULKDELIVERYADDRESSTYPE_TABLE = 2;
  const INVITATION_MAIL_DONE = '2';
  const CANCELLATION_FLAG_OPEN = '0';
  const CANCRLLATION_FLAG_CANCEL = '1';
  const CANCELLATION_FLAG_NOT_OPEN = '2';

  // ethnamの団体サマリにリダイレクトさせておく
  window.location.href = kanriUrl({ g: id });

  if (error) {
    return renderError(error);
  }
  if (!data) {
    return <Loading />;
  }

  const {
    data: { groups, groupsdetails, groupsOther, groupsmemo },
    conditions: { currentFiscalYear, canCreateYearCertificationkey },
  } = data;

  return (
    <div className="App">
      <div className="p-groupsSummary c-summary">
        <h1 className="l-flex_center u-mgb_m c-page_title">団体サマリ</h1>
        <CreateYearCertificationkey
          target="groups"
          id={groups.id}
          currentFiscalYear={currentFiscalYear}
          canCreate={canCreateYearCertificationkey}
        />
        <div className="l-content">
          <div className="l-center_wrap">
            <div className="c-frame">
              <div className="l-flex_between_center p-groupsSummary_Label">
                <div className="l-flex">
                  <span className="c-statusLabel c-statusLabel__monotone u-fz_xs">
                    団体ID: {groups.id}
                  </span>
                  {groups.cancellationflag === CANCELLATION_FLAG_OPEN ? (
                    <span className="c-statusLabel c-statusLabel__positive u-fz_xs">
                      稼働
                    </span>
                  ) : groups.cancellationflag === CANCRLLATION_FLAG_CANCEL ? (
                    <span className="c-statusLabel c-statusLabel__negative u-fz_xs">
                      解約
                    </span>
                  ) : groups.cancellationflag === CANCELLATION_FLAG_NOT_OPEN ? (
                    <span className="c-statusLabel c-statusLabel__negative u-fz_xs">
                      非稼働
                    </span>
                  ) : (
                    ''
                  )}
                </div>
                <div className="l-flex">
                  <div className="u-mgr_s">
                    <span className="u-fz_xs c-statusLabel c-statusLabel__monotone">
                      登録日
                    </span>
                    <span className="u-fz_xs">
                      {dateFormat(groups.createdday)}
                    </span>
                  </div>
                  <div>
                    <span className="u-fz_xs c-statusLabel c-statusLabel__monotone">
                      更新日
                    </span>
                    <span className="u-fz_xs">
                      {dateFormat(groups.updatedday)}
                    </span>
                    <span className="u-fz_xs">
                      （{groups.updatedUserno}：{groups.updatedUserName}）
                    </span>
                  </div>
                </div>
              </div>
              <div className="l-flex_between_center">
                <div>
                  <div className="l-flex_align_base">
                    <h4 className="c-section_title u-fz_l">{groups.name}</h4>
                    <TextCopy text={`${groups.id}: ${groups.name}`} />
                  </div>
                  <span className="u-fz_xs">{groups.groupnamekana}</span>
                </div>
                <div className="l-flex_end">
                  <a
                    href={kanriUrl({
                      action_owner_GROUPedit: 'true',
                      groupsno: String(groups.id),
                    })}
                  >
                    <button className="c-btn_rectangle c-btn_edit">
                      <i className="c-icon__Xsmall c-icon_edit"></i>
                    </button>
                  </a>
                </div>
              </div>
              <div className="u-mgt_s u-mgb_m l-flex">
                <div className="c-submenu_main">
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">パートナー名</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            <Link
                              to={`/partners/${groupsdetails.agencyaccountno}`}
                            >
                              {groupsdetails.agencyname}
                            </Link>
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">団体名</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groups.name} ({groups.groupnamekana})
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">プラン</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.planName}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_12">
                      <ul className="l-flex">
                        <li className="c-dataLabel">団体区分</li>
                        <li className="c-dataValue">
                          <span>{groupsdetails.fundingTypeName}</span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_12">
                      <ul className="l-flex">
                        <li className="c-dataLabel">団体の種類</li>
                        <li className="c-dataValue">
                          <span>{groupsdetails.groupTypeName}</span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">スタッフ撮影</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.staffphotographingName}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">認証タイプ</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.authtypeName}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">販売商品</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.salesproducttypeName}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">郵便番号</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">〒{groups.postcode}</div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">都道府県</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groups.prefectureName}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">市区町村</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">{groups.city}</div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">それ以下の住所</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">{groups.address}</div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_12">
                      <ul className="l-flex">
                        <li className="c-dataLabel">電話番号</li>
                        <li className="c-dataValue">
                          <span>{groups.telephone}</span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_12">
                      <ul className="l-flex">
                        <li className="c-dataLabel">FAX</li>
                        <li className="c-dataValue">
                          <span>{groups.faxnumber}</span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">配送先住所指定の有無</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.requiredeliveryaddressName}
                            {/* 分岐 */}
                            {groupsdetails.requiredeliveryaddress === '1' ? (
                              <div>
                                <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                                  <li className="l-col_24">
                                    <ul className="l-flex">
                                      <li className="c-dataLabel">郵便番号</li>
                                      <li className="c-dataValue">
                                        <div className="u-pre_wrap">
                                          〒
                                          {`${groupsdetails.deliverypostcode}`}
                                        </div>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                                <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                                  <li className="l-col_24">
                                    <ul className="l-flex">
                                      <li className="c-dataLabel">都道府県</li>
                                      <li className="c-dataValue">
                                        <div className="u-pre_wrap">
                                          {groupsdetails.deliveryprefectureName}
                                        </div>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                                <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                                  <li className="l-col_24">
                                    <ul className="l-flex">
                                      <li className="c-dataLabel">市区町村</li>
                                      <li className="c-dataValue">
                                        <div className="u-pre_wrap">
                                          {groupsdetails.deliverycity}
                                        </div>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                                <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                                  <li className="l-col_24">
                                    <ul className="l-flex">
                                      <li className="c-dataLabel">
                                        それ以下の住所
                                      </li>
                                      <li className="c-dataValue">
                                        <div className="u-pre_wrap">
                                          {groupsdetails.deliveryaddress}
                                        </div>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                                <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                                  <li className="l-col_24">
                                    <ul className="l-flex">
                                      <li className="c-dataLabel">宛名</li>
                                      <li className="c-dataValue">
                                        <div className="u-pre_wrap">
                                          {groupsdetails.deliveryaddressee}
                                        </div>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                                <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                                  <li className="l-col_12">
                                    <ul className="l-flex">
                                      <li className="c-dataLabel">電話番号</li>
                                      <li className="c-dataValue">
                                        <span>
                                          {groupsdetails.deliverytelephone}
                                        </span>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">責任者メールアドレス</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groups.mailsendflag === INVITATION_MAIL_DONE ? (
                              <a
                                className="c-textlink"
                                href={ownerUrl('users/' + groups.useraccountno)}
                              >
                                {groups.leaderMailAddress}
                              </a>
                            ) : (
                              groups.mailaddress
                            )}
                            <br />
                            {groups.mailsendflagName}{' '}
                            {groups.mailsendflag === INVITATION_MAIL_DONE ? (
                              ''
                            ) : (
                              <a
                                className="c-textlink"
                                href={kanriUrl({
                                  action_Owner_GROUPsendmail: 'true',
                                  groupsno: String(groups.id),
                                })}
                              >
                                招待メールを送る
                              </a>
                            )}
                            <div>
                              {groups.mailsendflag === INVITATION_MAIL_DONE ? (
                                <a
                                  className="c-textlink"
                                  href={kanriUrl({
                                    action_owner_SYSTEMmaillog: 'true',
                                    mailaddress: groups.leaderMailAddress,
                                  })}
                                >
                                  メールログ
                                </a>
                              ) : (
                                <a
                                  className="c-textlink"
                                  href={kanriUrl({
                                    action_owner_SYSTEMmaillog: 'true',
                                    mailaddress: groups.mailaddress,
                                  })}
                                >
                                  メールログ
                                </a>
                              )}
                            </div>
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">
                          責任者ダウンロードの可/不可
                        </li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.leaderdownloadflagName}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">UP作業時の注意点</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsmemo.uploadattentionpoint}
                            {groupsdetails.photoframeflag === '1'
                              ? 'フレームは' +
                                groupsdetails.photoframetypeName +
                                'を使用する'
                              : ''}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">撮影時の注意点</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsmemo.photographingattentionpoint}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">事前確認の必要と方法</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.confirmationcheckflagName}
                            {groupsdetails.confirmationcheckflag ? (
                              <div>
                                {groupsdetails.confirmationchecktypeName}
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">責任者承認機能の有無</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.requireacceptanceName}
                            {groupsdetails.requireacceptance === '1' ? (
                              <div>
                                {groupsdetails.question1}
                                <br />
                                {groupsdetails.question2}
                                <br />
                                {groupsdetails.question3}
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">文字入れ機能の有無</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.allowoverlaytextName}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">チラシの有無</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.requirechirashiName}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">チラシ発送指定日の有無</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.chirashijizenflagName}
                            {groupsdetails.chirashijizenflag === '1' ? (
                              <div>
                                {groupsdetails.chirashijizentype}
                                <br />
                                {groupsdetails.chirashijizenday +
                                  '営業日前発送'}
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">チラシの文言</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.chirashicontent}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">チラシ部数</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.handbillcount}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">サンプルブックの有無</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.requiresbName}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">FAX用紙の有無</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.requirefaxName}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">DVDの有無</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.requiredvdName}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">
                          サンプルブック(事前確認用)の有無
                        </li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.requiresbjizenName}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">
                          サンプルブック作成時の注意点
                        </li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsmemo.samplebookattentionpoint}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">
                          チラシ・SB等配送時の注意点
                        </li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsmemo.deliveryattentionpoint}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">
                          スマートアルバム購入可否フラグ
                        </li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsOther.albumsalesflagName}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">その他の注意点</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsmemo.etcattentionpoint}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">掲載日固定</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.publishdayflagName}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  {groupsdetails.publishdayflag === '1' ? (
                    <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                      <li className="l-col_24">
                        <ul className="l-flex">
                          <li className="c-dataLabel">
                            固定掲載日の開始日・終了日
                          </li>
                          <li className="c-dataValue">
                            <div className="u-pre_wrap">
                              {groupsdetails.publishstartday}日 -
                              {groupsdetails.publishendday}日
                            </div>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  ) : (
                    ''
                  )}
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">期間外販売の有無</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.termsaleflagName}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        {groupsdetails.termsaleflag ? (
                          <li className="c-dataLabel">掲載期間</li>
                        ) : (
                          <li className="c-dataLabel">バリュー期間</li>
                        )}
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.publishtermname}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">写真の並び順の初期値</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.alignmentName}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">所属人数</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsmemo.numberOfBelonging}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">家庭数</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsmemo.numberOfFamily}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">年度開始月</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.startyear}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">クラス名リスト</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.classnames}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">
                          一括配送時の宛名の不要/必要
                        </li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.deliverynameflagName}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">団体URL</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            <a href={groupsdetails.groupwebsiteurl}>
                              {groupsdetails.groupwebsiteurl}
                            </a>
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">パートナー名の表示</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.showpartnernamePropName}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <div className="c-section_subTitle_gry l-flex_between_center u-mgb_m u-fz_default">
                    <div className="l-flex_between_center p-groupsSummary_outline">
                      <div>
                        <b className="u-mgr_s">特殊設定項目1</b>
                        (パートナー様画面からは設定できません)
                      </div>
                    </div>
                  </div>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">まとめ発送の利用可否</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.iscombinedshipped}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">商品配送方法</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.deliveryflagName}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">一括配送手数料</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.ikkatsucarriage}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">FAX申込書部数</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.faxhandlingcount}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">個人情報の表示</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.allowpersonalinfoName}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <div className="c-section_subTitle_gry l-flex_between_center u-mgb_m u-fz_default">
                    <div className="l-flex_between_center p-groupsSummary_outline">
                      <div>
                        <b className="u-mgr_s">特殊設定項目2</b>
                        (パートナー様画面からは設定・閲覧できません)
                      </div>
                    </div>
                  </div>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">
                          顔検索機能使用可否フラグ イベント作成時デフォルト値
                        </li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.faceRecommendFlagDefValueName}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">
                          お手持ち写真プリント可否
                        </li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.isSalableTsuideName}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">プチパネル可否</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.isSalablePhotopanelName}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">プリント会社</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.printcompanyflagName}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  {groupsdetails.deliveryflag === '1' ? (
                    groupsdetails.bulkdeliveryaddresstype !==
                    BULKDELIVERYADDRESSTYPE_TABLE ? (
                      <div>
                        <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                          <li className="l-col_24">
                            <ul className="l-flex">
                              <li className="c-dataLabel">JVIS一括配送宛先</li>
                              <li className="c-dataValue">
                                <div className="u-pre_wrap">
                                  {groupsdetails.bulkdeliveryaddresstypeName}
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                        <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                          <li className="l-col_24">
                            <ul className="l-flex">
                              <li className="c-dataLabel">JVIS一括配送締日</li>
                              <li className="c-dataValue">
                                <div className="u-pre_wrap">
                                  {groupsdetails.bulkdeliverycloseday}
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    ) : (
                      <div>
                        <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                          <li className="l-col_24">
                            <ul className="l-flex">
                              <li className="c-dataLabel">JVIS一括配送宛先</li>
                              <li className="c-dataValue">
                                <div className="u-pre_wrap">
                                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                                    <li className="l-col_24">
                                      <ul className="l-flex">
                                        <li className="c-dataLabel">
                                          郵便番号
                                        </li>
                                        <li className="c-dataValue">
                                          <div className="u-pre_wrap">
                                            〒
                                            {
                                              groupsdetails.groupDeliveryPostcode
                                            }
                                          </div>
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                                    <li className="l-col_24">
                                      <ul className="l-flex">
                                        <li className="c-dataLabel">
                                          都道府県
                                        </li>
                                        <li className="c-dataValue">
                                          <div className="u-pre_wrap">
                                            {
                                              groupsdetails.groupDeliveryPrefectureName
                                            }
                                          </div>
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                                    <li className="l-col_24">
                                      <ul className="l-flex">
                                        <li className="c-dataLabel">
                                          市区町村
                                        </li>
                                        <li className="c-dataValue">
                                          <div className="u-pre_wrap">
                                            {groupsdetails.groupDeliveryCity}
                                          </div>
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                                    <li className="l-col_24">
                                      <ul className="l-flex">
                                        <li className="c-dataLabel">
                                          それ以下の住所
                                        </li>
                                        <li className="c-dataValue">
                                          <div className="u-pre_wrap">
                                            {groupsdetails.groupDeliveryAddress}
                                          </div>
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                                    <li className="l-col_24">
                                      <ul className="l-flex">
                                        <li className="c-dataLabel">宛名</li>
                                        <li className="c-dataValue">
                                          <div className="u-pre_wrap">
                                            {
                                              groupsdetails.groupDeliveryAddressee
                                            }
                                          </div>
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                                    <li className="l-col_12">
                                      <ul className="l-flex">
                                        <li className="c-dataLabel">
                                          電話番号
                                        </li>
                                        <li className="c-dataValue">
                                          <span>
                                            {
                                              groupsdetails.groupDeliveryTelephone
                                            }
                                          </span>
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                        <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                          <li className="l-col_24">
                            <ul className="l-flex">
                              <li className="c-dataLabel">JVIS一括配送締日</li>
                              <li className="c-dataValue">
                                <div className="u-pre_wrap">
                                  {groupsdetails.bulkdeliverycloseday}
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    )
                  ) : (
                    ''
                  )}
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">
                          イベント期間自動設定の可否
                        </li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.autoeventtermsettingflagName}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">FAX手数料の有無</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.faxcommissionflagName}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">送料の有無</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.carriageflagName}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">
                          フォトマネー購入の可/不可
                        </li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.pointsaleflagName}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">
                          集合写真の専用フレームの有無
                        </li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.photoframeflagName}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  {groupsdetails.photoframeflag === '1' ? (
                    <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                      <li className="l-col_24">
                        <ul className="l-flex">
                          <li className="c-dataLabel">フレームタイプ</li>
                          <li className="c-dataValue">
                            <div className="u-pre_wrap">
                              {groupsdetails.photoframetypeName}
                            </div>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  ) : (
                    ''
                  )}
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">
                          不定期キャンペーン可/不可
                        </li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.campaignflagName}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">
                          モデル募集フォームの表示/非表示
                        </li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.modelapplicationflagName}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">
                          写真閲覧キー問い合わせ可否
                        </li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.autonoticecertificationkeyName}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">ログインなしで写真閲覧</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.loginlessflagName}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">閲覧専用かどうか</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groups.viewonlyflagName}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">0円価格登録の可/不可</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.freephotoflagName}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">
                          (廃止)イベントの自動作成
                        </li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.autoeventcreateflagName}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">詳細FAX送信可否</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.shousaifaxsendflagName}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">
                          撮影日程確認書の自動作成
                        </li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.tsgfaxpdfflagName}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">責任者約款表示フラグ</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.leaderagreementflagName}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">法人格フラグ</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.corporationflagName}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">最寄駅</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.moyorieki}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">所在地への行き方</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.howtogo}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">(撮影時の)備考</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.satsueimemo}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    {groupsOther.students.map((students) => (
                      <li key={students.nendo}>
                        <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                          <li className="l-col_24">
                            <ul className="l-flex">
                              <li className="c-dataLabel">
                                園児・生徒情報({students.nendo})
                              </li>
                              <li className="c-dataValue">
                                <div className="u-pre_wrap">
                                  {students.summary}
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                    ))}
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">内定者社カメの研修可否</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.insidephotographertraineetypeName}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">相カメの研修可否</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.outsidephotographertraineetypeName}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">担当営業名</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.eigyoOwnerName}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">副担当営業名</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.eigyosubownerName}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">担当営業の変更履歴</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.eigyochangehistory}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">詳細対応者の変更履歴</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.photographerchangehistory}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">インセンティブ率</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.incentiverate + '%'}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">特殊集計条件</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.specialincentiveratetypeName}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                    <li className="l-col_24">
                      <ul className="l-flex">
                        <li className="c-dataLabel">しきい値価格</li>
                        <li className="c-dataValue">
                          <div className="u-pre_wrap">
                            {groupsdetails.specialincentiveratetype !== '0' ? (
                              <p>
                                L判価格{groupsdetails.thresholdprice}円<br />
                                以上のとき{groupsdetails.incentiverate}%
                                <br />
                                未満のとき{groupsdetails.lessthanthreshold}
                              </p>
                            ) : (
                              groupsdetails.specialincentiveratetypeName
                            )}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  {groupsdetails.araidiscountamount > 0 ? (
                    <ul className="l-col_wrap l-flex l-col_wrap__isSpMode">
                      <li className="l-col_24">
                        <ul className="l-flex">
                          <li className="c-dataLabel">
                            アライ様送料特別値引きの閾値
                          </li>
                          <li className="c-dataValue">
                            <div className="u-pre_wrap">
                              {groupsdetails.araidiscountamount}
                            </div>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  ) : (
                    ''
                  )}
                </div>
                <div className="c-submenu_sub">
                  <ul className="c-submenu_linkList">
                    <li className="c-submenu_linkList_item">
                      <Link
                        className="c-textlink"
                        to={`/events?${QueryString.stringify({
                          'societyIds[]': groups.id.toString(),
                        })}`}
                      >
                        イベント一覧
                      </Link>
                    </li>
                    <li className="c-submenu_linkList_item">
                      <a
                        href={kanriUrl({
                          action_Owner_EVENTnew: 'true',
                          groupsno: groups.id.toString(),
                        })}
                        className="c-textlink"
                      >
                        イベント登録
                      </a>
                    </li>
                    <li className="c-submenu_linkList_item">
                      <a
                        href={kanriUrl({
                          action_owner_NEWSlist: 'true',
                          newsflag: '0',
                          groupsno: groups.id.toString(),
                        })}
                        className="c-textlink"
                      >
                        お知らせ文
                      </a>
                    </li>
                    <li className="c-submenu_linkList_item">
                      <a
                        className="c-textlink"
                        href={kanriUrl({
                          action_owner_SALESsearch: 'true',
                          groupsno: groups.id.toString(),
                          eigyoownerno: groupsdetails.eigyoownerno.toString(),
                          search_period: 'this_nendo',
                          outputmode: 'html',
                        })}
                      >
                        売れ行き（今年度分）
                      </a>
                    </li>
                    <li className="c-submenu_linkList_item">
                      <Link
                        to={`/certificationkeys/?${QueryString.stringify({
                          groupId: groups.id,
                          type: TAB_PARTNER,
                        })}`}
                      >
                        写真閲覧キー一覧
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
