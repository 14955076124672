import React, { useCallback } from 'react';
import BaseApp, { useApiUrl } from '@/components/shared/Photographs/Index/App';
import { renderError, toMessages, useJsonApi } from '../../../../ts/useApi';
import { TIndexResponse } from '@/components/shared/Photographs/Index/types';
import Loading from '../../../shared/Loading/App';
import './photographs.scss';
import { errorToast, successToast } from '../../../../ts/toast';
import { kanriUrl } from '../../../../ts/url';

const App: React.FC = () => {
  const url = useApiUrl();
  const { data: response, error, mutate } = useJsonApi<TIndexResponse>(url);
  const handleApiSuccess = useCallback((s: string) => {
    successToast(s);
  }, []);
  const handleApiError = useCallback(
    (e: unknown, s = 'エラーが発生しました') => {
      errorToast(s);
      return toMessages(e);
    },
    []
  );
  if (error) {
    return renderError(error);
  }
  if (!response) {
    return <Loading />;
  }
  if (response.data.convertinfo) {
    response.data.convertinfo.link = kanriUrl({
      co: response.data.convertinfo.id.toString(),
    });
  }
  if (response.data.event) {
    response.data.event.link = kanriUrl({
      e: response.data.event.id.toString(),
    });
  }
  if (response.data.event?.groupId) {
    response.data.event.groupSummaryUrl = kanriUrl({
      g: response.data.event.groupId.toString(),
    });
  }
  response.data.photographs = response.data.photographs.map((photograph) => ({
    ...photograph,
    soldLinkUrl: kanriUrl({
      action_Owner_ORDERlist: 'true',
      photographno: photograph.id.toString(),
    }),
  }));
  return (
    <BaseApp
      response={response}
      mutate={mutate}
      handleApiSuccess={handleApiSuccess}
      handleApiError={handleApiError}
      showIndividualHide={false}
    />
  );
};

export default App;
