import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { renderError, useJsonApi } from '../../../../ts/useApi';
import Loading from '../../../shared/Loading/App';
import {
  TBulkHiddenPhotograph,
  TBulkHiddenResponse,
  TFormInputs,
} from './types';
import EventHeader from '../EventHeader';
import Form from './Form';
import Photographs from './Photographs';
import { FormProvider, useForm } from 'react-hook-form';

const App: React.FC = () => {
  const [photographs, setPhotographs] = useState<TBulkHiddenPhotograph[]>([]);
  const [isDisplayForm, setIsDisplayForm] = useState(true);
  const [submittedPhotographNamesText, setSubmittedPhotographNamesText] =
    useState('');

  const methods = useForm<TFormInputs>();

  const { id } = useParams<{ id: string }>();
  const { data: response, error } = useJsonApi<TBulkHiddenResponse>(
    `/api/events/${id}/bulk_hidden_photographs`
  );

  if (error) return renderError(error);
  if (!response) return <Loading />;

  const { data: shortResponseData, validator } = response;

  return (
    <div className="p-partnerSummary c-summary">
      <Link className="c-page_back" to={`/events/${id}`}>
        イベントサマリに戻る
      </Link>
      <h1 className="u-mgb_m c-page_title">写真一括非表示</h1>
      <EventHeader {...shortResponseData} />
      <FormProvider {...methods}>
        {isDisplayForm ? (
          <Form
            id={shortResponseData.event.id}
            validator={validator}
            setPhotographs={setPhotographs}
            setIsDisplayForm={setIsDisplayForm}
            setSubmittedPhotographNamesText={setSubmittedPhotographNamesText}
          />
        ) : (
          <Photographs
            id={shortResponseData.event.id}
            photographs={photographs}
            setPhotographs={setPhotographs}
            setIsDisplayForm={setIsDisplayForm}
            submittedPhotographNamesText={submittedPhotographNamesText}
            setSubmittedPhotographNamesText={setSubmittedPhotographNamesText}
          />
        )}
      </FormProvider>
    </div>
  );
};

export default App;
