import React, { useEffect, useState } from 'react';
import { TValidatorResponse } from '@/components/shared/Form/types';
import { ProfileHeader } from '../ProfileHeader';
import { renderError, toMessages, useJsonApi } from '../../../../ts/useApi';
import { TEditResponse } from './types';
import { TSaveResponse } from '../Create/types';
import { alertApiError } from '../../../../ts/formValidation';
import { errorToast, successToast } from '../../../../ts/toast';
import { useHistory } from 'react-router-dom';
import { usePreventDuplicateCall } from '@/ts/usePreventDuplicateCall';
import Loading from '../../../shared/Loading/App';
import { Form } from '../Form';
import '../Create/style.scss';
import { useParams } from 'react-router';
import { dateFormatHyphenFromISO8601 } from '../../../../ts/formatTools';
import { putJson } from '@/ts/fetch';
import { BreadCrumb } from '../../../shared/BreadCrumb';

const emptyValidator = {
  messages: {},
  hasError: false,
  rules: {},
};

const App: React.FC = () => {
  const { photographerId } = useParams<{
    photographerId: string;
  }>();
  const { data, error } = useJsonApi<TEditResponse>(
    '/api/photographers/' + photographerId + '/edit'
  );
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [profileImage, setProfileImage] = React.useState<string | null>(null);
  const [validator, setValidator] =
    useState<TValidatorResponse>(emptyValidator);
  const history = useHistory();

  const onSubmit = usePreventDuplicateCall(async (formData) => {
    try {
      formData.profileImage = profileImage;
      Object.keys(formData).forEach((key) => {
        if (formData[key] === undefined) {
          formData[key] = null;
        }
      });

      const postCode = formData['postCode'];
      if (postCode.match(/\d{7}/)) {
        formData['postCode'] =
          postCode.slice(0, 3) + '-' + postCode.slice(3, 7);
      }

      const saveResponse = (await putJson(
        `/api/photographers/${photographerId}`,
        formData
      )) as TSaveResponse;
      setErrorMessages([]);
      if (saveResponse.validator.hasError) {
        setValidator(saveResponse.validator);
        alertApiError();
        return;
      }
      successToast('更新しました');
      history.push(`/photographers/${photographerId}`);
    } catch (e) {
      setErrorMessages(toMessages(e));
      errorToast('エラーが発生しました');
    }
  });

  useEffect(() => {
    if (data) {
      setValidator(data.validator);
    }
  }, [data]);

  if (error) {
    return renderError(error);
  }
  if (!data) {
    return <Loading />;
  }

  const defaultValues = data.data.photographer;
  defaultValues.birthdate =
    dateFormatHyphenFromISO8601(defaultValues.birthdate) ?? '';

  return (
    <div className="App">
      <BreadCrumb
        pagesInfo={[
          { id: 1, title: 'はいチーズ!フォト管理画面', path: '/' },
          { id: 2, title: 'カメラマン一覧', path: '/photographers' },
          {
            id: 3,
            title: 'カメラマンサマリ',
            path: '/photographers/' + defaultValues.id,
          },
          { id: 4, title: 'カメラマン - 更新', path: undefined },
        ]}
      />
      <ProfileHeader pageTitle="カメラマン - 更新" />
      <div className="l-center_wrap">
        <Form
          formItems={data.formItems}
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          validator={validator}
          errorMessages={errorMessages}
          setProfileImage={setProfileImage}
          submitButton="更新する"
          cancelLink={'/photographers/' + defaultValues.id}
        />
      </div>
    </div>
  );
};

export default App;
