import { renderError, useJsonApi } from '../../../../ts/useApi';
import Loading from '../../../shared/Loading/App';
import AdministratorsList from './AdministratorsList';
import { ApiResult, Owneraccount } from './types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './app.scss';

const SORT_ASC = 0;
const SORT_DESC = 1;

const App: React.FC = () => {
  const [administratorList, setAdministratorList] = useState<Owneraccount[]>(
    []
  );
  const [sortId, setSortId] = useState(0);
  const [sortSales, setSortSales] = useState(0);
  const [sortAdmin, setSortAdmin] = useState(0);
  const [sortTehai, setSortTehai] = useState(0);
  const [sortPaymentsClose, setSortPaymentsClose] = useState(false);
  const [sortReportAll, setSortReportAll] = useState(0);
  const [sortUserPrivacy, setSortUserPrivacy] = useState(0);
  const [sortPartnerSupport, setSortPartnerSupport] = useState(0);
  const [sortOperation, setSortOperation] = useState(0);
  const [sortCorpPlan, setSortCorpPlan] = useState(0);
  const [sortEigyo, setSortEigyo] = useState(0);
  const { data, error } = useJsonApi<ApiResult>('/api/owneraccounts');

  if (error) {
    return renderError(error);
  }
  if (!data) {
    return <Loading />;
  } else {
    if (Object.keys(administratorList).length === 0) {
      setAdministratorList(data.data.owneraccounts);
    }
  }

  function handleSortId() {
    const now = new Date();
    console.log(now.getMinutes() + ':' + now.getSeconds());
    if (Object.keys(administratorList).length < 1) {
      return [];
    }

    if (sortId === 0) {
      setSortId(SORT_DESC);
    } else {
      setSortId(SORT_ASC);
    }

    const sortResult: Owneraccount[] = administratorList.sort(
      (first, second) => {
        if (sortId === 0) {
          return first.ownerno < second.ownerno ? 1 : -1;
        } else {
          return first.ownerno > second.ownerno ? 1 : -1;
        }
      }
    );
    setAdministratorList(sortResult);
    const nowEnd = new Date();
    console.log(nowEnd.getMinutes() + ':' + nowEnd.getSeconds());
  }

  function handleSortSales() {
    if (Object.keys(administratorList).length < 1) {
      return [];
    }

    if (sortSales === 0) {
      setSortSales(SORT_DESC);
    } else {
      setSortSales(SORT_ASC);
    }

    const sortResult: Owneraccount[] = administratorList.sort(
      (first, second) => {
        if (sortId === 0) {
          return first.auth_sales < second.auth_sales ? 1 : -1;
        } else {
          return first.auth_sales > second.auth_sales ? 1 : -1;
        }
      }
    );
    setAdministratorList(sortResult);
  }

  function handleSortAdmin() {
    if (Object.keys(administratorList).length < 1) {
      return [];
    }
    if (sortAdmin === 0) {
      setSortAdmin(SORT_DESC);
    } else {
      setSortAdmin(SORT_ASC);
    }

    const sortResult: Owneraccount[] = administratorList.sort(
      (first, second) => {
        if (sortAdmin === 0) {
          return first.auth_admin < second.auth_admin ? 1 : -1;
        } else {
          return first.auth_admin > second.auth_admin ? 1 : -1;
        }
      }
    );
    setAdministratorList(sortResult);
  }

  function handleSortTehai() {
    if (Object.keys(administratorList).length < 1) {
      return [];
    }
    if (sortTehai === 0) {
      setSortTehai(SORT_DESC);
    } else {
      setSortTehai(SORT_ASC);
    }

    const sortResult: Owneraccount[] = administratorList.sort(
      (first, second) => {
        if (sortTehai === 0) {
          return first.auth_tehai < second.auth_tehai ? 1 : -1;
        } else {
          return first.auth_tehai > second.auth_tehai ? 1 : -1;
        }
      }
    );
    setAdministratorList(sortResult);
  }

  function handleSortReportAll() {
    if (Object.keys(administratorList).length < 1) {
      return [];
    }
    if (sortReportAll === 0) {
      setSortReportAll(SORT_DESC);
    } else {
      setSortReportAll(SORT_ASC);
    }

    const sortResult: Owneraccount[] = administratorList.sort(
      (first, second) => {
        if (sortReportAll === 0) {
          return first.auth_reportall < second.auth_reportall ? 1 : -1;
        } else {
          return first.auth_reportall > second.auth_reportall ? 1 : -1;
        }
      }
    );
    setAdministratorList(sortResult);
  }

  function handleSortUserPrivacy() {
    if (Object.keys(administratorList).length < 1) {
      return [];
    }
    if (sortUserPrivacy === 0) {
      setSortUserPrivacy(SORT_DESC);
    } else {
      setSortUserPrivacy(SORT_ASC);
    }

    const sortResult: Owneraccount[] = administratorList.sort(
      (first, second) => {
        if (sortUserPrivacy === 0) {
          return first.auth_userprivacy < second.auth_userprivacy ? 1 : -1;
        } else {
          return first.auth_userprivacy > second.auth_userprivacy ? 1 : -1;
        }
      }
    );
    setAdministratorList(sortResult);
  }

  function handleSortPartnerSupport() {
    if (Object.keys(administratorList).length < 1) {
      return [];
    }
    if (sortPartnerSupport === 0) {
      setSortPartnerSupport(SORT_DESC);
    } else {
      setSortPartnerSupport(SORT_ASC);
    }

    const sortResult: Owneraccount[] = administratorList.sort(
      (first, second) => {
        if (sortPartnerSupport === 0) {
          return first.auth_partnersupport < second.auth_partnersupport
            ? 1
            : -1;
        } else {
          return first.auth_partnersupport > second.auth_partnersupport
            ? 1
            : -1;
        }
      }
    );
    setAdministratorList(sortResult);
  }

  function handleSortOperation() {
    if (Object.keys(administratorList).length < 1) {
      return [];
    }
    if (sortOperation === 0) {
      setSortOperation(SORT_DESC);
    } else {
      setSortOperation(SORT_ASC);
    }

    const sortResult: Owneraccount[] = administratorList.sort(
      (first, second) => {
        if (sortOperation === 0) {
          return first.auth_operation < second.auth_operation ? 1 : -1;
        } else {
          return first.auth_operation > second.auth_operation ? 1 : -1;
        }
      }
    );
    setAdministratorList(sortResult);
  }

  function handleSortCorpPlan() {
    if (Object.keys(administratorList).length < 1) {
      return [];
    }
    if (sortCorpPlan === 0) {
      setSortCorpPlan(SORT_DESC);
    } else {
      setSortCorpPlan(SORT_ASC);
    }

    const sortResult: Owneraccount[] = administratorList.sort(
      (first, second) => {
        if (sortCorpPlan === 0) {
          return first.auth_operation < second.auth_operation ? 1 : -1;
        } else {
          return first.auth_operation > second.auth_operation ? 1 : -1;
        }
      }
    );
    setAdministratorList(sortResult);
  }

  function handleSortEigyo() {
    if (Object.keys(administratorList).length < 1) {
      return [];
    }
    if (sortEigyo === 0) {
      setSortEigyo(SORT_DESC);
    } else {
      setSortEigyo(SORT_ASC);
    }

    const sortResult: Owneraccount[] = administratorList.sort(
      (first, second) => {
        if (sortEigyo === 0) {
          return first.auth_operation < second.auth_operation ? 1 : -1;
        } else {
          return first.auth_operation > second.auth_operation ? 1 : -1;
        }
      }
    );
    setAdministratorList(sortResult);
  }

  function handleSortPaymentsClose() {
    if (Object.keys(administratorList).length < 1) {
      return [];
    }
    if (!sortPaymentsClose) {
      setSortPaymentsClose(true);
    } else {
      setSortPaymentsClose(false);
    }

    const sortResult: Owneraccount[] = administratorList.sort(
      (first, second) => {
        if (!sortPaymentsClose) {
          return second.auth_photographer_payments_close ? 1 : -1;
        } else {
          return second.auth_photographer_payments_close ? 1 : -1;
        }
      }
    );
    setAdministratorList(sortResult);
  }

  return (
    <div className="App">
      <h3 className="l-flex_center u-mgb_m">
        <span className="c-page_title">千社員管理</span>
        {/*  TODO */}
      </h3>
      <div className="l-center_wrap">
        <div className="c-frame">
          <div className="l-flex_between_center u-mgb_s">
            <div className="l-flex_left">
              <div className="c-pagination_upper u-mgr_s">
                {/* ID */}
                <button
                  className={
                    'c-btn_toggle_sort u-fz_xs a-administrator-btn_toggle_sort ' +
                    (sortId === 1 && 'is-desc')
                  }
                  onClick={() => handleSortId()}
                >
                  ID
                </button>
                {/* 売上 */}
                <button
                  className={
                    'c-btn_toggle_sort u-fz_xs a-administrator-btn_toggle_sort ' +
                    (sortSales === 1 && 'is-desc')
                  }
                  onClick={() => handleSortSales()}
                >
                  売上
                </button>
                {/* 管理 */}
                <button
                  className={
                    'c-btn_toggle_sort u-fz_xs a-administrator-btn_toggle_sort ' +
                    (sortAdmin === 1 && 'is-desc')
                  }
                  onClick={() => handleSortAdmin()}
                >
                  管理
                </button>
                {/* 手配 */}
                <button
                  className={
                    'c-btn_toggle_sort u-fz_xs a-administrator-btn_toggle_sort ' +
                    (sortTehai === 1 && 'is-desc')
                  }
                  onClick={() => handleSortTehai()}
                >
                  手配
                </button>
                {/* レポート */}
                <button
                  className={
                    'c-btn_toggle_sort u-fz_xs a-administrator-btn_toggle_sort ' +
                    (sortReportAll === 1 && 'is-desc')
                  }
                  onClick={() => handleSortReportAll()}
                >
                  レポート
                </button>
                {/* 個人情報 */}
                <button
                  className={
                    'c-btn_toggle_sort u-fz_xs a-administrator-btn_toggle_sort ' +
                    (sortUserPrivacy === 1 && 'is-desc')
                  }
                  onClick={() => handleSortUserPrivacy()}
                >
                  個人情報
                </button>
                {/* サポート */}
                <button
                  className={
                    'c-btn_toggle_sort u-fz_xs a-administrator-btn_toggle_sort ' +
                    (sortPartnerSupport === 1 && 'is-desc')
                  }
                  onClick={() => handleSortPartnerSupport()}
                >
                  サポート
                </button>
                {/* 業務 */}
                <button
                  className={
                    'c-btn_toggle_sort u-fz_xs a-administrator-btn_toggle_sort ' +
                    (sortOperation === 1 && 'is-desc')
                  }
                  onClick={() => handleSortOperation()}
                >
                  業務
                </button>
                {/* 経企 */}
                <button
                  className={
                    'c-btn_toggle_sort u-fz_xs a-administrator-btn_toggle_sort ' +
                    (sortCorpPlan === 1 && 'is-desc')
                  }
                  onClick={() => handleSortCorpPlan()}
                >
                  経企
                </button>
                <button
                  className={
                    'c-btn_toggle_sort u-fz_xs a-administrator-btn_toggle_sort ' +
                    (sortEigyo === 1 && 'is-desc')
                  }
                  onClick={() => handleSortEigyo()}
                >
                  営業
                </button>
                <button
                  className={
                    'c-btn_toggle_sort u-fz_xs a-administrator-btn_toggle_sort ' +
                    (sortPaymentsClose && 'is-desc')
                  }
                  onClick={() => handleSortPaymentsClose()}
                >
                  支払締め
                </button>
              </div>
            </div>
            <div className="l-flex_end">
              <Link
                className={`c-btn_rectangle c-btn_create ${
                  data.conditions.canCreate ? '' : 'is-disabled'
                }`}
                to="/administrator/create"
              >
                <i className="c-icon c-icon__small c-icon_create" />
              </Link>
            </div>
          </div>
          <AdministratorsList owneraccounts={administratorList} />
        </div>
      </div>
    </div>
  );
};

export default App;
